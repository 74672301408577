<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div>
        <!--<argon-button color="white" variant="outline">New order</argon-button>-->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <table id="review-list" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th class="text-xxs">Id</th>
                  <th>Image</th>
                  <th>Provider</th>
                  <th>Service</th>
                  <th class="text-xxs">Date</th>
                  <th class="text-xxs">Rating</th>
                  <th class="text-xxs">Score</th>
                  <th class="text-xxs">Comment</th>

                  <th class="text-xxs">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="review of reviewList.reviews" :key="review.id">
                  <td>
                    <div class="d-flex align-items-center">
                      <argon-checkbox class="d-none" />
                      <p class="text-xxs ms-2 mb-0">{{ review.id }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <img
                        :src="imgUrl + review.profile_img"
                        alt="..."
                        class="avatar shadow"
                      />
                    </div>
                  </td>
                  <td>
                    <p class="my-2 text-xxs">
                      {{ review.name }}
                    </p>
                  </td>
                  <td class="text-xxs">
                    <span class="my-2 text-xs">{{ review.service_title }}</span>
                  </td>

                  <td>
                    <p class="my-2 text-xxs">
                      {{ review.created }}
                    </p>
                  </td>

                  <td class="text-xxs">
                    <star-rating
                      class="mx-2"
                      :star-size="10"
                      :show-rating="false"
                      :round-start-rating="false"
                      :rating="review.rating"
                    ></star-rating>
                  </td>
                  <td class="text-xxs">
                    <span class="my-2 text-xxs"> {{ review.rating }} </span>
                  </td>
                  <td class="text-xxs">
                    <span class="my-2 text-xxs"> {{ review.review }}</span>
                  </td>

                  <td class="text-xxs">
                    <router-link
                      :to="{
                        name: 'ServiceDetail',
                        params: { id: review.service_id },
                      }"
                    >
                      <argon-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-eye" aria-hidden="true"></i>
                      </argon-button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import starRating from "vue-star-rating";

export default {
  name: "Rating User",
  components: {
    ArgonButton,
    ArgonCheckbox,
    starRating,
  },
  data() {
    return {
      bookingStatusCode: "",
      bookingStatusColor: "",
      bookingStatusTitle: "",
      bookingDetails: [],
      imgUrl: "https://back.labes.com.tn/",
      uRole: "",
    };
  },
  mounted() {
    if (document.getElementById("review-list")) {
      const dataTableSearch = new DataTable("#review-list", {
        searchable: true,
        fixedHeight: false,
        perPageSelect: false,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function (el) {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
  },
  async created() {
    await this.$store.dispatch("loadUserReviews");
    this.uRole = this.$store.state.auth.user.type;
  },
  computed: {
    reviewList() {
      return this.$store.state.userReviews;
    },
  },
  methods: {
    async setBookingStatus(booking) {
      this.bookingStatusCode = booking.status;

      if (this.bookingStatusCode == 1) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Pending";
      } else if (this.bookingStatusCode == 2) {
        this.bookingStatusColor = "badge-info";
        this.bookingStatusTitle = "In progress";
      } else if (this.bookingStatusCode == 3) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 4) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 5) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Rejected by Pro";
      } else if (this.bookingStatusCode == 6) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "service Complete - Payment Pending";
      } else if (this.bookingStatusCode == 7) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Cancelled by Patient";
      } else if (this.bookingStatusCode == 8) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete";
      }
    },
  },
};
</script>
