<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12 row">
        <div class="col-12 col-lg-4">
          <div class="card text-gray border-gray-300 p-4 mb-4">
            <h5 class="font-weight-bolder">Service Image</h5>
            <div class="text-center mt-2">
              <div v-if="previewURL" class="fmxw-100 mb-3">
                <img
                  class="rounded img-fluid shadow border-radius-xl"
                  :src="previewURL"
                  alt="File Preview"
                />
              </div>

              <input
                class="form-control"
                type="file"
                id="formFile"
                @change="previewServicePhoto"
              />
            </div>
            <small class="text-gray fw-light text-xxs d-block mt-2"
              >Image to display in the front page. Image should be at least 500
              pixels wide and only *.jpg, *.png and *.jpeg formats are
              accepted.</small
            >
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <div class="card border-gray-300 p-2 mb-4">
            <div class="card-body">
              <h5 class="font-weight-bolder">General Informations</h5>
              <div class="row">
                <div class="col-12 mb-4">
                  <label>Title</label>
                  <input
                    v-model="serviceInformations.service_title"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-12 mb-4">
                  <label>Location</label>
                  <input
                    v-model="serviceInformations.service_location"
                    class="form-control"
                    placeholder="26, Avenue de la liberté, Tunis - 2060"
                    type="text"
                  />
                </div>
                <div class="col-12 col-lg-6 mb-4">
                  <label>Category *</label>

                  <select
                    class="form-control"
                    name="choices-category"
                    v-model="selectedCategory"
                  >
                    <option
                      :value="cat"
                      v-for="cat of categoryOptions"
                      :key="cat.id"
                      :id="cat.id"
                    >
                      {{ cat.category_name }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-lg-6 mb-4">
                  <label>Sub Category *</label>

                  <select v-model="selectedSubcategory" class="form-control">
                    <option
                      v-for="subCatego of selectedCategory.subCategories"
                      :key="subCatego.id"
                      :id="subCatego.id"
                      :value="subCatego.id"
                    >
                      {{ subCatego.subcategory_name }}
                    </option>
                  </select>
                </div>

                <div class="col-12 col-lg-6 mb-4">
                  <label>Service Offred *</label>
                  <div
                    v-for="(input, index) in servicesOff"
                    :key="`serviceOfferedInput-${index}`"
                    class="input wrapper flex items-center d-flex"
                  >
                    <input
                      v-model="input.service_offered"
                      type="text"
                      class="form-control m-1"
                      placeholder=""
                    />
                    <div class="d-flex align-items-center">
                      <button
                        @click="addField(index, servicesOff)"
                        class="btn mb-0 btn-outline-success btn-md null null btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-plus" aria-hidden="true"></i>
                      </button>
                      <button
                        v-if="servicesOff.length > 1"
                        @click="removeField(index, servicesOff)"
                        class="btn mb-0 btn-outline-danger btn-md null null btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mb-4">
                  <label class="mt-4">Tags</label>
                  <select
                    id="choices-tags"
                    class="form-control"
                    name="choices-tags"
                    multiple
                  >
                    <option value="Choice 1" selected>
                      Service à domicile
                    </option>
                    <option value="Choice 2">Medeci</option>
                    <option value="Choice 3">soignant</option>
                    <option value="Choice 4">infirmier</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-4">
                  <label class="mt-4">Description</label>
                  <p class="text-xs form-text text-muted ms-1 d-inline">
                    (optional)
                  </p>
                  <div ref="editDescription" class="h-50">
                    <p>Service <strong>Description</strong> example</p>
                  </div>
                </div>
              </div>
              <div class="d-none row">
                <div class="col-12 mb-4">
                  <label for="description">Description</label>
                  <textarea
                    rows="10"
                    class="form-control text-gray"
                    id="description"
                    required=""
                  >
  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque temporibus odio, ipsum sint animi sequi totam repellat quidem, placeat neque vel enim nisi. Quia dolor in earum labore, aperiam dolorum.
  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa porro consequuntur, laborum eligendi laboriosam itaque quis nobis, tempora at id in repellendus eius reiciendis error, enim fugit modi molestiae!
  Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia velit, deserunt animi, debitis eum laudantium facere adipisci minus ad quaerat ipsam consectetur, obcaecati non sit! Voluptas voluptatum autem modi dolorem?
                                  </textarea
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="card text-gray border-gray-300 p-4 mb-4">
            <div class="text-gray">
              <div class="mb-4">
                <label for="video">Video url</label>
                <p class="text-xs form-text text-muted ms-1 d-inline">
                  (optional)
                </p>
                <input
                  type="text"
                  class="form-control text-gray"
                  id="video"
                  required=""
                  v-model="serviceInformations.service_video.url_video"
                />
              </div>

              <div class="row">
                <div class="col-12">
                  <label>Gallery</label>

                  <div
                    id="serviceGallery"
                    action="#"
                    class="form-control dropzone"
                  >
                    <div class="fallback">
                      <input
                        name="file"
                        type="file"
                        id="galleryInput"
                        ref="galleryInput"
                        multiple
                        accept="image/*"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <small class="text-gray fw-light font-xs d-block mt-2"
                >Images should be at least 500 pixels wide and only *.jpg, *.png
                and *.jpeg formats are accepted.</small
              >
            </div>

            <div class="row">
              <div class="col text-right">
                <button
                  class="btn btn-primary btn-dark mt-2 animate-up-2 text-right"
                  type="button"
                  :disabled="this.btnAddStatus"
                  @click="editService"
                >
                  Confirm
                </button>
              </div>

              <div
                class="alert alert-success text-white"
                role="alert"
                v-if="this.postData"
              >
                <strong>Please wait!</strong> Connecting to server...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Quill from "quill";
import Dropzone from "dropzone";
import Choices from "choices.js";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import axios from "axios";
//import OtherProducts from "../../components/OtherProducts.vue";

export default {
  name: "Edit Service",
  components: {},
  data() {
    return {
      servicesOff: [{ service_offered: "" }],
      profileImageUrl: null,
      photoImageUrl: null, // URL for photo image
      previewURL: null,

      categoryOptions: this.$store.state.homeData.category_list,
      selectedCategory: "", // To store the selected category
      selectedSubcategory: "", // To store the selected subcategory
      category_list: this.$store.state.homeData.category_list,
      serviceLoaded: [],
      providerOverview: [],
      subcategories: [],
      serviceInformations: {
        id: "",
        service_title: "",
        service_location: "",
        service_latitude: "",
        service_longitude: "",
        category: "",
        subcategory: "",
        about: "",
        service_offered: "",
        service_video: "",
        files: "",
        /* Call<EmptyData> postCreateService(@Part("service_title") RequestBody service_title,
                                        @Part("service_location") RequestBody service_location,
                                        @Part("category") RequestBody category,
                                        @Part("subcategory") RequestBody subcategory,
                                        @Part("service_latitude") RequestBody service_latitude,
                                        @Part("service_longitude") RequestBody service_longitude,
                                        @Part("service_amount") RequestBody service_amount,
                                        @Part("about") RequestBody about,
                                        @Part("service_offered") RequestBody service_offered,
                                        @Part List<MultipartBody.Part> files,
                                        @Header("token") String token);*/
      },
      selectedFiles: [], // Initialize as an empty array for multiple files
      selectedServicePhoto: null,
      ServicePhotoModif: false,
      serviceInsertedId: "",
      errMessage: "",
      btnAddStatus: false,
      imgUrl: "https://back.labes.com.tn/",
      postData: false,
    };
  },
  mounted() {
    this.getChoices("choices-category-service");
    this.getChoicesSubCat("choices-subcategory-service");

    Dropzone.autoDiscover = false;
    var dropGallery = document.getElementById("serviceGallery");

    this.myDropzone = new Dropzone(dropGallery, {
      url: "/",
      autoProcessQueue: false, // Prevent Dropzone from automatically uploading files
      addRemoveLinks: true, // Display the remove button
      maxFilesize: 10, // Maximum file size in MB
      parallelUploads: 5, // Number of parallel file uploads allowed
      paramName: "images", // Name of the file parameter
      maxFiles: 10, // Set the maximum number of files
      minFiles: 3, // Set the minimum number of files
      dictRemoveFile: "Remove",
      // Custom error messages for exceeding min/max files
      dictMaxFilesExceeded: "You can only upload a maximum of 5 files.",
      dictMinFilesExceeded: "You must upload at least 3 file.",
      createImageThumbnails: true,
      previewTemplate: `
        <div class="dz-preview dz-file-preview">
          <div class="dz-image">
            <img data-dz-thumbnail />
          </div>
          <div class="dz-details">
            <!-- Remove or customize the following line to hide or display the file name -->
            <div class="dz-size"><span data-dz-size></span></div>
           
          </div>
        
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
          <div class="dz-success-mark">
            <span>&#10004;</span>
          </div>
          <div class="dz-error-mark">
            <span>&#10008;</span>
          </div>
        </div>
      `,

      init: function () {
        this.on("addedfile", function (file) {
          // A file has been added to Dropzone, you can access it here
          //console.log("File added:", file);
          // Check if it's the first file
          const isFirstFile = this.files.length === 1;

          // Optionally, hide the preview of the first file
          if (isFirstFile) {
            file.previewElement.style.display = "none";
          }

          // Remove the following line in your actual implementation
          this.emit("success", file);
          console.log("All files:", this.files);
        });
        this.on("success", function () {
          // Now, you can access the files using this.myDropzone.files
          console.log("All files:", this.files);
        });
        // Event handler for removed files
        this.on("removedfile", (file) => {
          // Handle file removal (you can update UI or perform other actions)

          console.log("File removed:", file);
          console.log("All files:", this.files);
        });
      },
    });
    //this.myDropzone = myDropzone;

    if (document.getElementById("choices-tags")) {
      var tags = document.getElementById("choices-tags");
      // const examples = new Choices(tags, {
      return new Choices(tags, {
        removeItemButton: true,
        allowHTML: true,
      });

      /* examples.setChoices(
          [
            {
              value: "One",
              label: "Expired",
              disabled: true,
            },
            {
              value: "Two",
              label: "Out of Stock",
              selected: true,
            },
          ],
          "value",
          "label",
          false
        );*/
    }
    // Products gallery

    var initPhotoSwipeFromDOM = function (gallerySelector) {
      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item;

        for (var i = 0; i < numNodes; i++) {
          figureEl = thumbElements[i]; // <figure> element
          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue;
          }

          linkEl = figureEl.children[0]; // <a> element

          size = linkEl.getAttribute("data-size").split("x");

          // create slide object
          item = {
            src: linkEl.getAttribute("href"),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10),
          };

          if (figureEl.children.length > 1) {
            // <figcaption> content
            item.title = figureEl.children[1].innerHTML;
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.children[0].getAttribute("src");
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
        }

        return items;
      };

      // find nearest parent element
      var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
      };

      // triggers when user clicks on thumbnail
      var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function (el) {
          return el.tagName && el.tagName.toUpperCase() === "FIGURE";
        });

        if (!clickedListItem) {
          return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

        for (var i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue;
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
          }
          nodeIndex++;
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery);
        }
        return false;
      };

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
          params = {};

        if (hash.length < 5) {
          return params;
        }

        var vars = hash.split("&");
        for (var i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue;
          }
          var pair = vars[i].split("=");
          if (pair.length < 2) {
            continue;
          }
          params[pair[0]] = pair[1];
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10);
        }

        return params;
      };

      var openPhotoSwipe = function (
        index,
        galleryElement,
        disableAnimation,
        fromURL
      ) {
        var pswpElement = document.querySelectorAll(".pswp")[0],
          gallery,
          options,
          items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute("data-pswp-uid"),

          getThumbBoundsFn: function (index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
              pageYScroll =
                window.pageYOffset || document.documentElement.scrollTop,
              rect = thumbnail.getBoundingClientRect();

            return {
              x: rect.left,
              y: rect.top + pageYScroll,
              w: rect.width,
            };
          },
        };

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (var j = 0; j < items.length; j++) {
              if (items[j].pid == index) {
                options.index = j;
                break;
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1;
          }
        } else {
          options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return;
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(
          pswpElement,
          // eslint-disable-next-line no-undef
          PhotoSwipeUI_Default,
          items,
          options
        );
        gallery.init();
      };

      // loop through all gallery elements and bind events
      var galleryElements = document.querySelectorAll(gallerySelector);

      for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute("data-pswp-uid", i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      var hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(
          hashData.pid,
          galleryElements[hashData.gid - 1],
          true,
          true
        );
      }
    };

    // execute above function
    initPhotoSwipeFromDOM(".my-gallery");
  },
  async created() {
    //this.$store.dispatch("loadCategories");
    //await this.$store.dispatch("loadServiceProvider");
    await this.$store.dispatch("loadServiceProvider");
    this.serviceLoaded =
      this.$store.state.serviceProvider.service_details[0].service_overview;
    this.providerOverview =
      this.$store.state.serviceProvider.service_details[0].seller_overview;

    this.setServiceData();
  },
  computed: {
    /* subCatego() {
      return this.selected
        ? this.category_list.find((x) => x.name === this.selected).contact
        : [];
    },*/
    filteredSubcategories() {
      if (this.selectedCategory) {
        // Find the selected category based on its name
        const category = this.categoryOptions.find(
          (cat) => cat.category_name === this.selectedCategory
        );

        if (category) {
          return category.subCategories || [];
        }
      }

      return [];
    },
    user() {
      return this.$store.state.auth.user;
    },
    service() {
      return this.$store.state.service;
    },
  },
  methods: {
    async setServiceData() {
      this.serviceInformations.id = this.serviceLoaded.service_id;
      this.serviceInformations.service_title = this.serviceLoaded.service_title;
      this.serviceInformations.service_location =
        this.providerOverview.location;

      this.serviceInformations.service_video = this.serviceLoaded.service_video;
      const category = this.$store.state.homeData.category_list;
      this.selectedCategory = category.find(
        (cat) => cat.id === this.serviceLoaded.category_id
      );

      this.selectedSubcategory = this.serviceLoaded.subcategory_id;

      // Sort the array so that images with 'service_photo' come first
      this.serviceLoaded.service_image_file.sort((a, b) => {
        const containsA = a.service_image.includes("service_photo");
        const containsB = b.service_image.includes("service_photo");

        // Put images with 'service_photo' first
        return containsB - containsA;
      });

      console.log("First element after sort ");
      console.log(this.serviceLoaded.service_image_file[0]);

      this.previewURL =
        this.imgUrl + this.serviceLoaded.service_image_file[0].service_image;

      const arrayFromString = this.serviceLoaded.service_offered;

      // Reverse the process to get the original array
      const arrayOfInputServiceOff = JSON.parse(arrayFromString);

      this.servicesOff = arrayOfInputServiceOff.map((serviceName) => ({
        service_offered: serviceName,
      }));

      this.serviceInformations.about = this.serviceLoaded.about;

      if (this.$refs.editDescription) {
        this.setupQuillEditor();
      }

      // const photoURLs = this.serviceLoaded.service_image;

      const photoURLFiles = this.serviceLoaded.service_image_file;

      console.log(photoURLFiles);

      // Prepopulate Dropzone with initial photos
      photoURLFiles.forEach((element) => {
        // Assuming base64String is your base64-encoded file content
        var imgb64 = element.service_img_b64;
        // console.log(imgb64);
        // Decode the base64 string into a binary blob
        const binaryString = atob(imgb64);

        // Convert the binary string to a Uint8Array
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: "image/jpeg" }); // Change "image/jpeg" to the appropriate image type

        // Create a File object from the Blob
        const file = new File([blob], element.mobile_image, {
          type: "image/jpeg",
        }); // Change "filename.jpg" to your desired file name and update the type accordingly

        // Now 'file' is a File object that can be used in Dropzone
        // console.log(file);

        // Assuming 'myDropzone' is your Dropzone instance
        this.myDropzone.emit("success", file);
        this.myDropzone.addFile(file);
      });
    },
    getAddedFiles() {
      console.log("getAddedfiles");
      // Access the added files in the Dropzone instance
      const addedFiles = this.myDropzone.files;
      console.log(addedFiles);
      return addedFiles;
    },
    setupQuillEditor() {
      // Initialize Quill with the container element
      this.quill = new Quill(this.$refs.editDescription, {
        theme: "snow",
      });

      // Set the initial content from the data property
      this.quill.root.innerHTML = this.serviceInformations.about;

      // Listen for Quill's text-change event to update the data property
      this.quill.on("text-change", () => {
        this.serviceInformations.about = this.quill.root.innerHTML;
      });
    },

    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: true,
          itemSelectText: "",
          allowHTML: true,
        });
      }
    },
    getChoicesSubCat(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: true,
          itemSelectText: "",
          allowHTML: true,
        });
      }
    },

    addField(index, fieldType) {
      fieldType.push({});
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    previewServicePhoto(event) {
      const inputServicePhoto = event.target;
      if (inputServicePhoto.files && inputServicePhoto.files[0]) {
        const reader = new FileReader();

        this.selectedServicePhoto = null;
        this.selectedServicePhoto = inputServicePhoto.files[0];
        //replace the first element (Hidden)
        const fChanged = inputServicePhoto.files[0];

        // Rename the file (replace "newFileName" with your desired name)
        const newFileName = "service_photo_" + fChanged.name; // Change this to your desired file name
        console.log(newFileName);
        console.log(fChanged);
        this.myDropzone.files[0] = new File([fChanged], newFileName, {
          type: fChanged.type,
        });

        this.myDropzone.emit("complete", fChanged);
        this.ServicePhotoModif = true;

        console.log(this.myDropzone.files);
        reader.onload = (e) => {
          this.previewURL = e.target.result;
        };

        reader.readAsDataURL(inputServicePhoto.files[0]);
      }
    },

    showSwal(type) {
      if (type === "success-message") {
        let timerInterval;
        this.$swal({
          icon: "success",
          title: "Edit service!",
          text: "Service Updated Successfully!",
          type: type,
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            //this.$swal.showLoading();
            //const b = this.$swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              //b.textContent = this.$swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
            window.location.reload();
          },
        });
      } else if (type === "error-message") {
        this.$swal({
          icon: "error",
          title: "Edit service!",
          text: "",
          type: type,
        });
      }
    },

    async editService() {
      this.postData = true;
      this.btnAddStatus = true;
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      axios.defaults.headers["Content-Type"] = "multipart/form-data";
      this.serviceInformations.category = this.selectedCategory.id;
      this.serviceInformations.subcategory = this.selectedSubcategory;
      this.serviceInformations.service_offered = this.servicesOff.map(
        (item) => item.service_offered
      );

      // Define your request data
      const formData = new FormData();

      formData.append("id", this.serviceInformations.id);
      formData.append("service_title", this.serviceInformations.service_title); // Replace 'service_title' with your actual data
      formData.append(
        "service_location",
        this.serviceInformations.service_location
      );
      formData.append("category", this.serviceInformations.category);
      formData.append("subcategory", this.serviceInformations.subcategory);
      formData.append(
        "service_latitude",
        this.serviceInformations.service_latitude
      );
      formData.append(
        "service_longitude",
        this.serviceInformations.service_longitude
      );

      formData.append("about", this.serviceInformations.about);

      const arrayOfInputValues = this.servicesOff.map(
        (input) => input.service_offered
      );
      const arrayFromString = JSON.stringify(arrayOfInputValues);

      //const arrayServiceOffered = valuesServiceOffered.split(",");

      formData.append("service_offered", arrayFromString);

      formData.append("service_video", this.serviceInformations.service_video);

      // Access the list of uploaded files
      const uploadedFiles = this.myDropzone.files;

      console.log("here is file to upload");
      uploadedFiles.forEach((element) => {
        console.log(element);
        const imageFile = element;
        formData.append("images[]", imageFile, imageFile.name);
      });

      //console.log(this.myDropzone.files);

      // You can send fileData to the server using AJAX or another method here

      /*if (this.ServicePhotoModif) {
        console.log(this.selectedServicePhoto);
        console.log("Image Service changed");
        const servImg = this.selectedServicePhoto;
        formData.append("images[]", servImg, servImg.name);
      }*/

      // Loop through the list of image files
      /*for (let i = 0; i < uploadedFiles.length; i++) {
        const imageFile = uploadedFiles[i];
        formData.append("images[]", imageFile, imageFile.name);
      }*/

      await axios
        .post("update_service", formData)
        .then(({ data }) => {
          console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            this.showSwal("success-message");
          } else {
            this.showSwal("error-message");
          }
        })
        .catch(({ response }) => {
          console.log(response);

          this.showSwal("error-message");
        })
        .finally(() => {
          //alert("login finish ")
          this.btnAddStatus = false;
          this.postData = false;
        });
    },
  },
};
</script>
