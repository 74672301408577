<template>
  <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <div class="position-relative">
          

          <div class="blur-shadow-image">
            <img
              class="w-100 rounded-3 avatar-xxl shadow-lg"
              :src="previewURL"
            />
          </div>
          <div class="mt-2"></div>
        </div>
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ user.name }}</h5>
          <p class="mb-0 font-weight-bold text-sm">
            {{ uRole == 1 ? "Provider" : "User" }} #{{ user.id }}
          </p>
          <label class="form-check-label mb-0 me-2">
            <small id="profileVisibility">Modifier la photo</small>
          </label>
          <button
            class="btn btn-icon-only shadow-none text-dark mb-0 me-3 me-sm-0"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
            @click="showEditPhButton()"
            data-bs-original-title="Video call"
          >
            <i class="ni ni-settings"></i>
          </button>
          <input
            v-if="this.editPhButton"
            class="form-control mt-2"
            type="file"
            id="formFile"
            @change="previewServicePhoto"
          />
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <button
          class="my-2 mx-2 btn bg-gradient-info btn-sm me-2"
          type="button"
          name="button"
          @click="editProfile()"
        >
          <span class="d-sm-inline">Update</span>
        </button>
      </div>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Name</label>
          <argon-input
            id="firstname"
            type="text"
            :value="user.name"
            placeholder="Alec"
          />
        </div>

        <div class="col-sm-6 col-6">
          <label class="form-label mt-2">Email</label>
          <argon-input
            id="email"
            type="email"
            :value="user.email"
            placeholder="example@email.com"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 d-none">
          <label class="form-label mt-2">Address</label>
          <argon-input
            id="location"
            type="text"
            :value="user.address"
            placeholder="Sydney, A"
          />
        </div>

        <div class="col-6">
          <label class="form-label mt-2"
            >Phone Number (+{{ user.country_code }})</label
          >
          <argon-input
            id="phone"
            type="text"
            :value="user.mobileno"
            placeholder="+40 745 765 439"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-6">
          <label class="form-label mt-2">Account Type</label>
          <input
            type="text"
            id="role"
            placeholder="Regular"
            class="form-control"
            :value="uRole == 1 ? 'Provider' : 'User'"
            disabled
          />
        </div>
        <div class="col-sm-6 col-6">
          <label class="form-label mt-2">Registry Date</label>
          <input
            type="text"
            id="role"
            placeholder="Regular"
            class="form-control"
            :value="user.created_at"
            disabled
          />
        </div>
      </div>
      <div class="col-12 d-none">
        <label class="form-label">Orgnization Description</label>
        <textarea
          class="form-control"
          type="text"
          placeholder="About your Organization "
        />
      </div>
      <div class="row d-none">
        <div class="col-md-6">
          <label class="form-label mt-2">Countries</label>
          <input
            id="choices-skills"
            class="form-control"
            type="text"
            value="Tunisia, France, Czech Republic"
            placeholder="Enter something"
            onfocus="focused(this)"
            onfocusout="defocused(this)"
          />
        </div>
        <div class="col-md-6">
          <argon-button
            class="float-end mt-6 mb-0"
            color="light"
            variant="gradient"
            size="sm"
            >Update Basic Informations</argon-button
          >
        </div>
      </div>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">Current password</label>
      <argon-input
        id="password"
        type="password"
        placeholder="Current Password"
      />
      <label class="form-label">New password</label>
      <argon-input
        id="new-password"
        type="password"
        placeholder="New Password"
      />
      <label class="form-label">Confirm new password</label>
      <argon-input
        id="confirm-password"
        type="password"
        placeholder="Confirm password"
      />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <argon-button
        class="float-end mt-6 mb-0"
        color="light"
        variant="gradient"
        size="sm"
        >Update password</argon-button
      >
    </div>
  </div>
  <!--<div id="2fa" class="card mt-4">
    <div class="card-header d-flex">
      <h5 class="mb-0">Two-factor authentication</h5>
      <argon-badge color="success" class="ms-auto">Enabled</argon-badge>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <p class="my-auto">Security keys</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          No Security Keys
        </p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Add</argon-button
        >
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">SMS number</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Edit</argon-button
        >
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">Authenticator app</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          Not Configured
        </p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Set up</argon-button
        >
      </div>
    </div>
  </div>-->
  <!--<div id="accounts" class="card mt-4">
    <div class="card-header">
      <h5>Accounts</h5>
      <p class="text-sm">
        Here you can setup and manage your integration settings.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img1" alt="logo_slack" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Slack</h5>
            <a class="text-sm text-body" href="javascript:;">
              Show less
              <i class="fas fa-chevron-up text-xs ms-1" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-slack" name="Enabled" checked />
        </div>
      </div>
      <div class="ps-5 pt-3 ms-3">
        <p class="mb-0 text-sm">
          You haven't added your Slack yet or you aren't authorized. Please add
          our Slack Bot to your account by clicking on
          <a href="javascript">here</a>. When you've added the bot, send your
          verification code that you have received.
        </p>
        <div
          class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
        >
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Verification Code
          </p>
          <argon-input
            id="verification-code"
            type="text"
            value="1172913"
            class="my-auto w-20 ms-sm-auto"
          />
        </div>
        <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Connected account
          </p>
          <h6 class="text-sm ms-auto me-3 my-auto">hello@creative-tim.com</h6>
          <argon-button
            color="danger"
            variant="gradient"
            size="sm"
            class="my-sm-auto mt-2 mb-0"
            type="button"
            name="button"
            >Delete</argon-button
          >
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img2" alt="logo_spotify" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Spotify</h5>
            <p class="mb-0 text-sm">Music</p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-spotify" name="Spotify" checked />
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img3" alt="logo_atlassian" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Atlassian</h5>
            <p class="mb-0 text-sm">Payment vendor</p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-atlassian" name="Atlassian" checked />
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img4" alt="logo_asana" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Asana</h5>
            <p class="mb-0 text-sm">Organize your team</p>
          </div>
        </div>
        <div class="my-auto ms-auto">
          <argon-switch id="enable-asana" name="Asana" />
        </div>
      </div>
    </div>
  </div>-->
  <!--<div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Activity</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user mentions you in a comment</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-email"
                  name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-push"
                  name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
              <td>
                <argon-switch
                  id="notify-sms"
                  name="Sms"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user comments your item.</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-comment-email"
                  name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-comment-push"
                  name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-comment-sms"
                  name="SMS"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user follows you.</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-follow-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-follow-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-follow-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-login-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-login-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-login-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>-->
  <!--<div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <argon-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Active</argon-badge
        >
        <p class="text-secondary text-sm my-auto me-3">EU</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Safari on iPhone</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>-->
  <div id="delete" class="d-none card mt-4">
    <div class="card-header">
      <h5>Delete Account</h5>
      <p class="text-sm mb-0">
        Once you delete your account, there is no going back. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <argon-switch id="delete-account" name="Delete Account" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Confirm</span
          >
          <span class="text-xs d-block">I want to delete my account.</span>
        </div>
      </div>
      <argon-button
        color="secondary"
        variant="outline"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        >Deactivate</argon-button
      >
      <argon-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-2"
        type="button"
        name="button"
        >Delete Account</argon-button
      >
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
//import ArgonBadge from "@/components/ArgonBadge.vue";
//import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import img from "../../../../assets/img/team-3.jpg";
import img1 from "../../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../../assets/img/small-logos/logo-asana.svg";
import axios from "axios";

export default {
  name: "SideNavItem",
  components: {
    ArgonButton,
    ArgonSwitch,
    // ArgonBadge,
    //ArgonAvatar,
    ArgonInput,
  },
  data() {
    return {
      uRole: this.$store.state.auth.user.type,
      imgCatUrl: "https://back.labes.com.tn/",
      img,
      img1,
      img2,
      img3,
      img4,
      previewURL:
        "https://back.labes.com.tn/uploads/profile_img/labes-avatar-profile.jpg",
      selectedServicePhoto: null,
      ServicePhotoModif: false,
      editPhButton: false,
      updateUrl: "",
      profileInformations: [],
      profilePhotoName: "",
      /* profileInformations : {
        name:'',
        type:'',
        email:'',
        mobileno:'',
        profile_img:'',
        country_code:'',
        created_at:'',
      }*/
    };
  },
  async created() {
    //await this.$store.dispatch("loadUser", this.$store.state.auth.user.id);
    console.log(this.$store.state.auth.user);
    if (this.$store.state.auth.user.profile_img != "") {
      this.previewURL =
        this.imgCatUrl + this.$store.state.auth.user.profile_img;
    }
    this.profileInformations = this.$store.state.auth.user;
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender, { allowHTML: true });
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language, { allowHTML: true });
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month, { allowHTML: true });
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day, { allowHTML: true });
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year, { allowHTML: true });
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
  },
  methods: {
    async editProfile() {
      if (this.uRole == "1") {
        this.updateUrl = "update_provider";
      } else {
        this.updateUrl = "update_user";
      }
      console.log(this.updateUrl);
      //this.postData = true;
      //this.btnAddStatus = true;
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      axios.defaults.headers["Content-Type"] = "multipart/form-data";

      // Define your request data
      const formData = new FormData();

      formData.append("name", this.profileInformations.name);
      formData.append("type", this.profileInformations.type); // Replace 'service_title' with your actual data
      formData.append("email", this.profileInformations.email);
      formData.append("mobileno", this.profileInformations.mobileno);
      formData.append("country_code", this.profileInformations.country_code);
      // Access the list of uploaded files
      /* const uploadedFiles = this.myDropzone.files;

      console.log("here is file to upload");
      uploadedFiles.forEach((element) => {
        console.log(element);
        const imageFile = element;
        formData.append("images[]", imageFile, imageFile.name);
      });*/

      //console.log(this.myDropzone.files);

      // You can send fileData to the server using AJAX or another method here

      if (this.ServicePhotoModif) {
        console.log(this.selectedServicePhoto);
        console.log("Image Service changed");
        //const servImg = this.selectedServicePhoto;
        formData.append(
          "profile_img[]",
          this.selectedServicePhoto,
          "profile_img"
        );
      }

      console.log("data to send");
      console.log(formData);
      // Loop through the list of image files
      /*for (let i = 0; i < uploadedFiles.length; i++) {
        const imageFile = uploadedFiles[i];
        formData.append("images[]", imageFile, imageFile.name);
      }*/

      await axios
        .post(this.updateUrl, formData)
        .then(({ data }) => {
          console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            this.showSwal("success-message");
          } else {
            this.showSwal("error-message");
          }
        })
        .catch(({ response }) => {
          console.log(response);

          this.showSwal("error-message");
        })
        .finally(() => {
          //alert("login finish ")
          this.btnAddStatus = false;
          this.postData = false;
        });
    },

    showEditPhButton() {
      this.editPhButton = true;
    },
    previewServicePhoto(event) {
      const inputServicePhoto = event.target;
      if (inputServicePhoto.files && inputServicePhoto.files[0]) {
        const reader = new FileReader();

        this.selectedServicePhoto = null;
        this.selectedServicePhoto = inputServicePhoto.files[0];

        //replace the first element (Hidden)

        // Rename the file (replace "newFileName" with your desired name)
        /*const newFileName = "profile_photo_" + fChanged.name; // Change this to your desired file name
        console.log(newFileName);
        console.log(fChanged);
        this.profilePhotoName = newFileName;
        fChanged.name =  "profile_img" ;*/

        this.ServicePhotoModif = true;

        //console.log(this.myDropzone.files);
        reader.onload = (e) => {
          this.previewURL = e.target.result;
        };

        reader.readAsDataURL(inputServicePhoto.files[0]);
      }
    },
  },
};
</script>
