<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div class="page-header align-items-start min-vh-50 pt-7 pb-9">
      <span class="mask bg-gradient-info opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5"></h1>
            <p class="text-lead text-white"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="p-2 container-fluid">
        <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
          <div class="col-12">
            <div class="row my-2">
              <div class="col-md-9">
                <div class="card my-2">
                  <div class="row p-4">
                    <div class="col-lg-2 d-flex justify-content-center">
                      <a href="javascript:;">
                        <div class="flex-shrink-0">
                          <img
                            :src="
                              imgCatUrl +
                              serviceData.seller_overview.profile_img
                            "
                            alt="Generic placeholder image"
                            class="img-fluid"
                            style="width: 180px; border-radius: 10px"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="col-lg-10 ps-0 mx-auto">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="p-md-0 pt-1">
                              <span class="font-weight-bolder mb-0 text-sm">
                                {{ serviceData.seller_overview.name }}
                              </span>
                              <div class="mt-4 mt-lg-0">
                                <span class="badge badge-primary mx-auto">{{
                                  serviceData.service_overview.category_name
                                }}</span>
                              </div>

                              <div class="d-flex my-1 align-items-center">
                                <star-rating
                                  class="mx-2 text-info"
                                  :star-size="20"
                                  :show-rating="false"
                                  :round-start-rating="false"
                                  :rating="serviceData.service_overview.ratings"
                                ></star-rating>
                                <span
                                  >({{
                                    serviceData.service_overview.rating_count
                                  }}
                                  ) avis</span
                                >
                              </div>

                              <div>
                                <span class="small my-1 ps-2"
                                  ><span class="fas fa-map me-1"></span>
                                  {{ serviceData.seller_overview.location }}
                                </span>
                              </div>

                              <span class="small my-1 ps-2"
                                ><span class="fas fa-phone me-1"></span>+216
                                {{ serviceData.seller_overview.mobileno }}</span
                              >

                              <div class="my-1 align-items-center"></div>
                            </div>
                          </div>

                          <div class="col-lg-8">
                            <Carousel
                              id="gallery"
                              :items-to-show="1"
                              :wrap-around="false"
                              v-model="currentSlide"
                            >
                              <Slide
                                v-for="(image, index) in serviceData
                                  .service_overview.service_image"
                                :key="index"
                              >
                                <div class="carousel__item">
                                  <img
                                    :src="imgCatUrl + image"
                                    alt=""
                                    class="card-img p-2 rounded-xl"
                                  />
                                </div>
                              </Slide>
                            </Carousel>

                            <Carousel
                              id="thumbnails"
                              :items-to-show="3"
                              :wrap-around="true"
                              v-model="currentSlide"
                              ref="carousel"
                            >
                              <Slide
                                v-for="(image, index) in serviceData
                                  .service_overview.service_image"
                                :key="index"
                              >
                                <div
                                  class="carousel__item"
                                  @click="slideTo(index - 1)"
                                >
                                  <img
                                    :src="imgCatUrl + image"
                                    alt=""
                                    class="card-img p-2 rounded-xl"
                                  />
                                </div>
                              </Slide>
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-none my-2">
                  <div class="card bg-gradient-default">
                    <div class="card-body">
                      <div class="flex-grow-1 ms-auto text-center">
                        <div class="d-flex justify-content-evenly rounded-3">
                          <div>
                            <p class="small text-muted mb-1">Visite(s)</p>
                            <p class="mb-0">
                              {{ serviceData.service_overview.views }}
                            </p>
                          </div>
                          <span class="mx-2">|</span>
                          <div class="px-3">
                            <p class="small text-muted mb-1">Booking</p>
                            <p class="mb-0">976</p>
                          </div>
                          <span class="mx-2">|</span>
                          <div>
                            <p class="small text-muted mb-1">Avis</p>
                            <p class="mb-0">8.5</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-2">
                  <div class="card bg-gradient-default">
                    <div class="card-body">
                      <h3
                        class="card-title text-info text-center text-gradient"
                      >
                        <span
                          class="text-gradient text-primary text-bold text-lg"
                          >{{
                            serviceData.service_overview.service_title
                          }}</span
                        >
                      </h3>

                      <span class="font-weight-bolder text-sm mb-1 p-2"
                        >Services offerts</span
                      >

                      <div class="d-flex flex-wrap text-sm p-2">
                        <span
                          v-for="(serviceOffered, index) in JSON.parse(
                            serviceData.service_overview.service_offered
                          )"
                          :key="index"
                          class="badge badge-success m-1"
                        >
                          {{ serviceOffered }}</span
                        >
                      </div>

                      <span class="font-weight-bolder text-sm mb-1 p-2"
                        >Description</span
                      >
                      <p class="text-xs mb-2 p-2" style="">
                        {{
                          serviceData.service_overview.about
                            .replace(/<[^>]*>?/gm, "")
                            .replace(/\s{2,}/g, " ")
                            .trim()
                        }}
                      </p>
                      <span class="font-weight-bolder text-sm mb-1 p-2"
                        >Service Video</span
                      >

                      <div class="d-flex justify-content-center p-2">
                        <div class="embed-responsive embed-responsive-16by9">
                          <iframe
                            class="embed-responsive-item"
                            src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="my-2">
                  <div class="card bg-gradient-default">
                    <div class="card-body">
                      <h3
                        class="card-title text-info text-center text-gradient"
                      >
                        <span
                          class="text-gradient text-primary text-bold text-lg"
                          >Prendre Rendez-vous</span
                        >
                      </h3>
                      <div class="p-4">
                        <div class="col-lg-12 ps-0 mx-auto">
                          <div class="row">
                            <div class="col-lg-12 ps-0 mx-auto">
                              <div class="row">
                                <div class="mx-auto col-lg-12 col-sm-12">
                                  <div class="mt-4 row">
                                    <p
                                      class="text-xs text-success"
                                      :class="isAuth ? 'd-none' : ''"
                                    >
                                      * Merci de s'identifier avant de passer à
                                      la réservation...
                                    </p>

                                    <div
                                      class="mt-2 col-lg-6 col-sm-12 my-lg-2"
                                    >
                                      <label class="form-label">Date</label>
                                      <flat-pickr
                                        v-model="selectedDate"
                                        class="form-control datetimepicker"
                                        placeholder=""
                                        :config="config"
                                      ></flat-pickr>
                                    </div>

                                    <div
                                      class="mt-2 col-lg-6 col-sm-12 my-lg-2"
                                    >
                                      <label>Heure</label>

                                      <select
                                        id="choices-time-slot"
                                        class="form-control"
                                        v-model="formBooking.booking_time"
                                        name="choices-time-slot"
                                      >
                                        <option value="Choice 1" selected>
                                          --:--
                                        </option>
                                        <option
                                          class="text-sm"
                                          aria-sort="false"
                                          v-for="timeslot in this.$store.state
                                            .serviceAvailability"
                                          :key="timeslot.id"
                                          :id="timeslot.id"
                                        >
                                          {{
                                            getTwentyFourHourTime(
                                              timeslot.start_time
                                            )
                                          }}
                                          -
                                          {{
                                            getTwentyFourHourTime(
                                              timeslot.end_time
                                            )
                                          }}
                                          <small class="d-none">
                                            {{ timeslot.start_time }} -
                                          </small>
                                          <small class="d-none">
                                            {{ timeslot.end_time }}</small
                                          >
                                        </option>
                                      </select>
                                    </div>

                                    <div
                                      class="mt-2 col-lg-6 col-sm-12 my-lg-2"
                                    >
                                      <label
                                        class="mb-2"
                                        for="exampleFormControlTextarea1"
                                        >Emplacement</label
                                      >

                                      <div
                                        class="form-check mb-2 mx-2"
                                        v-for="bookloc in bookLocation"
                                        :key="bookloc.name"
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioLocation"
                                          :value="bookloc.name"
                                          v-model="selectedLocation"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="flexRadioLocation"
                                          >{{ bookloc.name }}</label
                                        >
                                      </div>

                                      <input
                                        v-if="displocation"
                                        :disabled="dislocation"
                                        type="text"
                                        class="form-control"
                                        v-model="formBooking.location"
                                        placeholder="Votre Adresse / Emplacement"
                                        aria-label="Votre Emplacement"
                                      />
                                    </div>

                                    <div
                                      class="mt-2 col-lg-6 col-sm-12 my-lg-2"
                                    >
                                      <label for="exampleFormControlTextarea1"
                                        >Note</label
                                      >
                                      <textarea
                                        v-model="formBooking.notes"
                                        class="form-control mb-2"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                      ></textarea>

                                      <label
                                        class="mb-2"
                                        for="exampleFormControlTextarea1"
                                        >Paiement</label
                                      >

                                      <div
                                        class="form-check mb-2 mx-2"
                                        v-for="paymethod in bookPayment"
                                        :key="paymethod.name"
                                      >
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          name="flexRadioPayment"
                                          :value="paymethod.name"
                                          v-model="selectedPay"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="flexRadioPayment"
                                          >{{ paymethod.name }}</label
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mt-4 row">
                                    <div class="col-lg-12">
                                      <button
                                        v-show="!(isResponse && resStatus)"
                                        :disabled="!isAuth || userType != 2"
                                        @click="getBookingInfo"
                                        class="mb-0 btn bg-gradient-success mt-lg-auto w-100"
                                        type="button"
                                        name="button"
                                      >
                                        Réserver
                                      </button>
                                    </div>
                                    <div
                                      v-show="isResponse && resStatus"
                                      class="alert alert-info text-center my-2 me-3"
                                      role="alert"
                                    >
                                      <span
                                        class="alert-text text-xs text-white"
                                        ><strong>Success Réservation! </strong>
                                        Le provider vous contacterais dans les
                                        brefs delais !
                                      </span>
                                    </div>

                                    <div
                                      v-show="isResponse && !resStatus"
                                      class="alert alert-danger text-center my-2 me-3"
                                      role="alert"
                                    >
                                      <span
                                        class="alert-text text-xs text-white"
                                        ><strong>Echec Réservation ! </strong>
                                        Merci de vérifier les informations et
                                        réessayer
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card my-2">
                  <div class="row card-body p-4">
                    <div class="flex-shrink-0 text-center">
                      <img
                        src="https://back.labes.com.tn/uploads/profile_img/1683061547.jpg"
                        alt="Generic placeholder image"
                        class="img-fluid"
                        style="width: 180px; border-radius: 10px"
                      />
                    </div>
                    <div class="flex-grow-1 ms-auto text-center">
                      <h5 class="m-1 font-weight-bolder text-sm">
                        Dr Henda BEN HASSOUNA GOUIDER
                      </h5>
                      <p class="mb-2 pb-1 text-sm" style="color: #2b2a2a">
                        SOINS INFIRMIERS
                      </p>
                      <div
                        class="d-flex justify-content-center rounded-3 p-2 mb-2"
                      >
                        <div class="text-xs">
                          <p class="small text-muted mb-1">Visite(s)</p>
                          <p class="mb-0">41</p>
                        </div>
                        <span class="mx-2">|</span>
                        <div class="px-3 text-xs">
                          <p class="small text-muted mb-1">Bookings</p>
                          <p class="mb-0">976</p>
                        </div>
                        <span class="mx-2">|</span>
                        <div class="text-xs">
                          <p class="small text-muted mb-1">Avis</p>
                          <p class="mb-0">8.5</p>
                        </div>
                      </div>
                      <div class="d-flex pt-1">
                        <button
                          type="button"
                          class="btn btn-outline-primary me-1 flex-grow-1 w-100"
                        >
                          Consulter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import Choices from "choices.js";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import flatPickr from "vue-flatpickr-component";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";
import axios from "axios";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import starRating from "vue-star-rating";

export default {
  name: "ServiceDetail",
  data() {
    return {
      isAuth: false,
      userType: "0",
      resStatus: false,
      isResponse: false,
      serviceOffer: [],
      auth: {
        email: "",
        password: "",
        remember: false,
      },
      imgCatUrl: "https://back.labes.com.tn/",
      isErrorLogin: false,
      errMessage: "",
      selectedDate: "",
      service_id: this.$route.params.id,
      ServiceAvailable: [],
      paramsAvailable: {
        service_id: "",
        date: "",
      },
      config: {
        //allowInput: true,
        minDate: "today",
        dateFormat: "d-m-Y",
      },
      formBooking: {
        service_id: this.$route.params.id,
        notes: "",
        from_time: "",
        to_time: "",
        cod: "1",
        service_date: "",
        latitude: "36.2365558",
        longitude: "10.2365558",
        location: "",
        amount: "70",
        booking_currency: "TND",
        //token: "1CnHryklVUkERuO",
      },
      bookLocation: [
        { name: "à domicile" },
        { name: "une autre adresse" },
        { name: "déplacement" },
      ],
      bookPayment: [{ name: "Cash" }, { name: "carte bancaire" }],
      selectedLocation: "",
      selectedPay: "",
      serviceLoc: "",
      displocation: false,
      dislocation: true,
      currentSlide: 1,
    };
  },
  components: {
    Navbar,
    AppFooter,
    //OtherProducts,
    flatPickr,
    Carousel,
    Slide,
    starRating,
  },
  watch: {
    selectedDate() {
      this.paramsAvailable.date = this.selectedDate;
      this.paramsAvailable.service_id = this.service_id;
      this.getServiceAvailability();
    },
    selectedLocation: function (newLocation) {
      this.displocation = true;
      this.dislocation = true;

      if (newLocation == "à domicile") {
        this.formBooking.location = newLocation;
      } else if (newLocation == "déplacement") {
        this.formBooking.location = newLocation;
      } else {
        this.dislocation = false;
        this.formBooking.location = "";
      }
    },
  },
  async created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();

    //console.log("The id is: " + this.$route.params.id);
    await this.$store.dispatch("loadService", this.$route.params.id);
    this.isAuth = this.$store.state.auth.authenticated;

    if (this.isAuth) {
      this.userType = this.$store.state.auth.user.type;
    }
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    serviceData() {
      return this.$store.state.service;
    },
    /* ServiceAvailable() {
      return this.$store.state.serviceAvailability;
    },*/
    /*isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },*/
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    const bootstrap = this.$store.state.bootstrap;
    this.getChoices("choices-quantity");
    this.getChoices("choices-time");
    this.getChoices("choices-time-slot");

    // Gallery Carousel
    if (document.getElementById("products-carousel")) {
      var myCarousel = document.querySelector("#products-carousel");
      // eslint-disable-next-line no-unused-vars
      var carousel = new bootstrap.Carousel(myCarousel);
    }

    // Products gallery

    var initPhotoSwipeFromDOM = function (gallerySelector) {
      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item;

        for (var i = 0; i < numNodes; i++) {
          figureEl = thumbElements[i]; // <figure> element
          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue;
          }

          linkEl = figureEl.children[0]; // <a> element

          size = linkEl.getAttribute("data-size").split("x");

          // create slide object
          item = {
            src: linkEl.getAttribute("href"),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10),
          };

          if (figureEl.children.length > 1) {
            // <figcaption> content
            item.title = figureEl.children[1].innerHTML;
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.children[0].getAttribute("src");
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
        }

        return items;
      };

      // find nearest parent element
      var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
      };

      // triggers when user clicks on thumbnail
      var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function (el) {
          return el.tagName && el.tagName.toUpperCase() === "FIGURE";
        });

        if (!clickedListItem) {
          return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

        for (var i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue;
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
          }
          nodeIndex++;
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery);
        }
        return false;
      };

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
          params = {};

        if (hash.length < 5) {
          return params;
        }

        var vars = hash.split("&");
        for (var i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue;
          }
          var pair = vars[i].split("=");
          if (pair.length < 2) {
            continue;
          }
          params[pair[0]] = pair[1];
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10);
        }

        return params;
      };

      var openPhotoSwipe = function (
        index,
        galleryElement,
        disableAnimation,
        fromURL
      ) {
        var pswpElement = document.querySelectorAll(".pswp")[0],
          gallery,
          options,
          items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute("data-pswp-uid"),

          getThumbBoundsFn: function (index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
              pageYScroll =
                window.pageYOffset || document.documentElement.scrollTop,
              rect = thumbnail.getBoundingClientRect();

            return {
              x: rect.left,
              y: rect.top + pageYScroll,
              w: rect.width,
            };
          },
        };

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (var j = 0; j < items.length; j++) {
              if (items[j].pid == index) {
                options.index = j;
                break;
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1;
          }
        } else {
          options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return;
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(
          pswpElement,
          // eslint-disable-next-line no-undef
          PhotoSwipeUI_Default,
          items,
          options
        );
        gallery.init();
      };

      // loop through all gallery elements and bind events
      var galleryElements = document.querySelectorAll(gallerySelector);

      for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute("data-pswp-uid", i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      var hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(
          hashData.pid,
          galleryElements[hashData.gid - 1],
          true,
          true
        );
      }
    };

    // execute above function
    initPhotoSwipeFromDOM(".my-gallery");
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    ...mapActions({
      signIn: "auth/afterLogin",
    }),

    async getServiceAvailability() {
      console.log(this.paramsAvailable.service_id);
      await this.$store.dispatch(
        "loadServiceAvailability",
        this.paramsAvailable
      );
      this.ServiceAvailable = this.$store.state.serviceAvailability;
    },

    async getBookingInfo() {
      this.formBooking.from_time = this.formBooking.booking_time.slice(0, 5);
      this.formBooking.to_time = this.formBooking.booking_time.slice(8, 13);
      this.formBooking.booking_time = this.formBooking.booking_time.slice(
        14,
        33
      );
      //this.formBooking.from_time = this.formBooking.booking_time.slice(0, 5);
      //this.formBooking.to_time = this.formBooking.booking_time.slice(11, 16);
      this.formBooking.service_date = this.selectedDate;
      console.log(this.formBooking);
      await this.bookService();
    },

    getOffredServices(arr) {
      return JSON.parse(arr).text;
    },
    transformData(str) {
      this.serviceOffer = JSON.parse(str);
    },

    getTwentyFourHourTime(amPmString) {
      var d = new Date("1/1/2013 " + amPmString);

      return (
        String(d.getHours()).padStart(2, "0") +
        ":" +
        String(d.getMinutes()).padStart(2, "0")
      );
    },

    /*async getServiceAvailability() {
     await axios
        .get("/service_availability", {
          params: {
            service_id: this.service_id,
            date: this.selectedDate,
          },
        })
        .then((res) => (this.ServiceAvailable = res.data))
        .catch((error) => {
          console.log(error);
        });
    },*/

    showSwal(type) {
      if (type === "success-message") {
        this.$swal({
          icon: "success",
          title: "Réservation de service!",
          text: "Votre demande réservation a été envoyé!",
          type: type,
        });
      }
    },
    async bookService() {
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      if (this.selectedPay == "Cash") {
        this.formBooking.cod = 1;
      } else {
        this.formBooking.cod = 0;
      }
      await axios
        .post("book_service", this.formBooking)
        .then(({ data }) => {
          console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            this.showSwal("success-message");
            this.isResponse = true;
            this.resStatus = true;
          } else {
            console.log(data.status);
            this.isResponse = true;
            this.resStatus = false;
          }
        })
        .catch(({ response }) => {
          this.errMessage = response.data.message;
          //console.log(response);
          this.resStatus = false;
          this.isResponse = true;
        })
        .finally(() => {
          //alert("login finish ")
        });
    },

    slideTo(val) {
      this.currentSlide = val;
    },
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = document.getElementById(id);
        return new Choices(element, {
          searchEnabled: false,
          itemSelectText: "",
          allowHTML: true,
        });
      }
    },
  },
};
</script>
