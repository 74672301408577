<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div>
        <!--<argon-button color="white" variant="outline">New order</argon-button>-->
      </div>
    </div>

    <div class="row">
      <div class="col-12 row">
        <div class="col-md-6 col-sm-12">
          <div v-if="this.subscriptionId == 0" class="mt-4 mt-sm-0">
            <div class="card bg-white mt-4">
              <div class="card-body">
                <h6 class="mb-0 text-lg text-gray">Plan Details</h6>
                <div class="mb-2">
                  <sup class="text-gray"></sup>

                  <span class="h2 text-gray">FREE </span>
                  <div class="row">
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8">
                        <h6 class="mb-0 text-xxs text-gray">Started On:</h6>
                        -
                      </small>
                    </div>
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8"
                        ><h6 class="mb-0 text-xxs text-gray">Price:</h6>
                        0 DT</small
                      >
                    </div>
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8"
                        ><h6 class="mb-0 text-xxs text-gray">Expiry :</h6>
                        Lifetime
                      </small>
                    </div>
                  </div>
                  <h6 class="my-2 text-xs text-gray">Last Payment</h6>
                  <div class="row">
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8"> - </small>
                    </div>
                    <div class="col">
                      <span class="text-xxs text-gray">- </span>
                    </div>
                    <div class="col">
                      <span> - </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer pt-0"></div>
            </div>
          </div>
          <div v-if="this.subscriptionId > 0" class="mt-4 mt-sm-0">
            <div class="card bg-white mt-4">
              <div
                class="card-body"
                v-for="userSubscription of subscriptionDetails.subscription_data"
                :key="userSubscription.id"
              >
                <h6 class="mb-0 text-lg text-gray">Plan Details</h6>
                <div class="mb-2">
                  <sup class="text-gray"></sup>

                  <span class="h2 text-gray"
                    >{{ userSubscription.subscription_name }}
                  </span>

                  <div class="row">
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8">
                        <h6 class="mb-0 text-xxs text-gray">Started On:</h6>

                        {{ userSubscription.subscription_date.split(" ")[0] }}
                      </small>
                    </div>
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8"
                        ><h6 class="mb-0 text-xxs text-gray">Price:</h6>
                        {{ userSubscription.fee.split(".")[0] }} DT</small
                      >
                    </div>
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8"
                        ><h6 class="mb-0 text-xxs text-gray">Expiry :</h6>
                        {{ userSubscription.expiry_date_time.split(" ")[0] }}
                        ({{ userSubscription.fee_description }})
                      </small>
                    </div>
                  </div>
                  <h6 class="my-2 text-xs text-gray">Last Payment</h6>
                  <div class="row">
                    <div class="col">
                      <small class="text-gray text-xxs opacity-8">
                        {{ userSubscription.subscription_date.split(" ")[0] }}
                      </small>
                    </div>
                    <div class="col">
                      <span class="text-xxs text-gray"
                        >{{ userSubscription.fee.split(".")[0] }} DT
                      </span>
                    </div>
                    <div class="col">
                      <span
                        :class="
                          userSubscription.status == 1
                            ? 'text-xxs badge bg-success'
                            : 'text-xxs badge bg-danger'
                        "
                        >Paid
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer pt-0"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body row">
            <h6 class="mb-3 text-lg text-gray">Available Plans</h6>

            <div
              v-for="subscription of subscriptionDetails.subscription_list"
              :key="subscription.id"
              class="col-xl-3 col-md-6 my-3"
            >
              <div
                class="card"
                :class="
                  subscription.id == this.subscriptionId
                    ? 'bg-gradient-success'
                    : ''
                "
              >
                <div class="card-body">
                  <div>
                    <h2>{{ subscription.subscription_name }}</h2>
                    <h4 class="mb-0">
                      {{ (subscription.fee / 12).toFixed(1) }}
                      {{ subscription.currency }}

                      <small class="text-xs">Monthly Price </small>
                    </h4>
                    <h5 class="text-center">
                      <small class="text-sm"
                        >( {{ subscription.fee }} {{ subscription.currency }} /
                        Year )</small
                      >
                    </h5>
                  </div>
                  <div>
                    <p>
                      <i class="far fa-check-circle"></i>
                      Duration:
                      <span> {{ subscription.duration }} Months </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <i class="far fa-check-circle"></i>
                      <span> Secure Payment </span>
                    </p>
                  </div>
                  <div class="row d-none">
                    <div class="col-12">
                      <img
                        src="https://back.labes.com.tn/assets/img/stripe.png"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer pt-0">
                  <button
                    class="btn btn-sm btn-dark mb-0 w-100"
                    :class="
                      subscription.id == this.subscriptionId ? 'd-none' : ''
                    "
                    :v-show="subscription.id != this.subscriptionId"
                    @click="getPlanPrice(subscription)"
                  >
                    <span class="btn-inner--icon me-2">
                      <i class="ni ni-diamond"></i>
                    </span>
                    Upgrade
                  </button>
                  <button
                    :class="
                      subscription.id != this.subscriptionId ? 'd-none' : ''
                    "
                    :disabled="true"
                    class="btn btn-sm btn-dark mb-0 w-100"
                    @click="getPlanPrice(subscription)"
                  >
                    <span class="btn-inner--icon me-2">
                      <i class="ni ni-diamond"></i>
                    </span>
                    Purchased
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-none">
        <div class="card mt-4">
          <div class="card-body">
            <h6 class="mb-0 text-lg text-gray">Subscribed Details</h6>

            <div class="table-responsive">
              <table id="order-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-xxs">Plan</th>
                    <th class="text-xxs">Start Date</th>
                    <th class="text-xxs">End Date</th>
                    <th class="text-xxs">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="subscription of subscriptionDetails.subscription_list"
                    :key="subscription.id"
                  >
                    <td>
                      <div class="d-flex align-items-left">
                        <argon-checkbox class="d-none" />
                        <p class="text-xxs ms-2 mb-0"></p>
                      </div>
                    </td>

                    <td class="text-left">
                      <p class="my-2 text-xxs"></p>
                    </td>
                    <td class="text-left">
                      <p class="my-2 text-xxs"></p>
                    </td>

                    <td class="text-xxs font-weight-bold">
                      <div class="d-flex align-items-left">
                        <argon-button
                          :color="
                            subscription.paid_status === '1'
                              ? 'success'
                              : 'danger'
                          "
                          variant="outline"
                          class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                        >
                          <i
                            class="fas"
                            :class="
                              subscription.paid_status === '1'
                                ? 'fa-check'
                                : 'fa-times'
                            "
                            aria-hidden="true"
                          ></i>
                        </argon-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import axios from "axios";

import $ from "jquery";

export default {
  name: "Subscription Provider",
  components: {
    ArgonButton,
    ArgonCheckbox,
  },
  data() {
    return {
      planPrice: "",
      paymentReturn: false,
      subscriptionId: "-1",
      paymentStatusCode: "",
      paymentStatusColor: "",
      paymentStatusTitle: "",
      paymentDetails: [],
      imgUrl: "https://back.labes.com.tn/",
      uRole: "",
      subPayForm: {
        subscription_id: "",
        paytype: "CTP",
        transaction_id: "",
        payment_details: "",
      },
    };
  },
  mounted() {
    if (document.getElementById("order-list")) {
      const dataTableSearch = new DataTable("#order-list", {
        searchable: true,
        fixedHeight: false,
        perPageSelect: false,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function (el) {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
  },
  async created() {
    await this.$store.dispatch("loadSubscription");
    this.uRole = this.$store.state.auth.user.type;
    if (this.$store.state.subscriptionDetails?.subscription_data) {
      this.subscriptionId =
        this.$store.state.subscriptionDetails.subscription_data[0].subscription_id;
    } else {
      this.subscriptionId = "0";
    }

    this.searchUrlParam();
  },
  computed: {
    subscriptionDetails() {
      return this.$store.state.subscriptionDetails;
    },
  },
  methods: {
    getPlanPrice(subsDetail) {
      this.planPrice = subsDetail.fee;
      this.subPayForm.subscription_id = subsDetail.id;
      this.showPaySubscriptionSwal("pay-subscription");
    },
    searchUrlParam() {
      const queryString = window.location.search;
      //console.log(queryString);
      const urlParams = new URLSearchParams(queryString);
      //console.log(urlParams.has('orderId'));
      if (urlParams.has("orderId") && urlParams.has("planId")) {
        //console.log(urlParams.get("orderId"));
        this.paymentReturn = true;
        this.getOrderPaymentStatus(
          urlParams.get("orderId"),
          urlParams.get("planId")
        );
      }
    },
    async getOrderPaymentStatus(smtOrderId, planId) {
      //var urlParams = new URLSearchParams(window.location.search);
      var username = "0870543014";
      var pwd = "v9R33kcRT";
      var orderId = smtOrderId;
      var language = "fr";
      var userApiToken = localStorage.getItem("api_token");
      var invForm = this.subPayForm;

      console.log;
      await $.ajax({
        type: "post",
        url: "https://test.clictopay.com/payment/rest/getOrderStatus.do",
        //url: "https://ipay.clictopay.com/payment/rest/getOrderStatus.do",
        dataType: "json",
        data: {
          userName: username,
          password: pwd,
          orderId: orderId,
          language: language,
        },

        success: (data) => {
          //  console.log(data);
          var approvalCode = data["approvalCode"];
          var code = data["ErrorCode"];
          var cardPan = data["Pan"];
          var msg = data["ErrorMessage"];
          var OrderNumber = data["OrderNumber"];
          var cardHolderName = data["cardholderName"];
          var cardExpiration = data["expiration"];
          var ip = data["Ip"];
          var orderId = smtOrderId;

          //console.log("ErrorCode:" + code);
          if (code == 0) {
            //show Alert  Success  Payment
            /*console.log(approvalCode);
            console.log(OrderNumber);
            console.log(cardHolderName);
            console.log(cardPan);
            console.log(cardExpiration);
            console.log(ip);
            console.log(orderId);*/
            //msg = "Payment Validated";
            //this.showPaymentSwal("success-payment", msg);
            invForm.approvalCode = approvalCode;
            invForm.OrderNumber = OrderNumber;
            invForm.cardHolderName = cardHolderName;
            invForm.cardPan = cardPan;
            invForm.cardExpiration = cardExpiration;
            invForm.ip = ip;
            invForm.orderId = orderId;

            invForm.transaction_id = orderId;
            invForm.subscription_id = planId;
            invForm.payment_details = cardPan;
            axios.defaults.headers["token"] = userApiToken;
            //console.log(localStorage.getItem("api_token"));
            //console.log(invForm);
            axios
              .post("subscription_success", invForm)
              .then((response) => {
                if (response.data.response.response_code != 200) {
                  //console.log("failed to update payment");
                  this.showPaymentSwal(
                    "error-payment",
                    response.data.response.response_message
                  );
                } else {
                  this.showPaymentSwal("success-payment", msg);
                  //window.location.reload();
                  setTimeout(
                    location.reload.bind(window.location.href.split("?")[0]),
                    6000
                  );
                }
                //return response.data.response;
              })
              .catch((error) => {
                //console.log(error);
                //display payment update payment failed
                this.showPaymentSwal("error-payment", error);
              });
          } else {
            //show Alert Failed  Payment
            // console.log(msg);
            this.showPaymentSwal("error-payment", msg);
          }
        },

        error: function (xhr, status, error) {
          //console.log("failure");
          //console.log(status);
          // console.log(error);
          //alert('failure');
          var err = eval("(" + xhr.responseText + ")");
          console.log(err.Message);
          this.showPaymentSwal(
            "error-payment",
            err + "- status:" + status + " - error:" + error
          );
        },
      });
    },
    showPaymentSwal(type, value) {
      if (type === "success-payment") {
        this.$swal({
          icon: "success",
          title: "",
          text: value,
          type: type,
        });
      } else if (type === "error-payment") {
        this.$swal({
          icon: "error",
          title: "",
          text: value,
          type: type,
        });
      }
    },
    showPaySubscriptionSwal(type) {
      if (type === "update-invoice") {
        this.$swal({
          title: "Update Invoice ",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: () => {
            axios.defaults.headers["token"] = localStorage.getItem("api_token");

            return axios
              .post("update_invoice", this.invoiceForm)
              .then((response) => {
                if (response.data.response.response_code != 200) {
                  let timerInterval;
                  this.swal({
                    title: "Invoice Update",
                    icon: "error",
                    html: response.data.response.response_code,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                      // this.$swal.showLoading();
                      const b = this.$swal
                        .getHtmlContainer()
                        .querySelector("b");
                      timerInterval = setInterval(() => {
                        b.textContent = this.$swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                      //window.location.reload();
                    },
                  });
                }
                return response.data.response;
              })
              .catch((error) => {
                console.log(error);
                let timerInterval;
                this.$swal({
                  title: "Invoice Update",
                  icon: "error",
                  html: "Update Error",
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    const b = this.$swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft();
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                    //window.location.reload();
                  },
                });
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          console.log(result.isConfirmed);
          console.log(result.value);

          if (result.isConfirmed) {
            let timerInterval;
            if (result.value.response_code == 200) {
              if (this.isCOD == 0 && this.userType == 2) {
                this.sendChatMessageInvoice(
                  "La facture à été payée par carte bancaire"
                );
              } else {
                this.sendChatMessageInvoice(
                  "La facture à été acceptéé et va être payée en Cash"
                );
              }

              if (this.isCOD == 0 && this.userType == 2) {
                //alert("GoToPayment");
                this.showPaySubscriptionSwal("pay-invoice");
              } else {
                this.$swal({
                  title: "Invoice Update",
                  icon: "success",
                  html: result.value.response_message,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    //this.$swal.showLoading();
                    const b = this.$swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft();
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                    window.location.reload();
                  },
                });
              }
            } else {
              this.$swal({
                title: "Invoice Update",
                icon: "error",
                html: "Update Error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  // this.$swal.showLoading();
                  const b = this.$swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = this.$swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                  window.location.reload();
                },
              });
            }
          }
        });
      } else if (type === "pay-subscription") {
        this.$swal({
          title: "Are you sure to upgrade your plan?",
          text: "Vou will be redirected to safe payment",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Connecting to server",
              text: "In progress....",
              icon: "info",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });

            //this.ctpForm.amount = this.planPrice;
            var username = "0870543014";
            var pwd = "v9R33kcRT";
            var amount = this.planPrice * 1000;
            var rand = Math.random();
            var sessionPay = this.planPrice * 1000 * rand;

            var failUrl =
              "http://localhost:8080/labes-front/accounting/subscription-provider?planId=" +
              this.subPayForm.subscription_id;

            var ReturnUrl =
              "http://localhost:8080/labes-front/accounting/subscription-provider?planId=" +
              this.subPayForm.subscription_id;

            var description = "Commnde de test";

            $.ajax({
              type: "post",
              url: "https://test.clictopay.com/payment/rest/register.do",
              //url: "https://ipay.clictopay.com/payment/rest/register.do",
              dataType: "json",
              data: {
                userName: username,
                password: pwd,
                orderNumber: sessionPay,
                amount: amount,
                currency: "788",
                language: "en",
                returnUrl: ReturnUrl,
                failUrl: failUrl,
                description: description,
              },
              beforeSend: function () {
                console.log("beforeSend");
                $("#alertPayment").html(
                  '<div class="alert alert-success alert-dismissible" role="alert">Connecting To Server</div>'
                );
              },
              success: function (data) {
                console.log("success");
                console.log("orderId" + data["orderId"]);
                console.log("formUrl" + data["formUrl"]);
                console.log("errorCode" + data["errorCode"]);
                console.log("errorMessage" + data["errorMessage"]);
                console.log("success" + data.success);
                var data_order_id = data["orderId"];
                if (typeof data_order_id === "undefined") {
                  this.$swal({
                    title: "Payment Cancelled",
                    text: data["errorMessage"],
                    icon: "error",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  });
                  //alert('Variable "data_order_id" is undefined.');
                } else if (data_order_id === null) {
                  this.$swal({
                    title: "Payment Cancelled",
                    text: data["errorMessage"],
                    icon: "error",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  });
                } else {
                  // window.location.href = data["formUrl"];
                  window.location.assign(data["formUrl"]);
                }
              },

              error: function (xhr, status, error) {
                console.log("failure");
                console.log(status);
                console.log(error);
                //alert('failure');
                var err = eval("(" + xhr.responseText + ")");
                console.log(err.Message);
              },
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal({
              title: "Payment Cancelled",
              text: "",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          }
        });
      }
    },
  },
};
</script>
