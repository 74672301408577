<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div>
        <!--<argon-button color="white" variant="outline">New order</argon-button>-->
      </div>
    </div>
    <div class="row">
      <div class="col-12 row">
        <div class="col-md-6 col-sm-12">
          <div class="mt-4 mt-sm-0">
            <div class="card bg-white mt-4">
              <div class="card-body">
                <h6 class="mb-0 text-lg text-gray">
                  Wallet ({{ walletHistory.wallet_info.wallet.token }} )
                </h6>

                <div class="mb-2">
                  <h6 class="mb-0 text-xs text-gray">Balance</h6>
                  <sup class="text-gray"></sup>
                  <span class="h2 text-gray"
                    >{{
                      (
                        walletHistory.wallet_info.wallet.wallet_amt / 1000
                      ).toFixed(3)
                    }}
                    /
                    {{ walletHistory.wallet_info.wallet.currency }}
                  </span>
                  <div class="row">
                    <div class="col">
                      <small class="text-gray opacity-8">
                        Total Debit :
                        {{
                          (
                            walletHistory.wallet_info.wallet.total_debit / 1000
                          ).toFixed(3)
                        }}
                        / {{ walletHistory.wallet_info.wallet.currency }}</small
                      >
                      <div class="progress progress-xs my-2">
                        <div
                          class="progress-bar bg-success"
                          style="width: 50%"
                        ></div>
                      </div>
                    </div>
                    <div class="col">
                      <small class="text-gray opacity-8"
                        >Total Crédit :
                        {{
                          (
                            walletHistory.wallet_info.wallet.total_credit / 1000
                          ).toFixed(3)
                        }}
                        / {{ walletHistory.wallet_info.wallet.currency }}</small
                      >
                      <div class="progress progress-xs my-2">
                        <div
                          class="progress-bar bg-warning"
                          style="width: 50%"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="text-gray opacity-8 mt-2 text-sm">
                    Total Revenu
                  </div>
                  <div>
                    <span class="text-gray opacity-8">
                      {{
                        (
                          walletHistory.wallet_info.wallet.total_credit / 1000
                        ).toFixed(3)
                      }}
                      / {{ walletHistory.wallet_info.wallet.currency }}</span
                    >
                  </div>
                  <div class="text-gray opacity-8 mt-2 text-sm">
                    Frais d'internet
                  </div>
                  <div>
                    <span class="text-danger font-weight-600"
                      >- 45,000 DT
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-footer pt-0">
                <button class="btn btn-sm btn-dark mb-0 w-100">
                  Alimenter le compte
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none col-md-6 col-sm-12">
          <div class="mt-4 mt-sm-0">
            <div class="card bg-white mt-4">
              <div class="card-body">
                <h6 class="mb-0 text-lg text-gray">Withdraw Request</h6>
                <div class="mb-2">
                  <sup class="text-gray d-none">DT</sup>
                  <span class="h2 text-gray">275,000 DT</span>
                  <div class="row">
                    <div class="col">
                      <small class="text-gray opacity-8">CB: 320,000 DT</small>
                      <div class="progress progress-xs my-2">
                        <div
                          class="progress-bar bg-success"
                          style="width: 50%"
                        ></div>
                      </div>
                    </div>
                    <div class="col">
                      <small class="text-gray opacity-8"
                        >CASH: 420,000 DT</small
                      >
                      <div class="progress progress-xs my-2">
                        <div
                          class="progress-bar bg-warning"
                          style="width: 50%"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="text-gray opacity-8 mt-2 text-sm">
                    Total Revenu
                  </div>
                  <div>
                    <span class="text-gray opacity-8">740,000 DT</span>
                  </div>
                  <div class="text-gray opacity-8 mt-2 text-sm">Commission</div>
                  <div>
                    <span class="text-danger font-weight-600"
                      >- 45,000 DT
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-footer pt-0">
                <button class="btn btn-sm btn-dark mb-0 w-100">
                  demande de retrait
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <h6 class="mb-0 text-lg text-gray">Recent Transactions</h6>

            <div class="table-responsive">
              <table id="order-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-xxs">Id</th>

                    <th class="d-none">Service</th>
                    <th class="text-xxs">Date</th>
                    <th class="text-xxs">Wallet</th>
                    <th class="text-xxs">Credit</th>
                    <th class="text-xxs">Debit</th>
                    <th class="text-xxs">Disponible</th>
                    <th class="text-xxs">Raison</th>
                    <th class="text-xxs">Status</th>
                    <th class="text-xxs">Documents</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="walletH of walletHistory.wallet_info.wallet_history"
                    :key="walletH.id"
                  >
                    <td>
                      <div class="d-flex align-items-center">
                        <argon-checkbox class="d-none" />
                        <p class="text-xxs ms-2 mb-0">{{ walletH.id }}</p>
                      </div>
                    </td>

                    <td class="text-xxs d-none">
                      <span class="my-2 text-xs">{{
                        walletH.service_title
                      }}</span>
                    </td>
                    <td class="text-center">
                      <p class="my-2 text-xxs">
                        {{ walletH.created_at }}
                      </p>
                    </td>
                    <td class="text-center">
                      <p class="my-2 text-xxs">
                        {{ (walletH.current_wallet / 1000).toFixed(3) }}
                      </p>
                    </td>
                    <td class="text-center">
                      <p class="my-2 text-xxs">
                        {{ (walletH.credit_wallet / 1000).toFixed(3) }}
                      </p>
                    </td>
                    <td class="text-center">
                      <p class="my-2 text-xxs">
                        {{ (walletH.debit_wallet / 1000).toFixed(3) }}
                      </p>
                    </td>
                    <td class="text-center">
                      <p class="my-2 text-xxs">
                        {{ (walletH.avail_wallet / 1000).toFixed(3) }}
                      </p>
                    </td>
                    <td class="text-center">
                      <p class="my-2 text-xxs">
                        {{ walletH.reason }}
                      </p>
                    </td>
                    <td class="text-xxs font-weight-bold">
                      <div class="d-flex align-items-center">
                        <argon-button
                          :color="
                            walletH.paid_status === '1' ? 'success' : 'danger'
                          "
                          variant="outline"
                          class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                        >
                          <i
                            class="fas"
                            :class="
                              walletH.paid_status === '1'
                                ? 'fa-check'
                                : 'fa-times'
                            "
                            aria-hidden="true"
                          ></i>
                        </argon-button>
                      </div>
                    </td>
                    <td class="text-center">
                      <p
                        :value="
                          walletH.documents === '' ? '-' : walletH.documents
                        "
                        class="my-2 text-xxs"
                      ></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";

export default {
  name: "Wallet",
  components: {
    ArgonButton,
    ArgonCheckbox,
  },
  data() {
    return {
      paymentStatusCode: "",
      paymentStatusColor: "",
      paymentStatusTitle: "",
      paymentDetails: [],
      imgUrl: "https://back.labes.com.tn/",
      uRole: "",
    };
  },
  mounted() {
    if (document.getElementById("order-list")) {
      const dataTableSearch = new DataTable("#order-list", {
        searchable: true,
        fixedHeight: false,
        perPageSelect: false,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function (el) {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
  },
  async created() {
    await this.$store.dispatch("loadWalletHistory");
    this.uRole = this.$store.state.auth.user.type;
  },
  computed: {
    walletHistory() {
      return this.$store.state.walletHistory;
    },
  },
  methods: {},
};
</script>
