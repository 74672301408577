<template>
    <navbar />
    <div
      class="page-header position-relative"
      :style="{
        backgroundImage:
          'url(' + require('../../assets/img/pricing-header-bg.jpg') + ')',
        backgroundSize: 'cover',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
      <div class="container pb-10 pb-lg-9 pt-7 postion-relative z-index-2">
        <div class="row">
          <div class="mx-auto text-center col-md-6 mt-4">
            <h3 class="text-white">Politique de Confdentialité</h3>
           
          </div>
        </div>
        
      </div>
    </div>
 
  
    <div class="container my-6">
      
     
      <div class="mt-8 row">
        <div class="mx-auto text-left col-md-9">
          <h2>Politique de protection des données personnelles</h2>
          <h3 >Contexte</h3>
          <p>LABES construit  avec ses utilisateurs des relations fortes et durables, fondées sur la  confiance réciproque : assurer la sécurité et la confidentialité des données  personnelles de ses utilisateurs est une priorité absolue pour LABES.<br>
      LABES respecte  l'ensemble des dispositions réglementaires et législatives Tunisiennes  relatives à la protection des données personnelles et se conforme également aux  règles déontologiques édictées par les instances ordinales représentatives des  professions médicales présentes sur son service.<br>
      Elle applique une  politique extrêmement stricte pour garantir la protection des données  personnelles de santé de ses utilisateurs :</p>
    <ul>
      <li>Chaque utilisateur de l'application web/mobile reste maître de ses données.  LABES n'en dispose pas librement.</li>
      <li>Elles sont traitées de manière transparente, confidentielle et sécurisée</li>
      <li>LABES est engagé dans une démarche continue de protection des données de  ses utilisateurs, en conformité avec la Loi organique n° 2004-63 du 27 juillet  2004, portant sur la protection des données à caractère personnel et  mentionnées dans les présentes Conditions Générales d'Utilisation et du  Règlement (UE) général sur la protection des données du 27 avril 2016 (ci-après  « RGPD »).</li>
      <li>LABES dispose d'une équipe dédiée à la protection des données  personnelles, composée d'un DPO (Data Protection Officer) et d'un RSI.</li>
    </ul>
    <h3>Objet de  la présente politique</h3>
<p>LABES souhaite  vous informer par l'intermédiaire de la présente politique de la manière dont  nous protégeons vos données à caractère personnel traitées via le site <a href="https://www.med.tn">https://www.med.tn</a> ou via l'application  « MED » disponible sur mobile.<br>
  La présente politique  décrit la manière dont LABES traite les données à caractère personnel des  utilisateurs (ci-après le/les « Utilisateur(s) ») lors de leur navigation sur  le site www.med.tn (ci-après le « Site ») et de leur utilisation des services  de LABES ou de son application mobile (ci-après l'Application).<br>
  Certaines Données  Personnelles de l'Utilisateur doivent être considérées comme des données à  caractère personnelles de santé, et sont ci-après dénommées « Données  Personnelles de Santé ».<br>
  Cette Politique peut  être modifiée, complétée ou mise à jour afin notamment de se conformer à toute  évolution légale, réglementaire, jurisprudentielle et technique. Cependant, les  Données Personnelles de l'Utilisateur seraient toujours traitées conformément à  la politique en vigueur au moment de leur collecte, sauf si une prescription  légale impérative venait à en disposer autrement et serait d'application  rétroactive.<br>
Cette politique fait  partie intégrante des Conditions Générales d'Utilisation du Site.</p>
<h3>Identité  et coordonnées des responsables de traitement</h3>
<p><em>Rappel légal : Le  responsable du traitement est, au sens de la loi, la personne qui détermine les  moyens et les finalités du traitement. Le sous-traitant est une personne  traitant des données à caractère personnel pour le compte du responsable du  traitement. Il agit sous l'autorité du responsable du traitement et sur  instruction de celui-ci.</em> <br>
LABES est  considéré comme le responsable de traitement, elle prend les mesures propres à  assurer la protection et la confidentialité des informations nominatives  qu'elle détient ou qu'elle traite dans le respect des dispositions de la loi et  du RGPD. Pour plus d'information concernant les services proposés par LABES  vous pouvez vous référer aux <a href="contenu/conditions-generales-dutilisation-2.html">Conditions générales d'utilisation</a> du Site.</p>
<h3>Collecte  et origine des données</h3>
<p>Toutes les données  concernant les Utilisateurs sont collectées directement auprès de ces derniers,  par LABES.<br>
  LABES s'engage à  recueillir le consentement de ses Utilisateurs et/ou à leur permettre de  s'opposer à l'utilisation de leurs données pour certaines finalités, dès que  cela est nécessaire.<br>
Lors de leur  navigation sur le site, les Utilisateurs sont informés des finalités pour  lesquelles leurs données sont collectées via les différents formulaires de  collecte de données en ligne et via la Charte de gestion des cookies.</p>
<h3>Finalité  des données collectées</h3>
<h3>1.&emsp;Nécessité de la collecte.</h3>
<p>Lors de la prise de  rendez-vous en ligne avec un professionnel de santé, l'Utilisateur communique  certaines Données Personnelles. Si l'Utilisateur ne souhaite pas communiquer  les informations qui lui sont demandées, il se peut que l'Utilisateur ne puisse  pas accéder à certaines parties du Site ou de l'Application, et que LABES  soit dans l'impossibilité de répondre à sa demande.</p>
<h3>2.&emsp;Finalités</h3>
<p>Le recueil de vos  Données Personnelles a pour base légale :</p>
<ul >
  <li>L'intérêt légitime de LABES à assurer la meilleure qualité de ses services, à fournir à ses Utilisateurs le meilleur suivi possible de leurs rendez-vous       et de leurs parcours de soins, et à améliorer le fonctionnement de son Site et de son Application ;</li>
  <li>L'intérêt légitime de LABES à produire des données statistiques relatives à l'impact de LABES sur l'activité des Professionnels de santé afin de       communiquer sur son outil et améliorer ses services ;</li>
  <li>L'intérêt légitime de LABES à réaliser des enquêtes de satisfaction facultatives sur ses services en vue de les améliorer ;</li>
  <li>Le consentement de ses Utilisateurs lorsque celui-ci est requis par la réglementation en vigueur, notamment en matière de prospection commerciale et de cookies.</li>
</ul>
<p>Les données des  Utilisateurs de LABES sont principalement traitées pour :</p>
<ul >
  <li>Permettre leur navigation sur le Site et permettre leur utilisation de l'Application</li>
  <li>Les mettre en relation avec un Professionnel de Santé</li>
  <li>Leur permettre de visualiser et gérer leurs parcours de soin, tant pour les rendez-vous pris directement sur le Site, que les rendez-vous renseignés       par leurs Professionnels de Santé directement.</li>
</ul>
<p>A titre subsidiaire  les données des Utilisateurs sont également collectées pour :</p>
<ul >
  <li>Prévenir et lutter contre la fraude informatique (spamming, hacking…)</li>
  <li>Améliorer la navigation sur le Site et l'utilisation de l'Application</li>
  <li>Mener des enquêtes de satisfaction facultatives sur les services de LABES (ces enquêtes sont réalisées de manière anonyme ou sont anonymisées à bref       délai)</li>
  <li>Effectuer des statistiques sur l'utilisation de l'outil, un reporting interne pour les équipes de LABES </li>
</ul>
<p>Vous retrouverez plus  de détails sur la gestion des cookies nous permettant d'atteindre cette  finalité dans notre Charte de gestion des cookies.<br>
Le caractère  obligatoire ou facultatif des données personnelles demandées et les éventuelles  conséquences d'un défaut de réponse à l'égard des Utilisateurs de LABES sont  précisés lors de leur(s) collecte(s).</p>
<h3>Types de  données traitées</h3>
<p>LABES est  susceptible de traiter, en tant que Responsable de Traitement, pour permettre  la prise de rendez-vous, la gestion des rendez-vous et du parcours de soins,  tout ou partie des données suivantes :</p>

 
<h3>Non-communication  des données personnelles </h3>
<p>Les Données  Personnelles de l'Utilisateur ne seront pas transmises à des acteurs  commerciaux ou publicitaires.<br>
LABES peut faire recourt  également aux prestations fournies par plusieurs sociétés spécialisées  (mailing, analyse d'audience) dont la liste peut être communiquée aux personnes  concernées sur demande adressée à <a href="mailto:privacy@labes.com.tn">privacy@labes.com.tn</a>. </p>
<ul>
  <li>Aucune Donnée Personnelle de Santé ne leur est communiquée. Si ces sociétés  utilisent des serveurs en dehors de la Tunisie, nous avons conclu avec elles  des contrats spécifiques et des clauses contractuelles types pour encadrer et  sécuriser le transfert de vos données à ces prestataires.</li>
</ul>
<h3>Durée de  conservation des données</h3>
<p>Nous conservons vos  données uniquement pour 2 ans en cas d'inactivité ou sous la demande de  l'utilisateur pour les finalités poursuivies, conformément aux prescriptions  légales.</p>
<h3>Les droits des utilisateurs</h3>
<p>Chaque fois que LABES  traite des Données Personnelles, elle prend toutes les mesures raisonnables  pour s'assurer de l'exactitude et de la pertinence des Données Personnelles au  regard des finalités pour lesquelles LABES les traite.<br>
  Conformément à la  réglementation européenne en vigueur, les Utilisateurs de LABES disposent  des droits suivants :</p>

<p>Dès que LABES a  connaissance du décès d'un Utilisateur et à défaut d'instructions de sa part, LABES  s'engage à détruire ses données, sauf si leur conservation s'avère nécessaire à  des fins probatoires ou pour répondre à une obligation légale (telle que la  conservation du dossier patient).<br>
  Si l'Utilisateur  souhaite savoir comment LABES utilise ses Données Personnelles, demander à  les rectifier ou s'oppose à leur traitement, l'Utilisateur peut contacter LABES  par écrit à l'adresse suivante : LABES – DPO, 11 Rue Ibn Aljazzar, Tunis  1002 ou par mail à privacy@labes.com.tn. <br>
Dans ce cas,  l'Utilisateur doit indiquer les Données Personnelles qu'il souhaiterait que LABES  corrige, mette à jour ou supprime, en s'identifiant de manière précise avec une  copie d'une pièce d'identité (carte d'identité ou passeport). Les demandes de  suppression de Données Personnelles seront soumises aux obligations qui sont  imposées à LABES par la loi, notamment en matière de conservation ou  d'archivage des documents. Enfin, les Utilisateurs de LABES peuvent déposer  une réclamation auprès des autorités de contrôle, et notamment de l'INPDP.</p>
<h3>Cookies et balises « TAGS »  Internet</h3>
<p>Veuillez-vous référer  à notre <strong>« Politique d'utilisation des cookies ».</strong></p>
<h3>Sécurité</h3>
<p>LABES met en œuvre  toutes les mesures techniques et organisationnelles afin d'assurer la sécurité  des traitements de données à caractère personnel et la confidentialité de  Données Personnelles.<br>
A ce titre, elle  prend toutes les précautions utiles, au regard de la nature des données et des  risques présentés par le traitement, afin de préserver la sécurité des données  et, notamment, d'empêcher qu'elles soient déformées, endommagées, ou que des  tiers non autorisés y aient accès (protection physique des locaux, procédés  d'authentification avec accès personnel et sécurisé via des identifiants et  mots de passe confidentiels, journalisation des connexions, chiffrement de  certaines données…).</p>
<h3>Informations  personnelles et mineurs</h3>
<p>En principe, le Site  et l'Application s'adressent à des personnes majeures capables de contracter  des obligations conformément à la législation en vigueur.<br>
L'Utilisateur mineur  de moins de 16 ans ou incapable doit obtenir le consentement préalable de son responsable  légal préalablement à la saisie de ses données sur le Site et l'Application.  Sauf obligation légale de confidentialité ou liée au secret médical, LABES  pourra directement informer ce responsable (i) des catégories spécifiques de  Données Personnelles recueillies auprès de la personne mineure (ii) de la  possibilité de s'opposer à la collecte, l'utilisation ou la conservation de  celles-ci.</p>
<h3>Nous  contacter – coordonnées du DPO</h3>
<p>Si l'Utilisateur a  des questions ou des réclamations concernant le respect par LABES de la  présente Politique, ou si l'Utilisateur souhaite faire part à LABES de  recommandations ou des commentaires visant à améliorer la qualité de la  présente politique, l'Utilisateur peut contacter LABES par écrit à l'adresse  suivante : LABES – DPO, 11 Rue Ibn Aljazzar, Tunis 1002 ou <a href="mailto:privacy@labes.com.tn">privacy@labes.com.tn</a>.</p>   </div>
   


        


 
      </div>
     
    </div>
    <app-footer />
  </template>
  
  <script>
  import Navbar from "@/examples/PageLayout/Navbar.vue";
  
  import AppFooter from "@/examples/PageLayout/Footer.vue";
 
  import setNavPills from "@/assets/js/nav-pills.js";
  
  export default {
    name: "Confidential Page",
    components: {
      Navbar,
     
      AppFooter,
      
    },
    data() {
      return {};
    },
    mounted() {
      this.$store.state.showSidenav = false;
      this.$store.state.showNavbar = false;
      this.$store.state.showFooter = false;
      setNavPills();
    },
    beforeUnmount() {
      this.$store.state.showSidenav = true;
      this.$store.state.showNavbar = true;
      this.$store.state.showFooter = true;
      if (this.$store.state.isPinned === false) {
        const sidenav_show = document.querySelector(".g-sidenav-show");
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        this.$store.state.isPinned = true;
      }
    },
  };
  </script>
  