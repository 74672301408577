<template>
  <div class="card bg-white">
    <div class="card-body">
      <h6 class="mb-0 text-lg text-gray">Wallet</h6>
      <div class="mb-2">
        <sup class="text-gray d-none">DT</sup>
        <span class="h2 text-gray">750 / DT</span>
        <div class="text-gray opacity-8 mt-2 text-sm">Votre solde</div>
        <div>
          <span class="text-danger font-weight-600">- 30% </span>
          <span class="text-gray opacity-8">250 DT</span>
        </div>
      </div>
      <button class="btn btn-sm btn-dark mb-0 w-100">
        Alimenter le compte
      </button>
    </div>
    <div class="card-footer pt-0">
      <div class="row">
        <div class="col">
          <small class="text-gray opacity-8">Dépot: 60%</small>
          <div class="progress progress-xs my-2">
            <div class="progress-bar bg-success" style="width: 60%"></div>
          </div>
        </div>
        <div class="col">
          <small class="text-gray opacity-8">Dépenses: 40%</small>
          <div class="progress progress-xs my-2">
            <div class="progress-bar bg-warning" style="width: 40%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditCard",
};
</script>
