<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div>
        <!--<argon-button color="white" variant="outline">New order</argon-button>-->
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="card mt-4">
          <div class="table-responsive">
            <table id="order-list" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th class="text-xxs">Id</th>

                  <th class="d-none">Service</th>
                  <th class="text-xxs">Date</th>
                  <th class="text-xxs">Status</th>
                  <th class="text-xxs">Montant</th>
                  <th class="text-xxs">Commission</th>
                  <th class="text-xxs">Payment</th>
                  <th class="text-xxs">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="booking of bookinglist" :key="booking.id">
                  <p class="d-none">{{ setBookingStatus(booking) }}</p>
                  <td>
                    <div class="d-flex align-items-center">
                      <argon-checkbox class="d-none" />
                      <p class="text-xxs ms-2 mb-0">#L-{{ booking.id }}</p>
                    </div>
                  </td>

                  <td class="text-xxs d-none">
                    <span class="my-2 text-xs">{{
                      booking.service_title
                    }}</span>
                  </td>
                  <td class="text-center">
                    <p class="my-2 text-xxs">
                      {{ booking.service_date }}
                    </p>
                    <p class="my-2 text-xxs d-none">
                      {{ booking.from_time }} - {{ booking.to_time }}
                    </p>
                  </td>

                  <td class="text-xxs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <argon-button
                        :color="
                          booking.paid === '1' ||
                          (booking.cod == '1' && booking.status == 8)
                            ? 'success'
                            : booking.paid === '0'
                            ? 'info'
                            : 'danger'
                        "
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i
                          class="fas"
                          :class="
                            booking.paid === '1' ||
                            (booking.cod == '1' && booking.status == 8)
                              ? 'fa-check'
                              : booking.paid === '0'
                              ? 'fa-undo'
                              : booking.paid === '2'
                              ? 'fa-exclamation-triangle'
                              : 'fa-times'
                          "
                          aria-hidden="true"
                        ></i>
                      </argon-button>
                      <span class="text-xxs" v-if="booking.cod == '1'"
                        >COD</span
                      >
                      <span class="text-xxs" v-if="booking.cod == '0'">CB</span>
                    </div>
                  </td>

                  <td class="text-xxs">
                    <span class="my-2 text-xxs">
                      {{ (booking.service_amount / 1000).toFixed(3) }} /
                      {{ booking.currency }}
                    </span>
                  </td>
                  <td class="text-center">
                    <p class="my-2 text-xxs">5000</p>
                  </td>
                  <td class="text-center">
                    <span class="my-2 text-xxs">
                      {{ ((booking.service_amount - 5000) / 1000).toFixed(3) }}
                      /
                      {{ booking.currency }}
                    </span>
                  </td>
                  <td class="text-xxs">
                    <router-link
                      :to="{
                        name: 'Booking Details',
                        params: { id: booking.id },
                      }"
                    >
                      <argon-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-eye" aria-hidden="true"></i>
                      </argon-button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="mt-4 mt-sm-0">
          <div class="card bg-white mt-4">
            <div class="card-body">
              <h6 class="mb-0 text-lg text-gray">Wallet</h6>
              <div class="mb-2">
                <sup class="text-gray d-none">DT</sup>
                <span class="h2 text-gray">275,000 DT</span>
                <div class="row">
                  <div class="col">
                    <small class="text-gray opacity-8">CB: 320,000 DT</small>
                    <div class="progress progress-xs my-2">
                      <div
                        class="progress-bar bg-success"
                        style="width: 50%"
                      ></div>
                    </div>
                  </div>
                  <div class="col">
                    <small class="text-gray opacity-8">CASH: 420,000 DT</small>
                    <div class="progress progress-xs my-2">
                      <div
                        class="progress-bar bg-warning"
                        style="width: 50%"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="text-gray opacity-8 mt-2 text-sm">Total Revenu</div>
                <div>
                  <span class="text-gray opacity-8">740,000 DT</span>
                </div>
                <div class="text-gray opacity-8 mt-2 text-sm">Commission</div>
                <div>
                  <span class="text-danger font-weight-600">- 45,000 DT </span>
                </div>
              </div>
            </div>
            <div class="card-footer pt-0">
              <div class="row">
                <button class="btn btn-sm btn-dark mb-0 w-100">Wallet</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";

export default {
  name: "Payments Provider List",
  components: {
    ArgonButton,
    ArgonCheckbox,
  },
  data() {
    return {
      bookingStatusCode: "",
      bookingStatusColor: "",
      bookingStatusTitle: "",
      bookingDetails: [],
      imgUrl: "https://back.labes.com.tn/",
      uRole: "",
    };
  },
  mounted() {
    if (document.getElementById("order-list")) {
      const dataTableSearch = new DataTable("#order-list", {
        searchable: true,
        fixedHeight: false,
        perPageSelect: false,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function (el) {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
  },
  async created() {
    await this.$store.dispatch("loadBookingList");
    this.uRole = this.$store.state.auth.user.type;
  },
  computed: {
    bookinglist() {
      return this.$store.state.bookingList.booking_list.filter(
        (b) => b.status == 8
      );
    },
  },
  methods: {
    async setBookingStatus(booking) {
      this.bookingStatusCode = booking.status;

      if (this.bookingStatusCode == 1) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Pending";
      } else if (this.bookingStatusCode == 2) {
        this.bookingStatusColor = "badge-info";
        this.bookingStatusTitle = "In progress";
      } else if (this.bookingStatusCode == 3) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 4) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 5) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Rejected by Pro";
      } else if (this.bookingStatusCode == 6) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "service Complete - Payment Pending";
      } else if (this.bookingStatusCode == 7) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Cancelled by Patient";
      } else if (this.bookingStatusCode == 8) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete";
      }
    },
  },
};
</script>
