import axios from "axios";
import router from "../router";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    api_token: "8338d6ff4f0878b222f312494c1621a9",
    user: [],
    subscriptionDetails: [],
    role: "",
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    subscriptionDetails(state) {
      return state.subscriptionDetails;
    },
    api_token(state) {
      return state.api_token;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_TOKEN(state, value) {
      state.api_token = value;
    },
    SET_SUBSCRIPTION_DETAILS(state, value) {
      state.subscriptionDetails = value;
    },
    SET_ROLE(state, role) {
      state.role = role;
    },
  },
  actions: {
    async login({ commit }, userId) {
      return await axios
        .get("/user/" + userId)
        .then(({ data }) => {
          commit("SET_USER", data.user);
          console.log(data.user);
          commit("SET_AUTHENTICATED", true);
          router.push({ name: "Welcome" });
        })
        .catch((data) => {
          console.log(data);
          localStorage.removeItem("token", null);
          commit("SET_USER", {});
          commit("SET_AUTHENTICATED", false);
          router.push({ name: "signin cover" });
        });
    },
    async afterLogin({ commit }) {
      //axios.defaults.headers["token"] = localStorage.getItem("api_token");
      await commit("SET_TOKEN", localStorage.getItem("api_token"));
      //axios.defaults.headers["token"] = this.api_token;
      await commit("SET_USER", JSON.parse(localStorage.getItem("user")));
      await commit(
        "SET_SUBSCRIPTION_DETAILS",
        JSON.parse(localStorage.getItem("subscription_details"))
      );
      await commit("SET_AUTHENTICATED", true);
    },
    logout({ commit }) {
      //axios.defaults.headers["token"] = "8338d6ff4f0878b222f312494c1621a9";
      commit("SET_TOKEN", "8338d6ff4f0878b222f312494c1621a9");
      commit("SET_USER", {});
      commit("SET_SUBSCRIPTION_DETAILS", {});
      commit("SET_AUTHENTICATED", false);
      localStorage.removeItem("user");
      localStorage.removeItem("subscription_details");
      localStorage.removeItem("api_token");
    },
  },
};
