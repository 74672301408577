<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar btn-background="bg-gradient-success" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div class="page-header align-items-start min-vh-50 pt-7 pb-9">
      <span class="mask bg-gradient-info opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Bienvenue !</h1>
            <p class="text-lead text-white">
              Nous sommes contents ontent de vous revoir :)
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card mt-5">
            <div class="card-header pb-0 text-start" v-if="!otpValidation">
              <h3 class="font-weight-bolder">S'dentifier</h3>
              <p class="mb-0">Entrez vos paramètres de connexion</p>
            </div>

            <div class="card-body">
              <form role="form" class="text-start">
                <div
                  v-if="showErrMess"
                  class="alert alert-danger text-center"
                  v-show="isErrorLogin"
                  role="alert"
                >
                  <span class="alert-text text-xs text-white"
                    ><strong>Login Failed ! </strong> Incorrect email or
                    password
                  </span>
                </div>

                <br />
                <label v-if="!otpValidation">Phone 
                
                  <span v-if="loadingPhone" class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                      
                  
                </label>

                <input
                  class="form-control"
                  v-model="mobileno"
                  v-if="!otpValidation"
                  type="tel"
                  placeholder="22222222"
                  id="tel"
                  required
                />
                <p class="text-xs text-danger" v-if="showErrPhone">
                  Mobile number not registered or Desactivated...
                </p>
                <p
                  class="text-xs text-success"
                  v-if="isValidPhoneData && !otpValidation"
                >
                  Mobile number is valid...
                </p>

                <div
                  class="alert alert-warning text-white text-xs text-center d-none"
                  v-if="otpValidation"
                  role="alert"
                >
                  We have used default otp for demo purpose.<br />
                  <strong>Default OTP 1234</strong>
                </div>
                <div
                  class="alert alert-warning text-white text-xs text-center "
                  v-if="otpValidation"
                  role="alert"
                >
                Validation en deux étapes<br />
                  <strong>Veuillez entrer le code OTP reçu par email</strong>
                </div>
                <label v-if="otpValidation">OTP</label>

                <input
                  class="form-control"
                  v-if="otpValidation"
                  v-model="otp"
                  type="tel"
                  placeholder="OTP"
                  id="otp"
                  required
                />
                <p
                  class="text-xs text-danger"
                  v-if="isErrorLogin && otpValidation"
                >
                  Invalid OTP...
                </p>

                <div class="text-center">
                  <button
                    v-if="!otpValidation"
                    class="mt-4 mb-0 btn bg-gradient-info btn-sm me-2 w-100"
                    type="button"
                    name="button"
                    @click="otpRequest"
                    :disabled="!isValidPhoneData"
                  >

                      <span v-if="loading">Envoi...</span>
                      <span v-if="loading" class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                          
                      <span v-else>Envoyer</span>
                    
                  </button>
                  <button
                    v-if="otpValidation"
                    class="mt-4 mb-0 btn bg-gradient-info btn-sm me-2 w-100"
                    type="button"
                    name="button"
                    @click="loginOtp"
                    :disabled="loadingOTP"
                  >
                    
                    <span v-if="loadingOTP">Vérification de l'OTP...</span>
                      <span v-if="loadingOTP" class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                          
                      <span v-else>Vérifier</span>
                  </button>
                  
                </div>
              </form>
            </div>
            <div
              class="card-footer text-center pt-0 px-lg-2 px-1"
              v-if="!otpValidation"
            >
              <p class="mb-4 text-sm mx-auto">
                Vous n'avez pas de compte?
                <router-link
                  :to="{ name: 'Signup Cover' }"
                  class="text-info font-weight-bold"
                  >S'inscrire</router-link
                >
              </p>
            </div>
            <div
              class="card-footer text-center pt-0 px-lg-2 px-1"
              v-if="otpValidation"
            >
              <p class="mb-4 text-sm mx-auto">
                Ce n'est pas votre numéro?
                <router-link
                  :to="{ name: 'Signin Cover' }"
                  class="text-info font-weight-bold"
                  >Revenir</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
const body = document.getElementsByTagName("body")[0];
import router from "../../../router";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";
import axios from "axios";
const token = '8338d6ff4f0878b222f312494c1621a9';

export default {
  name: "SigninCover",
  data() {
    return {
      loading:false,
      loadingPhone:false,
      loadingOTP:false,
      otp: "",
      api_signin: "",
      api_otp: "",
      showErrPhone: false,
      isValidPhoneData: false,
      showErrMess: false,
      otpValidation: false,
      userType: "",
      mobileno: "",
      loginOtpData: {
        mobileno: "",
        country_code: "216",
        otp: "",
      },
      otpData: {
        mobileno: "",
        countryCode: "216",
      },
      formData: {
        countryCode: "216",
        userMobile: "",
        mode: "2",
      },
      auth: {
        email: "",
        password: "",
        remember: false,
      },
      isErrorLogin: false,
      errMessage: "",
    };
  },
  components: {
    Navbar,
    AppFooter,
  },
  watch: {
    mobileno() {
      if (this.mobileno.length < 15 && this.mobileno.length > 7) {
        this.verifyMobileno();
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add("bg-gray-100");
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    ...mapActions({
      signIn: "auth/afterLogin",
    }),

    async verifyMobileno() {
      this.loadingPhone = true;
      this.formData.userMobile = this.mobileno;
      await axios({
        url: "/check_mobile/",
        method: "post",
        data: this.formData,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.data.data == 1) {
            this.showErrPhone = false;
            this.isValidPhoneData = true;
            this.userType = res.data.data.mode;
          } else {
            //alert(res.data.response.response_message)
            this.showErrPhone = true;
            this.isValidPhoneData = false;
          }
        })
        .catch((error) => console.error(error)).finally(() => {
          this.loadingPhone = false;
        });
    },

    async loginOtp() {
      this.loadingOTP = true;
      this.otpValidation = true;
      this.loginOtpData.mobileno = this.mobileno;
      this.loginOtpData.otp = this.otp;
      if (this.userType == 1) {
        this.api_signin = "/provider_signin";
      } else {
        this.api_signin = "/user_signin";
      }

      await axios({
        url: this.api_signin,
        method: "post",
        data: this.loginOtpData,
        headers: {
        'Token':  token,
         },

      })
        .then((res) => {
          console.log(res.data.data.provider_details);

          if (res.data.response.response_code == "200") {
            if (this.userType == 1) {
              localStorage.setItem(
                "user",
                JSON.stringify(res.data.data.provider_details)
              );
              localStorage.setItem(
                "subscription_details",
                JSON.stringify(
                  res.data.data.provider_details.subscription_details
                )
              );

              localStorage.setItem(
                "api_token",
                res.data.data.provider_details.token
              );
            } else {
              localStorage.setItem(
                "user",
                JSON.stringify(res.data.data.user_details)
              );
              localStorage.setItem(
                "subscription_details",
                JSON.stringify(res.data.data.user_details.subscription_details)
              );

              localStorage.setItem(
                "api_token",
                res.data.data.user_details.token
              );
            }

            //set token for axios call
            this.signIn();
            router.push({ name: "Welcome" });
          } else {
            console.log(res.data.response.response_message);
            this.isErrorLogin = true;
            this.loadingOTP = false;
          }
        })
        .catch((error) => console.error(error));
    },

    async otpRequest() {
      this.loading=true;
      this.otpValidation = true;
      this.otpData.mobileno = this.mobileno;
      if (this.userType == 1) {
        this.api_otp = "/send_otp_request_pro/";
      } else {
        this.api_otp = "/send_otp_request_user/";
      }

      await axios({
        url: this.api_otp,
        method: "post",
        data: this.otpData,
      })
        .then((res) => {
          console.log(res.data.data);
        })
        .catch((error) => console.error(error)).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>