<template>
  <navbar />
  <div
    class="page-header position-relative"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/img/pricing-header-bg.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <span class="mask bg-gradient-success opacity-6"></span>
    <div class="container pb-10 pb-lg-9 pt-7 postion-relative z-index-2">
      <div class="row">
        <div class="mx-auto text-center col-md-6 mt-4">
          <h3 class="text-white">À propos de nous</h3>
          
        </div>
      </div>
    
    </div>
  </div>
 
  <div class="container my-6">
   
   
    <div class="mt-8 row">
      <div class="mx-auto text-left col-md-9">
        <h2>Labes.com.tn</h2>
         <h3>Pour les patients</h3>
        <p>
          Un doute, une inquiétude, besoin d’un conseil santé ? Labes.com.tn c’est la solution

          Labes.com.tn est une plateforme innovante qui vous permet de trouver rapidement un médecin le plus proche de chez vous et de prendre rendez-vous en ligne gratuitement.

          Labes.com.tn vous permet de poser vos questions en ligne directement aux médecins en respectant les termes de confidentialité.

          Labes.com.tn est le Moteur de recherche pour trouver une pharmacie à proximité.

          Labes.com.tn c’est la base de données de la majorité des médicaments avec prix et indications.

          Labes.com.tn vous donne l’information sur les symptômes de maladie les plus courants.
        </p>
           <h3>Pour les professionnels de santé</h3>
        <p>
          Labes.com.tn est un service complet de gestion de cabinet médical, qui optimise votre organisation et vous fait gagner du temps. Avec Labes.com.tn, vous partagez vos disponibilités en temps réel avec vos patients selon vos critères, tout en gardant la main sur votre agenda médical.

          Labes.com.tn vous permet d’entrer en contact directement avec des patients et de répondre à leurs interrogations.

          Notre objectif final est d'améliorer l'accès aux soins en mettant à votre disposition des outils simples et intuitifs pour que chacun devienne acteur de sa santé et de son bien-être.
                 
        </p>
      </div>
    </div>
    <div class="mx-auto text-center col-md-6">
 
      <p> Le site www.labes.com.tn est édité par la Société Labes SA. </p>
      <p> Adresse: 11 Rue Ibn Aljazzar, Tunis 1002 </p>
      <p> Email: contact@labes.com.tn</p>
      <p> Juridique: juridique@labes.com.tn </p>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "About Page",
  components: {
    Navbar,
    AppFooter,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
  },
};
</script>
