<template>
  <div class="card bg-gradient-warning h-100">
    <div class="card-body">
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <h6 class="mb-0 text-lg text-white">Abonnement</h6>
        </div>
        <div class="col-auto">
          <span class="badge badge-lg badge-success">Active</span>
        </div>
      </div>
      <div class="my-4">
        <p class="text-white opacity-8 mb-0 text-sm">Date d'expiration</p>
        <div
          v-if="subscriptionDetails.subscription_name != null"
          class="h7 text-white cursor-pointer"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Copy Address"
        >
          {{ subscriptionDetails.expiry_date_time }}
        </div>
        <div
          v-else
          class="h7 text-white cursor-pointer"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Copy Address"
        >
          -
        </div>
      </div>
      <div class="row mt-5">
        <div v-if="subscriptionDetails.subscription_name != null" class="col">
          <p class="text-white opacity-8 mb-0 text-sm">Plan</p>
          <span class="d-block h6 text-white">{{
            subscriptionDetails.subscription_name
          }}</span>
        </div>
        <div v-else class="col">
          <p class="text-white opacity-8 mb-0 text-sm">Plan</p>
          <span class="d-block h6 text-white"> Free </span>
        </div>
        <div class="col ms-auto text-end">
          <div class="btn-groups mt-3">


              <button class="btn btn-sm btn-dark mb-0 w-100">
               
                Subscriptions
             
              </button>
                                    
           

           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

 
export default {
  name: "SubscriptionCard",

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    subscriptionDetails() {
      return this.$store.state.auth.subscriptionDetails;
    },
  },
};
</script>
