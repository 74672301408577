<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="card my-5">
          <div class="card-header p-3 pb-0">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div>
                <h6>Booking Details</h6>
                <p class="text-sm mb-0">
                  Labes no.
                  <b>L-{{ bookingDetail.booking_details.booking_id }}</b> from
                  <b>{{ bookingDetail.booking_details.request_date }}</b>
                </p>

                <argon-badge
                  :color="bookingStatusColor"
                  variant="gradient"
                  size="sm"
                >
                  Status - {{ bookingStatus }} -
                  {{ bookingStatusCode }}
                </argon-badge>
              </div>

              <argon-badge color="info" variant="gradient" size="sm"
                >{{ chatToken }}
              </argon-badge>
            </div>
          </div>
          <div class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <h6 class="text-lg mb-0 mt-2">
                        {{ bookingDetail.service_details.service_title }}
                      </h6>

                      <span class="mb-2 text-xs">
                        Category:
                        <span class="text-dark font-weight-bold ms-2"
                          >{{ bookingDetail.service_details.category_name }} ({{
                            bookingDetail.service_details.subcategory_name
                          }})</span
                        >
                      </span>

                      <span class="mb-2 text-xs">
                        Date:
                        <span class="text-dark font-weight-bold ms-2">
                          {{ bookingDetail.booking_details.service_date }} |
                          {{ bookingDetail.booking_details.from_time }} -
                          {{ bookingDetail.booking_details.to_time }}
                        </span>
                      </span>
                      <span class="mb-2 text-xs">
                        Location:
                        <span class="text-dark font-weight-bold ms-2">{{
                          bookingDetail.personal_details.location
                        }}</span>
                      </span>
                      <span class="mb-2 text-xs">
                        Notes:
                        <span class="text-dark font-weight-bold ms-2">{{
                          bookingDetail.booking_details.notes
                        }}</span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                v-if="
                  bookingDetail.booking_details.admin_comments != '' ||
                  bookingDetail.booking_details.user_rejected_reason != ''
                "
                class="col-lg-12 col-md-12 col-12"
              >
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <span class="mb-2 text-xs font-weight-bold">
                        Additional Informations
                      </span>
                      <span class="mb-2 text-xs">
                        Admin Comment:
                        <span class="text-danger font-weight-bold ms-2">
                          {{ bookingDetail.booking_details.admin_comments }}
                        </span>
                      </span>
                      <span class="mb-2 text-xs">
                        User Rejected Reason:
                        <span class="text-danger font-weight-bold ms-2">
                          {{
                            bookingDetail.booking_details.user_rejected_reason
                          }}
                        </span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-3 col-12 d-none">
                <argon-avatar
                  :img="imgUrl + bookingDetail.service_details.service_image[0]"
                  class="me-3"
                  size="xxl"
                  border-radius="sm"
                  alt="product image"
                />
              </div>
            </div>

            <hr class="horizontal dark mt-4 mb-4" />
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-center">
                        <img
                          :src="
                            imgUrl + bookingDetail.personal_details.profile_img
                          "
                          class="avatar avatar-xs me-2"
                          alt="user image"
                        />
                        <span>
                          <h6 class="mb-2 text-sm">
                            {{ bookingDetail.personal_details.name }}
                          </h6>
                        </span>
                      </div>

                      <span class="mb-2 text-xs">
                        Location:
                        <span
                          v-show="userType == 1"
                          class="text-dark font-weight-bold ms-2"
                          >{{ bookingDetail.personal_details.location }}</span
                        >
                        <span
                          v-show="userType == 2"
                          class="text-dark font-weight-bold ms-2"
                          >{{
                            bookingDetail.service_details.service_location
                          }}</span
                        >
                      </span>

                      <span class="mb-2 text-xs">
                        Mobile:
                        <span class="text-dark ms-2 font-weight-bold">
                          <b
                            >({{ bookingDetail.personal_details.country_code }})
                          </b>
                          - <b>{{ bookingDetail.personal_details.mobileno }}</b>
                        </span>
                      </span>
                      <span class="mb-2 text-xs">
                        Email Address:
                        <span class="text-dark ms-2 font-weight-bold">{{
                          bookingDetail.personal_details.email
                        }}</span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div
                  class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
                >
                  <div class="">
                    <button
                      v-show="bookingStatusCode == 1 && userType == 1"
                      class="my-2 mx-2 btn bg-gradient-success btn-sm me-2"
                      type="button"
                      name="button"
                      @click="confirmUpdateBookingStatus(1)"
                    >
                      <i class="fa fa-check mx-3"></i>
                      <span class="d-sm-inline">User Request Accept</span>
                    </button>
                    <button
                      v-show="
                        (bookingStatusCode > 1 &&
                          bookingStatusCode < 3 &&
                          userType == 1) ||
                        (bookingStatusCode <= 3 && userType == 2)
                      "
                      class="my-2 mx-2 btn bg-gradient-info btn-sm me-2"
                      type="button"
                      name="button"
                      @click="goToChat"
                    >
                      <i class="fa fa-comment mx-3"></i>
                      <span class="d-sm-inline">chat</span>
                    </button>

                    <button
                      v-show="
                        bookingStatusCode == 2 &&
                        userType == 1 &&
                        ((this.$store.state.bookingDetail.invoice_details
                          .approvalCode != '' &&
                          bookingDetail.booking_details.cod == 0) ||
                          bookingDetail.booking_details.cod == 1)
                      "
                      class="my-2 mx-2 btn bg-gradient-warning btn-sm me-2"
                      type="button"
                      name="button"
                      @click="confirmUpdateBookingStatus(3)"
                    >
                      <i class="fa fa-plus mx-3"></i>
                      <span class="d-sm-inline">Complete Request to user</span>
                    </button>
                    <button
                      v-show="bookingStatusCode == 3 && userType == 2"
                      class="my-2 mx-2 btn bg-gradient-warning btn-sm me-2"
                      type="button"
                      name="button"
                      @click="confirmUpdateBookingStatus(4)"
                    >
                      <i class="fa fa-plus mx-3"></i>
                      <span class="d-sm-inline">Complete Request Accept</span>
                    </button>
                    <button
                      v-show="bookingStatusCode == 6 && userType == 1"
                      class="my-2 mx-2 btn bg-gradient-primary btn-sm me-2"
                      type="button"
                      name="button"
                      @click="confirmUpdateBookingStatus(8)"
                    >
                      <i class="fa fa-money-bill mx-3"></i>
                      <span class="d-sm-inline">Payment Paid</span>
                    </button>

                    <button
                      v-show="bookingStatusCode < 3 && userType == 1"
                      class="my-2 mx-2 btn bg-gradient-danger btn-sm me-2"
                      type="button"
                      name="button"
                      @click="confirmUpdateBookingStatus(5)"
                    >
                      <i class="fa fa-times mx-3"></i>
                      <span class="d-sm-inline">Cancel Request Pro</span>
                    </button>

                    <button
                      v-show="bookingStatusCode <= 3 && userType == 2"
                      class="my-2 mx-2 btn bg-gradient-danger btn-sm me-2"
                      type="button"
                      name="button"
                      @click="confirmUpdateBookingStatus(cancelUserValue)"
                    >
                      <i class="fa fa-times mx-3"></i>
                      <span class="d-sm-inline">Cancel Request User</span>
                    </button>

                    <div v-if="bookingStatusCode == 8" class="mb-2">
                      <label for="" class="form-label">Avis </label>
                      <star-rating
                        :readOnly="this.userType == 1"
                        class="mx-2"
                        :star-size="20"
                        :show-rating="false"
                        :round-start-rating="false"
                        :rating="rating"
                        @update:rating="rating = $event"
                      ></star-rating>
                    </div>

                    <div v-if="bookingStatusCode == 8" class="mb-2">
                      <label for="comment" class="form-label"
                        >Commentaire</label
                      >

                      <textarea
                        class="form-control mx-2"
                        :disabled="this.userType == 1"
                        v-model="this.formRating.review"
                        id="comment"
                        rows="3"
                      ></textarea>
                    </div>

                    <button
                      v-show="bookingStatusCode == 8 && userType == 2"
                      class="my-2 mx-2 btn bg-gradient-secondary btn-sm me-2"
                      type="button"
                      name="button"
                      @click="showRatingSwal('update-rating')"
                    >
                      <i class="fa fa-star mx-3"></i>
                      <span class="d-sm-inline">Review</span>
                    </button>
                    <button
                      v-show="bookingStatusCode == 5 || bookingStatusCode == 7"
                      class="my-2 mx-2 btn bg-gradient-secondary btn-sm me-2"
                      type="button"
                      name="button"
                    >
                      <i class="fa fa-info-circle mx-3"></i>
                      <span class="d-sm-inline">Reason</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <hr class="horizontal dark mt-4 mb-4" />
            <div class="row">
              <div
                class="col-lg-6 col-12 ms-auto"
                v-show="bookingStatusCode > 1"
              >
                <h6 class="mb-3">Order Summary</h6>

                <div class="d-flex justify-content-between">
                  <span class="mb-2 text-sm">Transport:</span>
                  <span class="text-dark font-weight-bold ms-2">
                    <input
                      class="form-control my-2"
                      :disabled="
                        userType == 2 ||
                        this.$store.state.bookingDetail.invoice_details
                          .invoice_status == 1
                      "
                      placeholder="0.000"
                      @change="updateTotalInvoice"
                      v-model="invoiceForm.transport"
                      type="text"
                    />
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="mb-2 text-sm">Honoraire:</span>
                  <span class="text-dark ms-2 font-weight-bold">
                    <input
                      :disabled="
                        userType == 2 ||
                        this.$store.state.bookingDetail.invoice_details
                          .invoice_status == 1
                      "
                      class="form-control my-2"
                      placeholder="0.000"
                      @change="updateTotalInvoice"
                      v-model="invoiceForm.honoraire"
                      type="text"
                    />
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="text-sm">Materiel:</span>
                  <span class="text-dark ms-2 font-weight-bold">
                    <input
                      class="form-control my-2"
                      :disabled="
                        userType == 2 ||
                        this.$store.state.bookingDetail.invoice_details
                          .invoice_status == 1
                      "
                      placeholder="0.000"
                      @change="updateTotalInvoice"
                      v-model="invoiceForm.materiel"
                      type="text"
                    />
                  </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="text-sm">frais de Service:</span>
                  <span class="text-dark ms-2 font-weight-bold">
                    <input
                      class="form-control my-2"
                      v-model="invoiceForm.frais"
                      type="text"
                      disabled
                    />
                  </span>
                </div>
                <div
                  class="card bg-dark d-flex align-items-center justify-content-between flex-row p-3"
                >
                  <span class="text-white text-lg">Total:</span>
                  <span class="text-white text-lg ms-2 font-weight-bold">
                    {{ (invoiceForm.total / 1000).toFixed(3) }} / DT</span
                  >
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <h6 class="mb-3">Payment details</h6>

                <div
                  class="card card-body border card-plain border-radius-lg d-flex align-items-center justify-content-between flex-row"
                >
                  <b v-if="this.isCOD == 1">COD</b>
                  <b v-if="this.isCOD == 0"> Carte Bancaire </b>

                  <div
                    v-if="bookingStatusCode > 1"
                    class="d-flex justify-content-between"
                  >
                    <span class="text-lg">Total:</span>
                    <span class="text-dark text-lg ms-2 font-weight-bold">
                      {{ (invoiceForm.total / 1000).toFixed(3) }} / DT</span
                    >
                  </div>
                </div>

                <p
                  class="text-sm text-success mt-2"
                  v-show="
                    bookingStatusCode > 1 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_paid == 0 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_status == 1 &&
                    this.isCOD == 0
                  "
                >
                  Accepted
                  <span class="text-danger text-xs">(waiting Payment)</span>
                </p>

                <button
                  v-if="
                    userType == 1 &&
                    bookingStatusCode > 1 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_status == 0
                  "
                  class="my-2 float-center btn btn-dark btn-sm me-2"
                  type="button"
                  name="button"
                  @click="showInvoiceSwal('update-invoice')"
                >
                  <i class="fa fa-send-o mx-3"></i>
                  <span class="d-sm-inline">Send Invoice</span>
                </button>

                <button
                  v-show="
                    bookingStatusCode > 1 &&
                    userType == 2 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_paid == 0 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_status == 1 &&
                    this.isCOD == 0
                  "
                  class="my-2 float-center btn btn-success btn-sm me-2"
                  type="button"
                  name="button"
                  @click="showInvoiceSwal('pay-invoice')"
                >
                  <i class="fa fa-check mx-3"></i>
                  <span class="d-sm-inline">Pay Invoice</span>
                </button>
                <button
                  v-show="
                    userType == 2 &&
                    invoiceForm.total > 5000 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_status == 0
                  "
                  class="my-2 float-center btn btn-success btn-sm me-2"
                  type="button"
                  name="button"
                  @click="showInvoiceSwal('update-invoice')"
                >
                  <i class="fa fa-check mx-3"></i>
                  <span class="d-sm-inline">Accept Invoice</span>
                </button>

                <div
                  v-show="
                    bookingStatusCode > 1 &&
                    this.isCOD == 0 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_paid != 0 &&
                    this.$store.state.bookingDetail.invoice_details
                      .invoice_paid != null
                  "
                  class="col-xl-12 mb-xl-0 mb-4 my-2"
                >
                  <div class="card bg-transparent shadow-xl">
                    <div
                      class="overflow-hidden position-relative border-radius-xl"
                      style="
                        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/card-visa.jpg');
                      "
                    >
                      <span class="mask bg-gradient-dark"></span>
                      <div class="card-body position-relative z-index-1 p-3">
                        <i
                          class="fas fa-wifi text-white p-2"
                          aria-hidden="true"
                        ></i>
                        <h5 class="text-white mt-4 mb-2 pb-2">
                          {{ PanCard }}
                        </h5>
                        <div class="me-4">
                          <p class="text-white text-sm opacity-8 mb-0">
                            Card Holder
                          </p>
                          <h6 class="text-white mb-0">
                            {{
                              this.$store.state.bookingDetail.invoice_details
                                .cardHolder
                            }}
                          </h6>
                        </div>
                        <div class="d-flex">
                          <div class="d-flex">
                            <div class="me-4">
                              <p class="text-white text-sm opacity-8 mb-0">
                                Expires
                              </p>
                              <h6 class="text-white mb-0">
                                {{ this.cardExpiry }}
                              </h6>
                            </div>

                            <div>
                              <p class="text-white text-sm opacity-8 mb-0">
                                Auth Code
                              </p>
                              <h6 class="text-white mb-0">
                                {{
                                  this.$store.state.bookingDetail
                                    .invoice_details.approvalCode
                                }}
                              </h6>
                            </div>
                          </div>
                          <div
                            class="ms-auto w-20 d-flex align-items-end justify-content-end"
                          >
                            <img
                              class="w-60 mt-2 d-none"
                              src="../../../assets/img/logos/mastercard.png"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import starRating from "vue-star-rating";

import axios from "axios";
import router from "../../../router";
import $ from "jquery";

export default {
  name: "BookingDetails",
  components: {
    //ArgonButton,
    ArgonAvatar,
    ArgonBadge,
    starRating,
  },

  data() {
    return {
      rating: "No Rating Selected",
      formRating: {
        rating: 0,
        review: "",
        booking_id: this.$route.params.id,
        service_id: "",
        type: "3",
      },

      currentSelectedRating: "No Current Rating",
      boundRating: 3,
      totalLen: "4",
      totalInvoice: "",
      cancelUserValue: 2,
      bookingStatus: "",
      bookingStatusCode: "0",
      bookingStatusColor: "",
      userType: this.$store.state.auth.user.type,
      bookingDetails: [],
      updateBookingForm: {
        id: this.$route.params.id,
        status: "",
        type: this.$store.state.auth.user.type,
      },
      chatForm: {
        to: "",
        content:
          "Bonjour, Je viens d'accepter votre demande comment je peux vous aider",
      },
      chatFormInvoice: {
        to: "",
        content: "",
      },
      invoiceForm: {
        book_id: "",
        userType: "",
        transport: "",
        honoraire: "",
        materiel: "",
        frais: "",
        total: "",
      },
      ctpForm: {
        username: "moaddeb-api",
        pwd: "vZFw6p4K2",
        smtOrderId: "123546",
        language: "fr",
        amount: "",
        isCOD: "",
        failUrl:
          "https://www.labes.com.tn/booking/details/" +
          this.$route.params.id,
        ReturnUrl:
          "https://www.labes.com.tn//booking/details/" +
          this.$route.params.id,
      },
      chatToken: "",
      update_invoice: "",
      bookingForm: {
        type: this.$store.state.auth.user.type,
        booking_id: this.$route.params.id,
      },
      imgUrl: "https://back.labes.com.tn/",
      paymentReturn: false,
      PanCard: "",
      cardExpiry: "",
    };
  },
  async created() {
    await this.$store.dispatch("loadBooking", this.bookingForm);
    this.isCOD = this.$store.state.bookingDetail.booking_details.cod;
    this.formRating.service_id =
      this.$store.state.bookingDetail.service_details.service_id;
    this.rating = this.$store.state.bookingDetail.booking_details.rating_value;
    this.formRating.review =
      this.$store.state.bookingDetail.booking_details.rating_review;

    //console.log(this.isCOD);

    await this.setBookingStatus();
    await this.setBookingInvoice();
    this.searchUrlParam();
    //this.isCOD = this.$store.bookingDetails.cod;

    if (
      this.isCOD == 0 &&
      this.$store.state.bookingDetail.invoice_details.frais != null
    ) {
      this.setPanCard();
    }
  },
  computed: {
    bookingDetail() {
      return this.$store.state.bookingDetail;
    },
  },
  watch: {},

  methods: {
    setRating: function (rating) {
      this.rating = "You have Selected: " + rating + " stars";
    },
    showCurrentRating: function (rating) {
      this.formRating.rating =
        rating === 0
          ? this.currentSelectedRating
          : "Click to select " + rating + " stars";
    },
    setCurrentSelectedRating: function (rating) {
      this.currentSelectedRating = "You have Selected: " + rating + " stars";
    },

    confirmUpdateBookingStatus(value) {
      this.showSwal("update-status", value);
    },
    async updateBookingStatus(value) {
      this.updateBookingForm.status = value;
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      await axios
        .post("update_booking", this.updateBookingForm)
        .then(({ data }) => {
          console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            // this.showSwal("success-message");
          } else {
            console.log(data.status);
          }
        })
        .catch(({ response }) => {
          this.errMessage = response.data.message;
        })
        .finally(() => {
          //alert("login finish ")
        });
    },
    showInvoiceSwal(type) {
      if (type === "update-invoice") {
        this.$swal({
          title: "Update Invoice ",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: () => {
            axios.defaults.headers["token"] = localStorage.getItem("api_token");

            return axios
              .post("update_invoice", this.invoiceForm)
              .then((response) => {
                if (response.data.response.response_code != 200) {
                  let timerInterval;
                  this.$swal({
                    title: "Invoice Update",
                    icon: "error",
                    html: response.data.response.response_code,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                      // this.$swal.showLoading();
                      const b = this.$swal
                        .getHtmlContainer()
                        .querySelector("b");
                      timerInterval = setInterval(() => {
                        b.textContent = this.$swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                      //window.location.reload();
                    },
                  });
                }
                return response.data.response;
              })
              .catch((error) => {
                console.log(error);
                let timerInterval;
                this.$swal({
                  title: "Invoice Update",
                  icon: "error",
                  html: "Update Error",
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    const b = this.$swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft();
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                    //window.location.reload();
                  },
                });
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          console.log(result.isConfirmed);
          console.log(result.value);

          if (result.isConfirmed) {
            let timerInterval;
            if (result.value.response_code == 200) {
              if (this.isCOD == 0 && this.userType == 2) {
                this.sendChatMessageInvoice(
                  "La facture à été payée par carte bancaire"
                );
              } else {
                this.sendChatMessageInvoice(
                  "La facture à été acceptéé et va être payée en Cash"
                );
              }

              if (this.isCOD == 0 && this.userType == 2) {
                //alert("GoToPayment");
                this.showInvoiceSwal("pay-invoice");
              } else {
                this.$swal({
                  title: "Invoice Update",
                  icon: "success",
                  html: result.value.response_message,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    //this.$swal.showLoading();
                    const b = this.$swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft();
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                    window.location.reload();
                  },
                });
              }
            } else {
              this.$swal({
                title: "Invoice Update",
                icon: "error",
                html: "Update Error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  // this.$swal.showLoading();
                  const b = this.$swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = this.$swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                  window.location.reload();
                },
              });
            }
          }
        });
      } else if (type === "pay-invoice") {
        this.$swal({
          title: "Are you sure to pay the invoice?",
          text: "Vou will be redirected to safe payment",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Connecting to server",
              text: "In progress....",
              icon: "info",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });

            this.ctpForm.amount = this.invoiceForm.total;
            var username = "0870543014";
            var pwd = "v9R33kcRT";
            var amount = this.invoiceForm.total;
            var rand = Math.floor(Math.random() * amount);
            var sessionCookit = this.invoiceForm.total * 1000 * rand;
            var json_par = '{"cookitNumber":' + this.invoiceForm.total + "}";
            console.log(json_par);

            var failUrl =
              "http://51.210.179.133:8080/labes-front/booking/details/" +
              this.$route.params.id +
              "/";
            var ReturnUrl =
              "http://51.210.179.133:8080/labes-front/booking/details/" +
              this.$route.params.id +
              "/";
            var description = "Commnde de test";

            $.ajax({
              type: "post",
              url: "https://test.clictopay.com/payment/rest/register.do",
              //url: "https://ipay.clictopay.com/payment/rest/register.do",
              dataType: "json",
              data: {
                userName: username,
                password: pwd,
                orderNumber: sessionCookit,
                amount: amount,
                currency: "788",
                jsonParams: json_par,
                language: "en",
                returnUrl: ReturnUrl,
                failUrl: failUrl,
                description: description,
              },
              beforeSend: function () {
                console.log("beforeSend");
                $("#alertPayment").html(
                  '<div class="alert alert-success alert-dismissible" role="alert">Connecting To Server</div>'
                );
              },
              success: function (data) {
                console.log("success");
                console.log("orderId" + data["orderId"]);
                console.log("formUrl" + data["formUrl"]);
                console.log("errorCode" + data["errorCode"]);
                console.log("errorMessage" + data["errorMessage"]);
                console.log("success" + data.success);
                var data_order_id = data["orderId"];
                if (typeof data_order_id === "undefined") {
                  this.$swal({
                    title: "Payment Cancelled",
                    text: data["errorMessage"],
                    icon: "error",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  });
                  //alert('Variable "data_order_id" is undefined.');
                } else if (data_order_id === null) {
                  this.$swal({
                    title: "Payment Cancelled",
                    text: data["errorMessage"],
                    icon: "error",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  });
                } else {
                  // window.location.href = data["formUrl"];
                  window.location.assign(data["formUrl"]);
                }
              },

              error: function (xhr, status, error) {
                console.log("failure");
                console.log(status);
                console.log(error);
                //alert('failure');
                var err = eval("(" + xhr.responseText + ")");
                console.log(err.Message);
              },
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal({
              title: "Payment Cancelled",
              text: "",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          }
        });
      }
    },
    showPaymentSwal(type, value) {
      if (type === "success-payment") {
        this.$swal({
          icon: "success",
          title: "",
          text: value,
          type: type,
        });
      } else if (type === "error-payment") {
        this.$swal({
          icon: "error",
          title: "",
          text: value,
          type: type,
        });
      }
    },
    showSwal(type, value) {
      this.updateBookingForm.status = value;
      if (type === "success-message") {
        this.$swal({
          icon: "success",
          title: "",
          text: "Status updated Successfully!",
          type: type,
        });
      } else if (type === "update-status") {
        this.$swal({
          title: "Updating Booking Status",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: () => {
            axios.defaults.headers["token"] = localStorage.getItem("api_token");
            return axios
              .post("update_booking", this.updateBookingForm)
              .then((response) => {
                if (response.data.response.response_code != 200) {
                  let timerInterval;
                  this.$swal({
                    title: "Booking Update Status",
                    icon: "error",
                    html: response.data.response.response_code,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                      // this.$swal.showLoading();
                      const b = this.$swal
                        .getHtmlContainer()
                        .querySelector("b");
                      timerInterval = setInterval(() => {
                        b.textContent = this.$swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                      window.location.reload();
                    },
                  });
                }
                return response.data.response;
              })
              .catch((error) => {
                console.log(error);
                let timerInterval;
                this.$swal({
                  title: "Booking Update Status",
                  icon: "error",
                  html: "Update Error",
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    const b = this.$swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft();
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                    window.location.reload();
                  },
                });
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          console.log(result.isConfirmed);
          console.log(result.value);

          if (result.isConfirmed) {
            let timerInterval;
            if (result.value.response_code == 200) {
              this.sendChatMessage("Le status a été mis à jour");
              this.$swal({
                title: "Booking Update Status",
                icon: "success",
                html: result.value.response_message,
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  //this.$swal.showLoading();
                  const b = this.$swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = this.$swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                  window.location.reload();
                },
              });
            } else {
              this.$swal({
                title: "Booking Update Status",
                icon: "error",
                html: "Update Error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  // this.$swal.showLoading();
                  const b = this.$swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = this.$swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                  window.location.reload();
                },
              });
            }
          }
        });
      }
    },
    showRatingSwal(type) {
      if (type === "update-rating") {
        this.$swal({
          title: "Rate Provider",

          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,

          preConfirm: () => {
            axios.defaults.headers["token"] = localStorage.getItem("api_token");
            this.formRating.rating = this.rating;
            return axios
              .post("rate_review", this.formRating)
              .then((response) => {
                if (response.data.response.response_code != 200) {
                  let timerInterval;
                  this.$swal({
                    title: "Submit Review",
                    icon: "error",
                    html: response.data.response.response_code,
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                      // this.$swal.showLoading();
                      const b = this.$swal
                        .getHtmlContainer()
                        .querySelector("b");
                      timerInterval = setInterval(() => {
                        b.textContent = this.$swal.getTimerLeft();
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                      window.location.reload();
                    },
                  });
                }
                return response.data.response;
              })
              .catch((error) => {
                console.log(error);
                let timerInterval;
                this.$swal({
                  title: "Submit Review",
                  icon: "error",
                  html: "Review Error",
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    const b = this.$swal.getHtmlContainer().querySelector("b");
                    timerInterval = setInterval(() => {
                      b.textContent = this.$swal.getTimerLeft();
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                    window.location.reload();
                  },
                });
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          ///console.log(result.isConfirmed);
          //console.log(result.value);

          if (result.isConfirmed) {
            let timerInterval;
            if (result.value.response_code == 200) {
              this.sendChatMessage(
                "Bonjour, j'ai donné un avis à propos du service"
              );
              this.$swal({
                title: "Submit Review",
                icon: "success",
                html: result.value.response_message,
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  //this.$swal.showLoading();
                  const b = this.$swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = this.$swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                  window.location.reload();
                },
              });
            } else {
              this.$swal({
                title: "Submit Review",
                icon: "error",
                html: "Review Error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  // this.$swal.showLoading();
                  const b = this.$swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = this.$swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                  window.location.reload();
                },
              });
            }
          }
        });
      }
    },
    async sendChatMessageInvoice(chatMessage) {
      //this.chatForm.to = this.currentUserChat.token;
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      axios.defaults.headers["language"] = "fr";
      if (this.userType == 2) {
        this.chatFormInvoice.content = chatMessage;
      } else {
        this.chatFormInvoice.content = "Bonjour, la facture a été mise à jour";
      }

      await axios
        .post("chat", this.chatFormInvoice)
        .then(({ data }) => {
          //alert("hello");
          console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            console.log(data.response);
          } else {
            console.log(data.status);
            //alert("hella");
          }
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          //alert("login finish ")
        });
    },
    async sendChatMessage(chatMessage) {
      //this.chatForm.to = this.currentUserChat.token;
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      axios.defaults.headers["language"] = "fr";
      this.chatForm.content = chatMessage;
      await axios
        .post("chat", this.chatForm)
        .then(({ data }) => {
          //alert("hello");
          console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            console.log(data.response);
          } else {
            console.log(data.status);
            //alert("hella");
          }
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          //alert("login finish ")
        });
    },
    updateTotalInvoice() {
      this.invoiceForm.total =
        parseInt(this.invoiceForm.frais) +
        parseInt(this.invoiceForm.honoraire) +
        parseInt(this.invoiceForm.materiel) +
        parseInt(this.invoiceForm.transport);

      this.totalLen = this.invoiceForm.total.length;

      // this.totalInvoice = this.invoiceForm.total.slice(0, 5) + ',' +  this.invoiceForm.total.slice(this.totalLen - 3);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(3).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    goToChat() {
      this.$store.state.openChatToken = this.chatToken;
      //const chatTokenId = this.chatToken;
      router.push({
        name: "Chat List",
      });
    },

    setPanCard() {
      this.PanCard =
        this.$store.state.bookingDetail.invoice_details.pan.substr(0, 4) +
        "   " +
        this.$store.state.bookingDetail.invoice_details.pan.substr(4, 2) +
        "**    ****    " +
        this.$store.state.bookingDetail.invoice_details.pan.substr(8, 11);

      this.cardExpiry =
        this.$store.state.bookingDetail.invoice_details.cardExpiry.substr(
          4,
          2
        ) +
        " / " +
        this.$store.state.bookingDetail.invoice_details.cardExpiry.substr(2, 2);
    },

    async setBookingInvoice() {
      this.invoiceForm.userType = this.userType;
      this.invoiceForm.book_id = this.$route.params.id;

      //console.log('Invoice commission:'+ this.$store.state.bookingDetail.invoice_details.frais);
      if (this.$store.state.bookingDetail.invoice_details.frais != null) {
        this.invoiceForm.frais =
          this.$store.state.bookingDetail.invoice_details.frais;
        this.invoiceForm.transport =
          this.$store.state.bookingDetail.invoice_details.transport;
        this.invoiceForm.honoraire =
          this.$store.state.bookingDetail.invoice_details.honoraire;
        this.invoiceForm.total =
          this.$store.state.bookingDetail.invoice_details.total;
        this.invoiceForm.materiel =
          this.$store.state.bookingDetail.invoice_details.materiel;
      } else {
        this.invoiceForm.frais = "5000";
        this.invoiceForm.transport = "0000";
        this.invoiceForm.honoraire = "0000";
        this.invoiceForm.materiel = "0000";
        this.invoiceForm.total = this.invoiceForm.frais;
      }
      // console.log (this.invoiceForm.frais);
    },
    async setBookingStatus() {
      this.bookingDetails = this.$store.state.bookingDetail;
      this.bookingStatusCode = this.bookingDetails.booking_details.status;
      this.chatToken = this.bookingDetails.personal_details.token;
      this.chatForm.to = this.chatToken;
      this.chatFormInvoice.to = this.chatToken;

      if (this.bookingStatusCode == 1) {
        this.bookingStatusColor = "warning";
        this.bookingStatus = "Pending";
      } else if (this.bookingStatusCode == 2) {
        this.bookingStatusColor = "info";
        this.bookingStatus = "In progress";
      } else if (this.bookingStatusCode == 3) {
        this.bookingStatusColor = "success";
        this.bookingStatus = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 4) {
        this.bookingStatusColor = "warning";
        this.bookingStatus = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 5) {
        this.bookingStatusColor = "danger";
        this.bookingStatus = "Rejected by Pro";
      } else if (this.bookingStatusCode == 6) {
        this.bookingStatusColor = "warning";
        this.bookingStatus = "service Complete - Payment Pending";
      } else if (this.bookingStatusCode == 7) {
        this.bookingStatusColor = "danger";
        this.bookingStatus = "Cancelled by Patient";
      } else if (this.bookingStatusCode == 8) {
        this.bookingStatusColor = "success";
        this.bookingStatus = "Complete";
      }
      // Set Cancel Code for user
      if (this.bookingStatusCode > 1) {
        this.cancelUserValue = 7;
      }
    },
    searchUrlParam() {
      const queryString = window.location.search;
      //console.log(queryString);
      const urlParams = new URLSearchParams(queryString);
      //console.log(urlParams.has('orderId'));
      if (urlParams.has("orderId")) {
        //console.log(urlParams.get("orderId"));
        this.paymentReturn = true;
        this.getOrderPaymentStatus(urlParams.get("orderId"));
      }
    },
    async getOrderPaymentStatus(smtOrderId) {
      //var urlParams = new URLSearchParams(window.location.search);
      var username = "0870543014";
      var pwd = "v9R33kcRT";
      var orderId = smtOrderId;
      var language = "fr";
      var userApiToken = localStorage.getItem("api_token");
      var invForm = this.invoiceForm;
      await $.ajax({
        type: "post",
        url: "https://test.clictopay.com/payment/rest/getOrderStatus.do",
        //url: "https://ipay.clictopay.com/payment/rest/getOrderStatus.do",
        dataType: "json",
        data: {
          userName: username,
          password: pwd,
          orderId: orderId,
          language: language,
        },

        success: (data) => {
          //  console.log(data);
          var approvalCode = data["approvalCode"];
          var code = data["ErrorCode"];
          var cardPan = data["Pan"];
          var msg = data["ErrorMessage"];
          var OrderNumber = data["OrderNumber"];
          var cardHolderName = data["cardholderName"];
          var cardExpiration = data["expiration"];
          var ip = data["Ip"];
          var orderId = smtOrderId;

          //console.log("ErrorCode:" + code);
          if (code == 0) {
            //show Alert  Success  Payment
            /*console.log(approvalCode);
            console.log(OrderNumber);
            console.log(cardHolderName);
            console.log(cardPan);
            console.log(cardExpiration);
            console.log(ip);
            console.log(orderId);*/

            invForm.approvalCode = approvalCode;
            invForm.OrderNumber = OrderNumber;
            invForm.cardHolderName = cardHolderName;
            invForm.cardPan = cardPan;
            invForm.cardExpiration = cardExpiration;
            invForm.ip = ip;
            invForm.orderId = orderId;

            axios.defaults.headers["token"] = userApiToken;
            //console.log(localStorage.getItem("api_token"));
            //console.log(invForm);
            axios
              .post("pay_invoice", invForm)
              .then((response) => {
                if (response.data.response.response_code != 200) {
                  //console.log("failed to update payment");
                  this.showPaymentSwal(
                    "error-payment",
                    response.data.response.response_message
                  );
                } else {
                  this.showPaymentSwal("success-payment", msg);
                }
                //return response.data.response;
              })
              .catch((error) => {
                //console.log(error);
                //display payment update payment failed
                this.showPaymentSwal("error-payment", error);
              });
          } else {
            //show Alert Failed  Payment
            // console.log(msg);
            this.showPaymentSwal("error-payment", msg);
          }
        },

        error: function (xhr, status, error) {
          //console.log("failure");
          //console.log(status);
          // console.log(error);
          //alert('failure');
          var err = eval("(" + xhr.responseText + ")");
          console.log(err.Message);
          this.showPaymentSwal(
            "error-payment",
            err + "- status:" + status + " - error:" + error
          );
        },
      });
    },
    async updatePaymentStatus(orderId) {
      console.log(orderId);
    },
  },
};
</script>
