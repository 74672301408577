<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start pt-7 pb-4"
      style="
        background-image: url('https://prod-pl-client-logos.s3.us-west-1.amazonaws.com/9360/logo_raw_9360_20210503113059.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h4 class="text-white mb-2 mt-5">
              Résultat de recherche de votre prestatairess
            </h4>
            <p class="text-lead text-white">Lorem ipsum dolor sit amet</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="py-4 container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="bg-white">
              <div class="card-body">
                <div class="accordion" id="accordionRental">
                  <div class="accordion-item mb-3">
                    <span class="text-xs accordion-header" id="heading_0">
                      <button
                        class="accordion-button border-bottom font-weight-bold collapsed text-info"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse_0"
                        aria-expanded="false"
                        aria-controls="collapse_0"
                      >
                        <h5 class="card-title text-gray-200">Filtres</h5>
                        <i
                          class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                          aria-hidden="true"
                        ></i>
                        <i
                          class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </span>
                    <div
                      id="collapse_0"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading_0"
                      data-bs-parent="#accordionRental"
                      style=""
                    >
                      <div class="accordion-body text-sm opacity-8">
                        <div class="col-lg-12 mt-lg-0 mt-4">
                          <div class="align-items-center my-2">
                            <div class="col-12 mb-4">
                              <label>Keyword</label>
                              <input
                                class="form-control"
                                v-model="keywords"
                                placeholder="Mots clés"
                                type="text"
                              />
                            </div>
                            <div class="col-12 mb-4">
                              <label>Category </label>

                              <select
                                class="form-control"
                                name="choices-category"
                                v-model="selectedCategory"
                              >
                                <option
                                  :value="cat"
                                  v-for="cat of categoryOptions"
                                  :key="cat.id"
                                  :id="cat.id"
                                >
                                  {{ cat.category_name }}
                                </option>
                              </select>
                            </div>
                            <div class="col-12 mb-4">
                              <label>Sub Category </label>

                              <select
                                v-model="selectedSubcategory"
                                class="form-control"
                              >
                                <option
                                  v-for="subCatego of selectedCategory.subCategories"
                                  :key="subCatego.id"
                                  :id="subCatego.id"
                                  :value="subCatego.id"
                                >
                                  {{ subCatego.subcategory_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  @click="filterListProvider"
                  class="btn btn-sm btn-dark mb-0 w-100 mx-auto my-3"
                >
                  filter
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <h5 class="mb-2 my-3">
              Les prestataires de services les plus populaires
            </h5>

            <div class="p-2 bg-white">
              <Carousel :items-to-show="1" :wrap-around="true">
                <Slide
                  v-for="sponsoredprovider in sponsoredProviderList"
                  :key="sponsoredprovider.id"
                >
                  <div class="carousel__item">
                    <div class="row card-body p-4">
                      <div class="top-0 position-absolute d-flex w-100">
                        <div class="p-3 ms-auto">
                          <span class="badge badge-secondary">
                            <i class="fas fa-dot-circle text-info"></i>
                            SPONSORED
                          </span>
                        </div>
                      </div>
                      <div class="flex-shrink-0 text-center">
                        <img
                          :src="imgCatUrl + sponsoredprovider.profile_img"
                          alt="Generic placeholder image"
                          class="img-fluid"
                          style="width: 180px; border-radius: 10px"
                        />
                      </div>
                      <div class="flex-grow-1 ms-auto text-center">
                        <h5 class="m-1 font-weight-bolder text-sm">
                          {{ sponsoredprovider.name }}
                        </h5>
                        <p class="mb-2 pb-1 text-sm" style="color: #2b2a2a">
                          {{ sponsoredprovider.category_name }}
                        </p>
                        <div
                          class="d-flex justify-content-center rounded-3 p-2 mb-2"
                        >
                          <div class="text-xs">
                            <p class="small text-muted mb-1">Avis</p>
                            <p class="mb-0">41</p>
                          </div>
                          <span class="mx-2">|</span>
                          <div class="text-xs">
                            <p class="small text-muted mb-1">Score</p>
                            <p class="mb-0">8.5</p>
                          </div>
                        </div>
                        <div class=" ">
                          <router-link
                            :to="{
                              name: 'ServiceDetail',
                              params: { id: sponsoredprovider.service_id },
                            }"
                          >
                            <button
                              type="button"
                              class="btn btn-outline-info flex-grow-1 w-100"
                            >
                              Consulter
                            </button></router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>

                <template #addons>
                  <Navigation />
                </template>
              </Carousel>

              <div
                id="carouselExampleControls"
                class="d-none carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div
                    v-for="(sponsoredprovider, index) in sponsoredProviderList"
                    :key="sponsoredprovider.id"
                    class="carousel-item"
                    :class="index == 0 ? 'active' : ''"
                  >
                    <div class="px-3">
                      <div class="col-12 col-12 p-3">
                        <div class="row align-items-center">
                          <aside class="col-md-3 blur-shadow-image">
                            <a href="#"
                              ><img
                                :src="imgCatUrl + sponsoredprovider.profile_img"
                                class="rounded-3 shadow-lg"
                                alt="premium watch"
                            /></a>
                          </aside>
                          <div class="col-md-6">
                            <div class="info-main my-2 ps-2">
                              <a href="#" class="h5 title">
                                <router-link
                                  :to="{
                                    name: 'ServiceDetail',
                                    params: {
                                      id: sponsoredprovider.service_id,
                                    },
                                  }"
                                >
                                  <span class="font-weight-bolder mb-0 text-xs">
                                    {{ sponsoredprovider.name }}
                                  </span>
                                </router-link></a
                              >
                              <div>
                                <span class="text-success ms-auto text-xs"
                                  ><span class="ni ni-diamond me-1"></span
                                  >Verified Labes</span
                                >
                              </div>
                              <div>
                                <p class="text-xs">
                                  {{ sponsoredprovider.category_name }}
                                </p>
                              </div>
                              <div class="d-flex my-1 align-items-center">
                                <span
                                  class="star fas fa-star text-info me-1"
                                ></span>
                                <span
                                  class="star fas fa-star text-info me-1"
                                ></span>
                                <span
                                  class="star fas fa-star text-info me-1"
                                ></span>
                                <span
                                  class="star fas fa-star text-info me-1"
                                ></span>
                                <span
                                  class="star fas fa-star text-info me-1"
                                ></span>
                                <span>(4) avis</span>
                              </div>

                              <div>
                                <span
                                  v-for="serviceOf in JSON.parse(
                                    sponsoredprovider.service_offered
                                  )"
                                  :key="serviceOf"
                                  class="badge badge-info text-xxs me-1"
                                  >{{ serviceOf }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-3">
                            <div class="">
                              <span class="small my-1 ps-2"
                                ><span class="fas fa-map me-1"></span>
                                {{ sponsoredprovider.service_location }}
                              </span>
                            </div>

                            <span class="small my-1 ps-2"
                              ><span class="fas fa-phone me-1"></span>+216
                              22555555</span
                            >

                            <div class="p-md-0 m-2">
                              <router-link
                                :to="{
                                  name: 'ServiceDetail',
                                  params: { id: sponsoredprovider.service_id },
                                }"
                              >
                                <button
                                  @click="getBookingInfo"
                                  class="mb-0 btn btn-sm bg-gradient-info mt-lg-auto w-100"
                                  type="button"
                                  name="button"
                                >
                                  Details
                                  <i
                                    class="fas fa-arrow-right text-xs ms-1"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-100 top-0">
                  <a
                    class="carousel-control-prev w-5 bg-dark"
                    href="#carouselExampleControls"
                    role="button"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon bottom-50"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next w-5 bg-dark"
                    href="#carouselExampleControls"
                    role="button"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon bottom-50"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="mb-2 my-3 text-center d-flex justify-content-between">
              <b-spinner
                v-for="variant in variants"
                :variant="variant"
                :key="variant"
                type="grow"
              ></b-spinner>
            </div>

            <h5 v-if="paginatedProviders.length > 0" class="mb-2 my-3">
              Liste de prestataires sur votre zone
            </h5>

            <div class="m-1">
              <div
                class="row p-3 bg-white"
                v-show="paginatedProviders.length == 0"
              >
                <div class="col-md-12">
                  <div class="card card-profile card-plain">
                    <div class="row">
                      <h5 class="mb-2 my-3">Resultat de recherche (0)</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="m-1"
              v-for="provider in paginatedProviders"
              :key="provider.id"
            >
              <div class="row p-3 bg-white" v-if="provider.service_id != null">
                <div class="col-12 col-12 p-3">
                  <div class="row align-items-center">
                    <aside class="col-md-3 blur-shadow-image">
                      <a href="#"
                        ><img
                          :src="imgCatUrl + provider.profile_img"
                          class="rounded-3 shadow-lg"
                          alt="premium watch"
                      /></a>
                    </aside>
                    <div class="col-md-6">
                      <div class="info-main my-2 ps-2">
                        <a href="#" class="h5 title">
                          <router-link
                            :to="{
                              name: 'ServiceDetail',
                              params: { id: provider.service_id },
                            }"
                          >
                            <span class="font-weight-bolder mb-0 text-xs">
                              {{ provider.name }}
                            </span>
                          </router-link></a
                        >
                        <div>
                          <span class="text-success ms-auto text-xs"
                            ><span class="fa fa-medal me-1"></span>Verified
                            Labes</span
                          >
                        </div>
                        <div>
                          <p class="text-xs">
                            {{ provider.category_name }}
                          </p>
                        </div>
                        <div class="d-flex my-1 align-items-center">
                          <star-rating
                            class="mx-2 text-info"
                            :star-size="20"
                            :show-rating="false"
                            :round-start-rating="false"
                            :rating="provider.rating"
                          ></star-rating>
                          <span>({{ provider.rating_count }}) avis</span>
                        </div>

                        <div>
                          <span
                            v-for="serviceOf in JSON.parse(
                              provider.service_offered
                            )"
                            :key="serviceOf"
                            class="badge badge-info text-xxs me-1"
                            >{{ serviceOf }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="">
                        <span class="small my-1 ps-2"
                          ><span class="fas fa-map me-1"></span>
                          {{ provider.service_location }}
                        </span>
                      </div>

                      <span class="small my-1 ps-2"
                        ><span class="fas fa-phone me-1"></span>+216
                        22555555</span
                      >

                      <div class="p-md-0 m-2">
                        <router-link
                          :to="{
                            name: 'ServiceDetail',
                            params: { id: provider.service_id },
                          }"
                        >
                          <button
                            @click="getBookingInfo"
                            class="mb-0 btn btn-sm bg-gradient-info mt-lg-auto w-100"
                            type="button"
                            name="button"
                          >
                            Details
                            <i
                              class="fas fa-arrow-right text-xs ms-1"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-none col-md-12">
                  <div class="card card-profile card-plain">
                    <div class="row">
                      <div class="col-lg-2">
                        <a href="javascript:;">
                          <div class="position-relative">
                            <div class="blur-shadow-image">
                              <img
                                class="w-100 rounded-3 shadow-lg"
                                :src="imgCatUrl + provider.profile_img"
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-10 ps-0 mx-auto">
                        <div class="card-body text-left">
                          <div class="row">
                            <div class="col-lg-8 ps-0">
                              <div class="p-md-0 pt-1">
                                <router-link
                                  :to="{
                                    name: 'ServiceDetail',
                                    params: { id: provider.service_id },
                                  }"
                                >
                                  <span class="font-weight-bolder mb-0 text-xs">
                                    {{ provider.name }}
                                  </span>
                                </router-link>

                                <i
                                  class="ni ni-diamond text-success text-xs me-1"
                                ></i>
                                <span class="text-success text-xxs"
                                  >Verified Labes</span
                                >
                                <div>
                                  <p class="text-xs">
                                    {{ provider.category_name }}
                                  </p>
                                </div>

                                <div class="align-self-start">
                                  <div class="rating mt-lg-0">
                                    <i
                                      class="fas fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fas fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fas fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fas fa-star"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="fas fa-star-half-alt"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>

                                <span>(5) avis</span>

                                <div>
                                  <span
                                    v-for="serviceOf in JSON.parse(
                                      provider.service_offered
                                    )"
                                    :key="serviceOf"
                                    class="badge badge-info text-xxs me-1"
                                    >{{ serviceOf }}</span
                                  >
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-4 ps-0">
                              <div class="mx-auto my-2 text-xs">
                                <i
                                  class="fas fa-map-marker border ml-1 me-2 text-info"
                                  style="
                                    font-size: 12px;
                                    line-height: 26px;
                                    text-align: center;
                                    width: 27px;
                                    height: 27px;
                                    border: 1px #1171ef;
                                    border-radius: 50%;
                                  "
                                >
                                </i>
                                Serving {{ provider.service_location }} and the
                                Surrounding Area
                              </div>
                              <div
                                class="d-flex mx-auto my-2 align-items-center text-xs"
                              >
                                <i
                                  class="fas fa-phone border ml-1 me-2 text-info"
                                  style="
                                    font-size: 12px;
                                    line-height: 26px;
                                    text-align: center;
                                    width: 27px;
                                    height: 27px;
                                    border: 1px #1171ef;
                                    border-radius: 50%;
                                  "
                                >
                                </i>
                                +216
                                {{ provider.provider_phone }}
                              </div>
                              <div class="p-md-0 m-2">
                                <router-link
                                  :to="{
                                    name: 'ServiceDetail',
                                    params: { id: provider.service_id },
                                  }"
                                >
                                  <button
                                    @click="getBookingInfo"
                                    class="mb-0 btn btn-sm bg-gradient-info mt-lg-auto w-100"
                                    type="button"
                                    name="button"
                                  >
                                    Details
                                    <i
                                      class="fas fa-arrow-right text-xs ms-1"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-grow-1 my-4 ms-auto text-center">
              <button
                @click="loadMore"
                v-if="
                  currentPage * maxPerPage < listProviders.length &&
                  paginatedProviders.length > 0
                "
                class="mb-0 btn btn-sm bg-gradient-info align-self-center"
                type="button"
                name="button"
              >
                <span class="btn-inner--icon mx-2"
                  ><i class="ni ni-atom"></i
                ></span>
                <span class="btn-inner--text">Load more</span>
                More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
//import Choices from "choices.js";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
//import flatPickr from "vue-flatpickr-component";

//import OtherProducts from "../../ecommerce/components/OtherProducts.vue";

import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapMutations } from "vuex";
import { mapActions } from "vuex";
import axios from "axios";
import starRating from "vue-star-rating";

import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  name: "LabesProviders",

  data() {
    return {
      variants: [
        "primary",
        "secondary",
        "danger",
        "warning",
        "success",
        "info",
        "light",
        "dark",
      ],
      keywords: "",
      searchResult: [],
      filtredElement: [],
      selectedCategory: "", // To store the selected category
      selectedSubcategory: "", // To store the selected subcategory
      categoryOptions: this.$store.state.homeData.category_list,
      displayProviderList: [],
      sponsoredProviderList: [],
      auth: {
        email: "",
        password: "",
        remember: false,
      },
      imgCatUrl: "https://back.labes.com.tn/",
      isErrorLogin: false,
      errMessage: "",
      selectedDate: "",
      service_id: this.$route.params.id,
      paramsAvailable: {
        service_id: "",
        date: "",
      },
      config: {
        //allowInput: true,
        minDate: "today",
        dateFormat: "d-m-Y",
      },
      currentPage: 1,
      maxPerPage: 4,
      listProviders: [],
    };
  },
  components: {
    Navbar,
    AppFooter,
    Carousel,
    Slide,
    Navigation,
    starRating,
    //OtherProducts,
    //flatPickr,
  },
  watch: {},
  async created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    this.keywords = this.$store.state.searchTerm;
    this.$store.state.searchTerm = "";
    // console.log("The id is: " + this.$route.params.id);

    await this.$store.dispatch("loadProviders");

    this.listProviders = this.$store.state.providers;
    if (this.keywords != "") {
      await this.lookup();
    } else {
      this.displayProviderList = this.listProviders;
    }
    this.sponsoredProviderList = this.listProviders.filter(
      (provider) => provider.service_id != null
    );
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  computed: {
    homeData() {
      return this.$store.state.homeData;
    },
    /* providers() {
      //console.log(this.$store.state.categories)
      
      return this.$store.state.providers;
    },*/

    paginatedProviders() {
      //return this.listProviders.slice(0, this.currentPage * this.maxPerPage); // Display  providers  without filter
      return this.displayProviderList.slice(
        0,
        this.currentPage * this.maxPerPage
      );
    },

    /*isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },*/
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    const bootstrap = this.$store.state.bootstrap;

    // Gallery Carousel
    if (document.getElementById("products-carousel")) {
      var myCarousel = document.querySelector("#products-carousel");
      // eslint-disable-next-line no-unused-vars
      var carousel = new bootstrap.Carousel(myCarousel);
    }

    // Products gallery

    var initPhotoSwipeFromDOM = function (gallerySelector) {
      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item;

        for (var i = 0; i < numNodes; i++) {
          figureEl = thumbElements[i]; // <figure> element
          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue;
          }

          linkEl = figureEl.children[0]; // <a> element

          size = linkEl.getAttribute("data-size").split("x");

          // create slide object
          item = {
            src: linkEl.getAttribute("href"),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10),
          };

          if (figureEl.children.length > 1) {
            // <figcaption> content
            item.title = figureEl.children[1].innerHTML;
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.children[0].getAttribute("src");
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
        }

        return items;
      };

      // find nearest parent element
      var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
      };

      // triggers when user clicks on thumbnail
      var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function (el) {
          return el.tagName && el.tagName.toUpperCase() === "FIGURE";
        });

        if (!clickedListItem) {
          return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

        for (var i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue;
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
          }
          nodeIndex++;
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery);
        }
        return false;
      };

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
          params = {};

        if (hash.length < 5) {
          return params;
        }

        var vars = hash.split("&");
        for (var i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue;
          }
          var pair = vars[i].split("=");
          if (pair.length < 2) {
            continue;
          }
          params[pair[0]] = pair[1];
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10);
        }

        return params;
      };

      var openPhotoSwipe = function (
        index,
        galleryElement,
        disableAnimation,
        fromURL
      ) {
        var pswpElement = document.querySelectorAll(".pswp")[0],
          gallery,
          options,
          items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute("data-pswp-uid"),

          getThumbBoundsFn: function (index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
              pageYScroll =
                window.pageYOffset || document.documentElement.scrollTop,
              rect = thumbnail.getBoundingClientRect();

            return {
              x: rect.left,
              y: rect.top + pageYScroll,
              w: rect.width,
            };
          },
        };

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (var j = 0; j < items.length; j++) {
              if (items[j].pid == index) {
                options.index = j;
                break;
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1;
          }
        } else {
          options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return;
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(
          pswpElement,
          // eslint-disable-next-line no-undef
          PhotoSwipeUI_Default,
          items,
          options
        );
        gallery.init();
      };

      // loop through all gallery elements and bind events
      var galleryElements = document.querySelectorAll(gallerySelector);

      for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute("data-pswp-uid", i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      var hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(
          hashData.pid,
          galleryElements[hashData.gid - 1],
          true,
          true
        );
      }
    };

    // execute above function
    initPhotoSwipeFromDOM(".my-gallery");
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    ...mapActions({
      signIn: "auth/afterLogin",
    }),

    loadMore() {
      this.currentPage += 1;
    },

    showSwal(type) {
      if (type === "success-message") {
        this.$swal({
          icon: "success",
          title: "Réservation de service!",
          text: "Votre demande réservation a été envoyé!",
          type: type,
        });
      }
    },
    async lookup() {
      await axios
        .post("services_lookup", { text: this.keywords })
        .then(({ data }) => {
          this.filtredElement = [];
          this.searchResult = data.data.service_list;
          this.listProviders = this.$store.state.providers;

          if (this.searchResult.length > 0) {
            // console.log(this.searchResult);
            var items = "";
            this.searchResult.forEach((element) => {
              items = this.listProviders.filter(
                (provider) => provider.id == element.provider_id
              );

              this.filtredElement.push(items);
            });
            this.displayProviderList = this.filtredElement.flat();
          } else {
            this.displayProviderList = [];
          }
        })
        .catch(({ response }) => {
          this.errMessage = response.data.message;
          //console.log(response);
        })
        .finally(() => {
          //alert("login finish ")
        });
    },
    async filterListProvider() {
      if (this.keywords != "") {
        this.lookup();
      } else {
        this.$store.state.keywords = this.keywords;
        this.displayProviderList = this.$store.state.providers;
      }
    },
    async bookService() {
      await axios
        .post("book_service", this.formBooking)
        .then(({ data }) => {
          this.showSwal("success-message");
          console.log(data.status);
          this.isResponse = true;
        })
        .catch(({ response }) => {
          this.errMessage = response.data.message;
          //console.log(response);
          this.isErrorInsert = true;
          this.isResponse = true;
        })
        .finally(() => {
          //alert("login finish ")
        });
    },
    // Update the selected subcategory when the category changes
    updateSubcategories: function () {
      // Clear the subcategories list
      this.subcategories = [];

      // Find the selected category
      const selectedCategory = this.category_list.find(
        (category) => category.id === this.selectedCategory
      );
      //console.log("selectedCategory: " + selectedCategory);
      // Populate the subcategories list with subcategories from the selected category
      if (selectedCategory) {
        this.subcategories = selectedCategory.subCategories;
      }
    },
  },
};
</script>
