<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" />
      </div>
    </div>
  </div>
  <main class="main-content main-content-bg bg-white mt-0">
    <!-- <div
      class="page-header position-relative"
      style="background-image: url('https://img.freepik.com/photos-gratuite/vue-dessus-forme-coeur-endommage-stethoscope-espace-copie_23-2148635208.jpg?w=1380&t=st=1686398073~exp=1686398673~hmac=a046c5db012bbc9914544a72fed72e29ef9a034d0bf2901a6ba175cc5ceea70a');
      "
    > -->
    <div class="page-header position-relative">
      <!--<span class="mask bg-gradient-dark opacity-6"></span>-->
      <span class="mask bg-white"></span>
      <div class="container pb-6 pb-lg-6 pt-7 postion-relative z-index-2">
        <div class="row justify-content-center">
          <div class="row">
            <div class="d-none">
              <select v-model="$i18n.locale">
                <option value="en">English</option>
                <option value="fr">Français</option>
              </select>
            </div>
            <div class="d-none">
              <h1>{{ $t('welcome') }}</h1>
              <button>{{ $t('button.submit') }}</button>
              <button>{{ $t('button.cancel') }}</button>
            </div>
            <div class="mx-auto text-center col-md-6 mt-4">
              <h3 class="text-info">LABES</h3>
              <h4>Nouvelle adresse d'e-santé</h4>
            </div>
          </div>
          <div class="row p-0">
            <!--- search box -->
            <div class="mx-auto text-center col-lg-9 col-md-12 col-12">
              <div class="nav-wrapper position-relative z-index-2">
                <ul v-show="false" id="tabs-pricing" class="flex-row p-1 nav nav-pills nav-fill" role="tablist">
                  <li class="nav-item" v-for="category in homeData.category_list" :key="category.id">
                    <a id="tabs-iconpricing-tab-1" class="mb-0 nav-link text-xs active" data-bs-toggle="tab"
                      href="#monthly" role="tab" aria-controls="monthly" aria-selected="true">{{ category.category_name
                      }}</a>
                  </li>
                </ul>

                <form class="row border-radius-lg my-2 py-2 justify-content-center mx-auto">
                  <div class="col-md-9">

                    <h4 class=" text-sm text-start mb-0 fadeIn1 fadeInBottom mb-2 px-2">
                      Prenez rendez-vous avec : {{ part }}
                    </h4>




                    <!-- Search Bar -->
                    <div class="input-group input-group-alternative mb-2 px-2">

                      <input class="form-control" v-model="psearchQuery" @input="psearchProviders"
                        placeholder="Mot clés, Nom ..." type="text" id="search" />
                      <span class="input-group-text">
                        <button class="btn bg-gradient-info mb-0" type="button" id="button-addon2"
                          @click="gotoProviderPages">
                          Chercher
                        </button>
                      </span>
                    </div>

                    <div v-if="pproviders.length != 0"
                      class="bg-gray-50 min-w-screen min-h-screen flex justify-center items-center mb-2 px-2">
                      <div class="max-w-xs relative space-y-3">
                        <ul
                          class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10 dropdown-menu show"
                          style="max-height: 24em; overflow-y: auto;">
                          <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                            Showing {{pproviderList.length}} of
                            {{ pproviders.length }} results
                          </li>
                          <li v-for="pll in pproviderList" :key="pll.id" class="cursor-pointer hover:bg-gray-100 p-1">
                            <div class="card-header d-flex align-items-center border-bottom py-3">
                              <div class="d-flex align-items-center">
                                <a href="javascript:;">
                                  <img :src="imgCatUrl + pll.profile_img" class="avatar" alt="profile-image">
                                </a>
                                <div class="mx-3">
                                  <h6 class="mb-0">{{ pll.name }}</h6>
                                  <p class="text-muted text-xs">
                                    {{ pll.service_title }}
                                  </p>

                                </div>
                              </div>
                              <div class="text-end ms-auto">
                                <button type="button" class="btn btn-xs btn-info mb-0">
                                  <a :href="'/service-provider/' + pll.service_id" class="">
                                    <i class="fas fa-plus pe-2 text-white" aria-hidden="true">

                                    </i>
                                    <small class="text-white hide-on-mobile">Explore</small>
                                  </a>
                                </button>
                              </div>
                            </div>
                          </li>



                          <button class="btn bg-gradient-info my-3 w-100" type="button" id="button-addon2"
                            @click="gotoProviderPages">
                            Voir Plus
                          </button>


                        </ul>

                        <p v-if="selectedProvider" class="text-lg pt-2 absolute">
                          You have selected:
                          <span class="font-semibold">{{
                            selectedProvider
                            }}</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <div class="d-none input-group px-2 mb-1">
                      <span class="input-group-text bg-gradient-warning" @click="locatorButtonPressed"><i
                          class="fas fa-crosshairs text-white"> </i></span>
                      <input type="text" class="form-control px-2" placeholder="Votre Position"
                        aria-label="Votre Position" />
                    </div>
                    <p class="d-none">Lat = {{ lat }} Lon ={{ lon }}</p>
                    <p>{{ error }}</p>
                  </div>
                  <div class="col-md-5">
                    <div class="d-none input-group px-2 mb-1">
                      <select class="form-control px-2 me-1" name="choices-button" id="choices-button"
                        placeholder="Categories">
                        <option value="Choice 1" selected="">
                          Toutes les catégories
                        </option>
                        <option v-for="category in homeData.category_list" :key="category.id" :value="category.id">
                          {{ category.category_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!--- Call to action I Section -->
            <div class="mx-auto text-center col-lg-12 col-md-12 col-12 mt-2 mb-4">
              <section class="home">
                <div class="description">
                  <h2 class="title">
                    <span class="text-info">Connectez-vous</span>
                    pour vivre une nouvelle expérience e-santé.
                  </h2>
                  <p class="paragraph">
                    Nous nous engageons de vous donner le meilleur dans le domaine
                    de la santé pour une meilleure remise en forme et convalescence.
                  </p>

                  <div class="text-center">
                    <button type="button" class="btn btn-outline-info me-1 flex-grow-1 mb-0">
                      <a href="/authentication/signup-pro" class=""> Se connecter </a>
                    </button>
                  </div>
                </div>

                <div class="users-color-container">
                  <span class="item" style="--i: 1"></span>
                  <img class="item" src="https://back.labes.com.tn/uploads/profile_img/safta.png" style="--i: 2"
                    alt="" />
                  <span class="item" style="--i: 3"></span>
                  <img class="item"
                    src="https://www.granddeclic.ca/wp-content/uploads/2022/09/trecq-grand-declic-infirmire-spacialisee-uai-630x630.jpg"
                    style="--i: 4" alt="" />

                  <img class="item"
                    src="https://img.freepik.com/photos-premium/portrait-infirmiere-isole-blanc_53419-9800.jpg"
                    style="--i: 10" alt="" />
                  <span class="item" style="--i: 11"></span>
                  <img class="item"
                    src="https://www.freepnglogos.com/uploads/doctor-png/doctor-bulk-billing-doctors-chapel-hill-health-care-medical-3.png"
                    style="--i: 12" alt="" />
                  <span class="item" style="--i: 5"></span>

                  <span class="item" style="--i: 9"></span>
                  <img class="item"
                    src="https://st2.depositphotos.com/1158045/6892/i/450/depositphotos_68924935-stock-photo-male-nurse-portrait.jpg"
                    style="--i: 8" alt="" />
                  <span class="item" style="--i: 7"></span>
                  <img class="item" src="https://back.labes.com.tn/uploads/profile_img/1683061678.png" style="--i: 6"
                    alt="" />
                </div>
              </section>
            </div>
            <!--- Providers Caroussel -->
            <div class="mx-auto text-center col-lg-9 col-md-12 col-sm-12 mt-2 mb-4">
              <div class="row">
                <Carousel v-bind="settingsCarouselProvider" :breakpoints="breakpoints">
                  <Slide v-for="trservice in homeData.top_rating_services.slice(
                      0,
                      3
                    )" :key="trservice.id">
                    <div class="carousel__item">
                      <div class="card col-lg-12 col-md-12 col-sm-12 border mx-1">
                        <div class="card-body">
                          <div class="flex-shrink-0 text-center">
                            <img :src="imgCatUrl + trservice.user_image" alt="Generic placeholder image"
                              class="img-fluid" style="width: 180px; border-radius: 10px" />
                          </div>
                          <div class="flex-grow-1 ms-auto text-center">
                            <h5 class="m-1 font-weight-bolder text-xxs">
                              {{ trservice.provider_name }}
                            </h5>
                            <p class="mb-1 pb-1 text-xxs">
                              {{ trservice.category_name }}
                            </p>

                            <div>
                              <i class="fas fa-star" aria-hidden="true"></i>
                              <i class="fas fa-star" aria-hidden="true"></i>
                              <i class="fas fa-star" aria-hidden="true"></i>
                              <i class="fas fa-star" aria-hidden="true"></i>
                              <i class="fas fa-star-half-alt" aria-hidden="true"></i>
                            </div>

                            <div class="d-flex justify-content-center rounded-3 p-2 mb-2">
                              <div class="text-xs">
                                <p class="small text-muted mb-1">Avis</p>
                                <p class="mb-0">{{ trservice.rating_count }}</p>
                              </div>
                              <span class="mx-2">|</span>
                              <div class="px-3 text-xs">
                                <p class="small text-muted mb-1">Score</p>
                                <p class="mb-0">{{ trservice.ratings }}</p>
                              </div>
                            </div>
                            <div class="d-flex pt-1">
                              <button type="button" class="btn btn-outline-info me-1 flex-grow-1 w-100">
                                <router-link :to="{
                                    name: 'ServiceDetail',
                                    params: { id: trservice.service_id },
                                  }">
                                  Consulter</router-link>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>

                  <template #addons>
                    <Navigation />
                  </template>
                </Carousel>
              </div>
            </div>
            <!--- Call to action box -->
            <div class="col-lg-9 col-md-12 col-12 mx-auto mb-4 btn btn-outline-info">
              <div class="overflow-hidden position-relative bg-cover h-100">
                <span class="mask"></span>
                <div class="card-body position-relative z-index-1 h-100">
                  <h3 class="font-weight-bolder mb-3">
                    Vous êtes professionels de la santé, Découvrir nos services LABES
                  </h3>
                  <p class="mb-3">
                    Découvrir nos services LABES
                  </p>
                  <div class="text-end">
                    <a class="btn btn-round btn-outline-info mb-0" href="/authentication/signup-pro">
                      Rejoignez-nous
                      <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--- Labes Steps  -->
    <div class="row justify-content-center mx-auto bg-white my-3">
      <div class="col-lg-9">
        <h3 class="font-weight-bolder">
          Nos catégories de services disponible
        </h3>
        <p class="mb-0">Professions paramédicales</p>
      </div>
      <div class="mx-auto text-center col-lg-9 col-md-12 col-sm-12 mt-2 mb-4">
        <!--- Category Caroussel -->
        <div class="row">
          <Carousel v-bind="settingsCarouselCategory" :breakpoints="breakpointsCategory">
            <Slide v-for="category in homeData.category_list['0'].subCategories" :key="category.id">
              <div class="carousel__item">
                <div class="avatar avatar-xxl" style="width: 18rem">
                  <img
                    src="https://media.istockphoto.com/id/1321617070/vector/health-medical-logo.jpg?s=612x612&w=0&k=20&c=sus8vhG3c__vCdvOBLDhuf2vPUgIAudIAeUBApU_7Ew="
                    class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h6 class="text-xs mt-3">
                      {{ category.subcategory_name }}
                    </h6>
                  </div>
                </div>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mx-auto bg-white">
      <!--- Service section  -->

      <div class="col-lg-9 mt-4">
        <h3 class="font-weight-bolder">Nos services populaires</h3>
        <p class="mb-0">
          Trouvez votre service en toute et prenez un rendez vous en toute
          simplicité
        </p>
      </div>

      <div class="row">
        <div class="mx-auto col-lg-9 col-md-12 col-12">
          <div class="row">
            <div v-for="service in homeData.popular_services.slice(0, 3)" :key="service.service_id"
              class="col-12 col-sm-12 col-lg-4 my-4 d-flex">
              <div class="border-0 overflow-hidden">
                <div class="position-relative">
                  <img :src="imgCatUrl + service.service_image" class="card-img-top" alt="labes service photo " />
                </div>
                <div class="card-body border position-relative mt-n4 mx-2 bg-white text-center rounded">
                  <router-link :to="{
                      name: 'ServiceDetail',
                      params: { id: service.service_id },
                    }">
                    <span class="text-sm card-title">
                      {{ service.service_title }}
                    </span>
                    <span class="text-sm"> </span>
                  </router-link>

                  <span class="d-flex mx-auto justify-content-center">
                    <div class="position-relative d-flex mx-auto justify-content-center text-sm">
                      <div class="rating text-xxs">
                        <i class="fas fa-star" aria-hidden="true"></i>
                        <i class="fas fa-star" aria-hidden="true"></i>
                        <i class="fas fa-star" aria-hidden="true"></i>
                        <i class="fas fa-star" aria-hidden="true"></i>
                        <i class="fas fa-star-half-alt" aria-hidden="true"></i>
                      </div>
                    </div>
                  </span>

                  <span class="badge badge-primary text-xxs">
                    {{ service.category_name }}
                  </span>

                  <div class="author align-items-center mt-3">
                    <img :src="imgCatUrl + service.user_image" alt="..." class="avatar avatar-sm rounded-circle shadow"
                      style="border: 1px solid #72dec9" />
                    <div class="name ps-3 d-flex">
                      <span class="text-xxs">{{ service.provider_name }}</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-around text-xxs mt-3">
                    <div class="">
                      <span><span class="fas fa-phone me-2"></span>+216
                        {{
                        service.provider_phone.replace(/(?<=\d{2})\d /g, "*" ) }}</span>
                    </div>
                    <div class="">
                      <span><span class="fas fa-map-marker me-2"></span>{{ service.service_location }}</span>
                    </div>
                  </div>

                  <router-link :to="{
                      name: 'ServiceDetail',
                      params: { id: service.service_id },
                    }">
                    <div class="btn-group my-2">
                      <button class="btn bg-gradient-info btn-icon btn-xs">
                        Plus
                      </button>
                      <button class="btn bg-gradient-info" aria-label="mesage button" title="message button">
                        <span aria-hidden="true" class="fas fa-plus"></span>
                      </button>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 text-center mx-auto my-3">
          <button type="button" class="btn bg-gradient-info btn-lg">
            <a href="javascript:;" class="text-white text-bold icon-move-right font-weight-bold">Tous les services
              <i class="fas fa-arrow-right text-bold ms-1" aria-hidden="true"></i>
            </a>
          </button>
        </div>
      </div>

      <!--- Labes Steps  -->
      <div class="d-none container row justify-content-center mx-auto bg-white my-4">
        <div class="col-lg-9">
          <div class="row text-center">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-xss-12">
              <img src="https://cdn.med.tn/img/step1.svg" style="width: 210px; height: 175px"
                alt="Accédez rapidement à votre médecin" />
              <p>Accédez rapidement à votre infirmier</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-xss-12">
              <img src="https://cdn.med.tn/img/step2.svg" style="width: 210px; height: 175px"
                alt="Prenez rendez-vous en ligne à tout moment" />
              <p>Prenez rendez-vous en ligne à tout moment</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 col-xss-12">
              <img src="https://cdn.med.tn/img/step3.svg" style="width: 210px; height: 175px"
                alt="Recevez des sms/mail de rappel personnalisés" />
              <p>Profiter du service</p>
            </div>
          </div>
        </div>
      </div>
      <!--- Category section  -->
      <div class="col-lg-9 mt-4">
        <div class="d-none card shadow">
          <div class="card-body px-5 py-5 text-center text-md-left">
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="px-4 py-6 text-component">
                  <!--<img
                    src="https://ingenico.com/themes/custom/ingenico/build/images/icons/world-marker.svg"
                    alt="icon"
                    class="icon1"
                  /> -->
                  <h2>Devenir partenaire</h2>
                  <div class="body-txt">
                    Rejoignez notre écosystème de partenaires, conçu pour vous
                    aider à développer votre activité.
                  </div>
                  <div class="btn-wrap">
                    <a href="authentication/signup-pro" class="btn btn-outline-info">
                      Rejoignez-nous
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 10 10" stroke="currentColor"
                        class="ml-2 h-3 w-3">
                        <g transform="translate(-3947 6344.354)">
                          <line fill="none" stroke-linejoin="round" stroke-miterlimit="10" x1="3948" y1="-6339.4"
                            x2="3956" y2="-6339.4"></line>
                          <path fill="none" stroke-miterlimit="10" d="M3952-6343.4l4,4l-4,4"></path>
                        </g>
                      </svg></a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-4 mt-md-0 text-md-right">
                <img loading="lazy" class="panel-image-right"
                  src="https://ingenico.com/sites/default/files/styles/max_w1248/public/block/2022-07/Become-a-PArtner-CTA.png?itok=4b_Eiv3R"
                  alt="Become a Partner" typeof="foaf:Image" />
              </div>
            </div>
          </div>
        </div>

        <!---------------Get In Touch section -->

        <div class="card shadow">
          <div class="card-body px-5 py-5 text-center text-md-left">
            <div class="row align-items-center">
              <div class="col-md-6">
                <h2 class="mb-3">Entrez en contact</h2>
                <p class="mb-0">
                  Si vous avez besoin d'aide ou vous avez des questions sur nos services, N'hésitez pas de nous
                  contacter.
                </p>
              </div>
              <div class="col-12 col-md-6 mt-4 mt-md-0 text-md-right">
                <a href="/contact/" class="btn btn-dark"><span class="me-1"><span class="fas fa-headphones"></span>
                  </span>Contactez-nous</a>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="d-none col-lg-9 mt-4">
        <h3 class="font-weight-bolder">
          Nos catégories de services disponible
        </h3>
        <p class="mb-0">Assistant de soins personnels</p>
      </div>
      <div class="d-none row">
        <div class="mx-auto col-lg-9 col-md-12 col-12 my-4 row">
          <div class="col-lg-4 col-md-12 col-12 my-4">
            <div class="justify-content-center mx-auto row">
              <div v-for="category in homeData.category_list" :key="category.id" class="col-lg-12 col-md-12 col-sm-12">
                <div class="accordion" id="accordionRental">
                  <div class="accordion-item mb-3">
                    <span class="text-xs accordion-header" :id="'heading_' + category.id">
                      <button class="accordion-button border-bottom font-weight-bold collapsed text-info" type="button"
                        data-bs-toggle="collapse" :data-bs-target="'#collapse_' + category.id" aria-expanded="false"
                        :aria-controls="'collapse_' + category.id">
                        {{ category.category_name }}
                        <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                          aria-hidden="true"></i>
                        <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                          aria-hidden="true"></i>
                      </button>
                    </span>
                    <div :id="'collapse_' + category.id" class="accordion-collapse collapse"
                      :aria-labelledby="'heading_' + category.id" data-bs-parent="#accordionRental" style="">
                      <div class="accordion-body text-sm opacity-8">
                        <div class="col-lg-12 mt-lg-0 mt-4">
                          <div v-for="subcategory in category.subCategories" :key="subcategory.id"
                            class="d-flex align-items-center my-2">
                            <a :href="subcategory.subcategory_name" class="mb-0 me-1 text-xxs text-info">
                              {{ subcategory.subcategory_name }}
                              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 10 10"
                                stroke="currentColor" class="ml-2 h-3 w-3">
                                <g transform="translate(-3947 6344.354)">
                                  <line fill="none" stroke-linejoin="round" stroke-miterlimit="10" x1="3948"
                                    y1="-6339.4" x2="3956" y2="-6339.4"></line>
                                  <path fill="none" stroke-miterlimit="10" d="M3952-6343.4l4,4l-4,4"></path>
                                </g>
                              </svg></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <div class="card shadow">
              <div class="card-body px-5 py-5 text-center text-md-left">
                <div class="align-items-left">
                  <div class="flex-1">
                    <h2>Restez informé</h2>
                    <div class="txtWrap">
                      <p>
                        <span><span>Découvrez les dernières actualités de Labes.</span></span>
                      </p>
                    </div>
                  </div>

                  <div class="btn-wrap">
                    <a href="#" target="_self" class="btn">
                      Voir toutes les actualités
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 10 10" stroke="currentColor"
                        class="ml-2 h-3 w-3">
                        <g transform="translate(-3947 6344.354)">
                          <line fill="none" stroke-linejoin="round" stroke-miterlimit="10" x1="3948" y1="-6339.4"
                            x2="3956" y2="-6339.4"></line>
                          <path fill="none" stroke-miterlimit="10" d="M3952-6343.4l4,4l-4,4"></path>
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container row justify-content-center mx-auto bg-white">
      <!---------------Brand / Partner section -->
      <div class="container my-6 d-none">
        <div class="row">
          <div class="col-8 mx-auto text-center">
            <h6 class="opacity-5">More than 50+ brands trust Soft</h6>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-2 col-md-4 col-6 mb-4">
            <img class="w-100 opacity-9"
              src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/logos/gray-logos/logo-coinbase.svg"
              alt="logo_coinbase" />
          </div>
          <div class="col-lg-2 col-md-4 col-6 mb-4">
            <img class="w-100 opacity-9"
              src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/logos/gray-logos/logo-nasa.svg"
              alt="logo_nasa" />
          </div>
          <div class="col-lg-2 col-md-4 col-6 mb-4">
            <img class="w-100 opacity-9"
              src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/logos/gray-logos/logo-netflix.svg"
              alt="logo_netflix" />
          </div>
          <div class="col-lg-2 col-md-4 col-6 mb-4">
            <img class="w-100 opacity-9"
              src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/logos/gray-logos/logo-pinterest.svg"
              alt="logo_pinterest" />
          </div>
          <div class="col-lg-2 col-md-4 col-6 mb-4">
            <img class="w-100 opacity-9"
              src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/logos/gray-logos/logo-spotify.svg"
              alt="logo_spotify" />
          </div>
          <div class="col-lg-2 col-md-4 col-6 mb-4">
            <img class="w-100 opacity-9"
              src="https://demos.creative-tim.com/argon-dashboard-pro/assets/img/logos/gray-logos/logo-vodafone.svg"
              alt="logo_vodafone" />
          </div>
        </div>
      </div>


      <div class="d-none container mb-0">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="card shadow">
              <div class="card-body px-5 py-5 text-center text-md-left">
                <div class="align-items-left">
                  <div class="flex-1">
                    <h2>Restez informé</h2>
                    <div class="txtWrap">
                      <p>
                        <span><span>Découvrez les dernières actualités de Labes.</span></span>
                      </p>
                    </div>
                  </div>

                  <div class="btn-wrap">
                    <a href="#" target="_self" class="btn">
                      Voir toutes les actualités
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 10 10" stroke="currentColor"
                        class="ml-2 h-3 w-3">
                        <g transform="translate(-3947 6344.354)">
                          <line fill="none" stroke-linejoin="round" stroke-miterlimit="10" x1="3948" y1="-6339.4"
                            x2="3956" y2="-6339.4"></line>
                          <path fill="none" stroke-miterlimit="10" d="M3952-6343.4l4,4l-4,4"></path>
                        </g>
                      </svg></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card shadow">
              <div class="card-body px-5 py-5 text-center text-md-left">
                <div class="align-items-left">
                  <div class="flex-1">
                    <h2>Des questions ou commentaires ?</h2>
                    <div class="txtWrap">
                      <p>
                        <span><span>Envoyez-nous un message en utilisant le lien
                            ci-dessous et nous vous répondrons dès que
                            possible.</span></span>
                      </p>
                    </div>
                  </div>

                  <div class="btn-wrap">
                    <a href="#" target="_self" class="btn">
                      Contacter nos commerciaux
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 10 10" stroke="currentColor"
                        class="ml-2 h-3 w-3">
                        <g transform="translate(-3947 6344.354)">
                          <line fill="none" stroke-linejoin="round" stroke-miterlimit="10" x1="3948" y1="-6339.4"
                            x2="3956" y2="-6339.4"></line>
                          <path fill="none" stroke-miterlimit="10" d="M3952-6343.4l4,4l-4,4"></path>
                        </g>
                      </svg></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="py-6">
        <hr />
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-5 col-lg-7 order-md-1 text-center text-md-start z-index-2 cta-image">
              <img class="img-fluid mb-4 mb-md-0"
                src="https://technext.github.io/applab_2/v1.0.0/assets/img/illustrations/cta.png" width="850" alt="" />
            </div>
            <div class="col-md-7 col-lg-5 text-center text-md-start">
              <h1 class="display-6 fw-bold lh-sm text-info">
                Téléchargez notre application maintenant
              </h1>
              <p class="my-4">
                L’essor des appareils mobiles transforme votre façon de bénéficier de nos service e-santé.
              </p>
              <div class="d-flex justify-content-center d-inline-block">
                <a class="pe-2 pe-sm-3 pe-md-4" href="!#"><img
                    src="https://technext.github.io/applab_2/v1.0.0/assets/img/illustrations/google-play.png"
                    width="160" alt="" /></a><a href="!#"><img
                    src="https://technext.github.io/applab_2/v1.0.0/assets/img/illustrations/app-store.png" width="160"
                    alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import setNavPills from "@/assets/js/nav-pills.js";

import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

//import DefaultServiceCard from "../../pages/profile/components/DefaultServiceCard.vue";
//import CategoryInfoCard from "@/examples/Cards/CategoryInfoCard.vue";
import { mapMutations } from "vuex";
import router from "../../../router";
//import pros from "./countries.json";
import { ref } from "vue";

let searchTerm = ref("");
let selectedProvider = ref("");

export default {
  name: "Home",
  components: {
    Navbar,
    AppFooter,
    Carousel,
    Slide,
    Navigation,
    //DefaultServiceCard,
    //CategoryInfoCard,
  },
  data() {
    return {
      wCategories:[],
      words: [],
      part: '',
      i: 0,
      offset: 0,
      forwards: true,
      skip_count: 0,
      skip_delay: 20,
      speed: 100,



      psearchQuery: "",
      pproviders: [],
      pproviderList :[],
      keywords: "",
      form: {
        longitude: "10.3433887",
        latitude: "36.8692812",
      },
      imgCatUrl: "https://back.labes.com.tn/",
      error: "",
      lat: "",
      lon: "",
      address: "",
      isVisible: false,
      // carousel settings
      settingsCarouselProvider: {
        itemsToShow: 1.5,
        snapAlign: "center-odd",
        wrapAround: true,
        autoplay: 2000,
        pauseAutoplayOnHover: true,
        touchDrag: true,
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
      },

      settingsCarouselCategory: {
        itemsToShow: 1.8,
        snapAlign: "center",
        wrapAround: true,
        autoplay: 2000,
        pauseAutoplayOnHover: true,
        touchDrag: true,
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpointsCategory: {
        // 700px and up
        700: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.2,
          snapAlign: "center",
        },
      },
      pros: this.$store.state.pros,
      professionals: [],
      searchTerm,
      selectedProvider,
    };
  },
  watch: {
    /* "$store.state.pros": {
      handler(newPros) {
        this.professionals = newPros;
      },
      deep: true,
    },*/
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    //this.wordFlick();
    setNavPills();
  },
  async created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    // await this.$store.dispatch("loadCategories");
    await this.$store.dispatch("loadHomeData", this.form);
    this.wordFlick();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;

    this.toggleDefaultLayout();
  },
  computed: {
    /*searchProviders() {
      //console.log("update json");

      if (searchTerm.value === "") {
        return [];
      }
      //this.getFilteredProList(searchTerm.value);
      let matches = 0;
      const pros = this.professionals;
      return pros.filter((pro) => {
        if (
          pro.name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          pro.email.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          pro.category_name
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) ||
          pro.subcategory_name
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) ||
          pro.category_name
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) ||
          pro.service_title
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) ||
          pro.about.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          pro.service_offered
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) ||
          (pro.service_location
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) &&
            matches < 10)
        ) {
          matches++;
          return pro;
        }

        return pro;
      });
    },*/

    categories() {
      //console.log(this.$store.state.categories)
      return this.$store.state.categories;
    },
    homeData() {
      return this.$store.state.homeData;
    },
    /*isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },*/
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    locatorButtonPressed() {
      const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        this.lat = latitude;
        this.lon = longitude;
        this.form.longitude = longitude;
        this.form.latitude = latitude;
        // Do something with the position
      };

      const error = (err) => {
        this.err = err;
        console.log(err);
      };

      // This will open permission popup
      navigator.geolocation.getCurrentPosition(success, error);
    },

    async psearchProviders() {
      try {
        /* const response = await axios.get("/lookup_pros", {
          params: {
            keyword: this.psearchQuery,
          },
        });*/
        // this.pproviders = response.data;
        await this.$store.dispatch("lookupPros", this.psearchQuery);
        this.pproviders = this.$store.state.pros;
        this.pproviderList = this.pproviders.filter(pll => pll.service_id !== null);
      } catch (error) {
        console.error("Error fetching providers:", error);
      }
    },

    gotoProviderPages() {
      console.log(this.psearchQuery);
      this.$store.state.searchTerm = this.psearchQuery;
      router.push({
        name: "LabesProviders",
      });
    },
    selectedPro(pro) {
      selectedProvider.value = pro;
      searchTerm.value = "";
    },
    async getFilteredProList(term) {
      await this.$store.dispatch("lookupPros", term);
      //console.log(JSON.stringify(updatedPros));
      //console.log(term);
    },
    wordFlick() {
      this.wCategories = this.$store.state.homeData.category_list['0'].subCategories;
      //console.log(this.wCategories);
      this.wCategories.forEach(subCategory => {
          this.words.push(subCategory.subcategory_name);
      });


      setInterval(() => {
        if (this.forwards) {
          if (this.offset >= this.words[this.i].length) {
            ++this.skip_count;
            if (this.skip_count == this.skip_delay) {
              this.forwards = false;
              this.skip_count = 0;
            }
          }
        } else {
          if (this.offset == 0) {
            this.forwards = true;
            this.i++;
            this.offset = 0;
            if (this.i >= this.words.length) {
              this.i = 0;
            }
          }
        }
        this.part = this.words[this.i].substr(0, this.offset);
        if (this.skip_count == 0) {
          if (this.forwards) {
            this.offset++;
          } else {
            this.offset--;
          }
        }
      }, this.speed);
    },





  },
};
</script>
<style>
/**************************************** *******************************/

html {
  scroll-behavior: smooth;
}

img {
  width: 100%;
}

/* SECTION - HOME */

.home {
  display: grid;
  grid-template-columns: 45% 50%;
  place-items: center;
  gap: 50px;
  /* background: rgb(67, 69, 112);
  background: radial-gradient(
    circle,
    rgba(67, 69, 112, 1) 3%,
    rgba(35, 36, 57, 1) 60%
  );*/
  overflow: hidden;
  padding: 40px 80px;
}

.description {
  /* color: #fff; */
  padding: 0 50px;
}

.description > h1 {
  font-family: "Tilt Neon", sans-serif;
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 30px;
}

.description > p {
  font-family: "Nunito", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 30px;
}

/*.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  max-width: max-content;
  background: linear-gradient(
    90deg,
    rgb(118, 167, 63) 0%,
    rgb(51, 143, 118) 40%,
    rgb(55, 141, 167) 50%,
    rgb(117, 152, 242) 70%,
    rgb(144, 118, 236) 100%
  );
  background-size: 200%;
  background-position: left;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  border: 0;
  padding: 12px 16px;
  border-radius: 5px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5),
    inset 0px 2px 2px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.btn:is(:hover, :focus-visible) {
  background-position: right;
}*/

.users-color-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
}

.item {
  max-width: 200px;
  aspect-ratio: 1/1;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5),
    inset 0px 2px 2px rgba(255, 255, 255, 0.2);
}

.item:nth-child(1) {
  background-color: #11cdef;
  border-radius: 50% 50% 0 50%;
}

.item:nth-child(2) {
  border-radius: 50% 50% 0 0;
}

.item:nth-child(3) {
  background-color: #11cdef;
  border-radius: 50%;
}

.item:nth-child(4) {
  border-radius: 0 0 0 50%;
}

.item:nth-child(5) {
  border-radius: 0 50% 50% 0;
}

.item:nth-child(6) {
  background-color: #11cdef;
  border-radius: 0 50% 50% 50%;
}

.item:nth-child(7) {
  border-radius: 50% 50% 0 50%;
}

.item:nth-child(8) {
  background-color: #11cdef;
  border-radius: 50% 0 0 50%;
}

.item:nth-child(9) {
  background-color: #11cdef;
  border-radius: 0 50% 50% 0;
}

.item:nth-child(10) {
  border-radius: 50%;
}

.item:nth-child(11) {
  background-color: #11cdef;
  border-radius: 50% 0 50% 50%;
}

.item:nth-child(12) {
  border-radius: 50% 0 0 0;
}

.item {
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: calc(0.2s * var(--i));
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* SECTION - CARD CONTAINER */

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  min-height: 100vh;
  background: rgb(67, 69, 112);
  background: radial-gradient(
    circle,
    rgba(67, 69, 112, 1) 3%,
    rgba(35, 36, 57, 1) 60%
  );
  overflow: hidden;
  padding: 90px 50px;
}

.card {
  border-radius: 15px;
  margin: 20px;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.5),
    inset 0px 2px 2px rgba(255, 255, 255, 0.2);
  cursor: grab;
}

.content {
  position: relative;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10, 1fr);
  padding: 20px;
  background: rgba(104, 104, 104, 0.5);
}

.content::before,
.content::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: inherit;
  background: conic-gradient(
    from var(--gradient-angle),
    rgb(109, 186, 22),
    rgb(30, 119, 95),
    rgb(55, 141, 167),
    rgb(59, 91, 174),
    rgb(144, 118, 236),
    rgb(59, 91, 174),
    rgb(55, 141, 167),
    rgb(30, 119, 95),
    rgb(109, 186, 22)
  );
  animation: rotation 8s linear infinite;
}

.content::after {
  filter: blur(30px);
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}

.content img {
  border-radius: 10px;
  grid-row: 1 / 5;
  margin-bottom: 20px;
  user-select: none;
  object-fit: cover;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.content h1 {
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  grid-row: 5 / 6;
  user-select: none;
}

.content p {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  grid-row: 6 / 9;
  user-select: none;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #ece9e6 0%,
    #ffffff 51%,
    #ece9e6 100%
  );
  padding: 10px 16px;
  color: #000;
  box-shadow: 0 0 5px #eee;
  outline: 0;
  font-size: 1rem;
  font-weight: 400;
  grid-row: 9 / 10;
  cursor: pointer;
  transition: all 0.5s ease-in;
}

.btn-grad:is(:hover, :focus-visible) {
  text-decoration: none;
}

.control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 14px;
}

.control li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #559b9a, #3b3d8a);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5),
    inset 0px 4px 4px rgba(255, 255, 255, 0.2);
  padding: 16px;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(1);
}

.control li::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #559b9a, #3b3d8a);
  border-radius: 50%;
  z-index: -1;
  transition: all 0.5s ease-in;
  opacity: 0;
}

.control li:hover::before {
  opacity: 1;
}

.arrow {
  font-size: 1.5rem;
  color: #fff;
}

@media (max-width: 1215px) {
  .description > h1 {
    font-size: 3rem;
  }

  .description > p {
    font-size: 1rem;
  }

  /*.btn {
    font-size: 1rem;
  }*/
}

@media (max-width: 1015px) {
  .description > h1 {
    font-size: 2.5rem;
  }

  .description > p {
    font-size: 1rem;
  }

  .btn-grad {
    padding: 8px 12px;
  }
}

@media (max-width: 1015px) {
  .home {
    grid-template-columns: 45% 50%;
    gap: 50px;
    padding: 140px 50px;
  }
}

@media (max-width: 865px) {
  .home {
    grid-template-columns: 45% 50%;
    gap: 60px;
    padding: 130px 70px;
  }
}

@media (max-width: 815px) {
  .home {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "userscolorcontainer"
      "description";
    gap: 30px;
    padding: 90px 80px 70px;
  }

  .users-color-container {
    grid-area: userscolorcontainer;
  }

  .description {
    grid-area: description;
    padding: 0;
    text-align: center;
  }

  .description > h1 {
    font-size: 3.5rem;
  }

  .description > p {
    font-size: 1.1rem;
  }
}

@media (max-width: 815px) {
  .description > h1 {
    font-size: 2.7rem;
    text-align: center;
  }

  .description > p {
    font-size: 1rem;
  }
}

@media (max-width: 460px) {
  .home {
    gap: 0;
    padding: 30px 40px;
  }
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your breakpoints */
  .hide-on-mobile {
    display: none;
  }
}
</style>
