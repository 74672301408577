<template>
  <navbar />
  <div
    class="page-header position-relative"
    :style="{
      backgroundImage:
        'url(' + require('../../assets/img/pricing-header-bg.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <span class="bg-gradient-success opacity-6"></span>
    <div class="container pb-10 pb-lg-9 pt-7 postion-relative z-index-2">
      <div class="row">
        <div class="mx-auto text-center col-md-6 mt-4">
          <h3 class="text-white">Contact</h3>
          <p class="text-white">N'hézitez pas d'exprimez vos besoins</p>
        </div>
      </div>
      <div class="row">
        <div class="mx-auto text-center col-lg-4 col-md-6 col-7">
          <div class="mt-5 nav-wrapper position-relative z-index-2"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-n8">
    <div class="container">
      <div class="tab-content tab-space">
        <div id="monthly" class="tab-pane active">
          <div class="row">
            <div class="mx-auto mb-4 col-lg-6 mb-lg-0">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div class="card card-body" id="profile">
                      <h1 v-if="!emailSent" class="mb-3">Contactez-nous</h1>
                     
                  
                      <h3 v-if="emailSent && !isError" class="mb-3">Demande Envoyée.</h3>

                        <form @submit.prevent="sendEmail" v-if="!emailSent">
                        <div class="row g-3">


                         
                          <div class="col-md-12">
                            <label for="your-name" class="form-label"
                              >Nom Complet ou Nom de l'entreprise</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="your-name"
                              name="your-name"
                              v-model="form.name"
                              required
                            />
                          </div>
                         
                          <div class="col-md-6">
                            <label for="your-email" class="form-label"
                              >Votre Email</label
                            >
                            <input
                              type="email"
                              class="form-control"
                              id="your-email"
                              name="your-email"
                              v-model="form.email"
                              required
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="your-phone" class="form-label"
                              >Numéro de Tél</label
                            >
                            <input
                              type="phone"
                              class="form-control"
                              id="your-phone"
                              name="your-phone"
                              v-model="form.phone"
                              required
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="your-subject" class="form-label"
                              >Sujet</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              id="your-subject"
                              name="your-subject"
                              v-model="form.subject"
                            />
                          </div>
                          <div class="col-12">
                            <label for="your-message" class="form-label"
                              >Votre Message</label
                            >
                            <textarea
                              class="form-control"
                              id="your-message"
                              name="your-message"
                              v-model="form.message"
                              rows="5"
                              required
                            ></textarea>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div >
                                <button
                                  data-res="<?php echo $sum; ?>"
                                  type="submit"
                                  class="btn btn-dark w-100 fw-bold"
                                  :disabled="loading"
                                >

                                    <span v-if="loading">Envoi...</span>
                                    <span v-if="loading" class="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                        
                                    <span v-else>Envoyer</span>

                                   
                               
                                </button>
                              </div>
                            </div>
                            <p v-if="!emailSent && isError" class="text-xs text-danger"> Un erreur est survenu lors de l'envoi de votre demande. Merci de rééssayer plutard</p>

                          </div>
                        </div>
                      </form>
                      <div v-if="emailSent" class="col-md-12">

                        <div
                          class="alert alert-success text-white text-sm text-center"
                         
                          role="alert"
                        >
                        Votre demande à été envoyée et elle sera traitée par notre équipe dans les brefs délais..<br />
                           
                        </div>

 
                            <button
                              
                              class="mt-4 mb-0 btn bg-gradient-info btn-sm me-2 w-100"
                              type="button"
                              name="button"
                              @click="goToHomePage"
                             
                            >
                              Aller au site
                            </button>

                          </div>
                      
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
//import PricingCard from "./components/PricingCard.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import emailjs from 'emailjs-com';

export default {
  name: "Contact Page",
  components: {
    Navbar,
    //  PricingCard,
    AppFooter,
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone:'',
        subject: '',
        message: '',
      },
      emailSent: false,
      isError:false,
      loading:false,
    };
  },
  methods: {
    sendEmail() {

      this.loading = true;
      const serviceID = 'service_0y1o3yn';
      const templateID = 'template_vls4a2u';
      const userID = '6tmcUgmzSYU7uw8eR';

      const templateParams = {
        from_name: this.form.name,
        from_email: this.form.email,
        from_phone: this.form.phone,
        subject: this.form.subject,
        message: this.form.message,
      };

      emailjs.send(serviceID, templateID, templateParams, userID)
        .then((response) => {
          console.log('Email sent successfully:', response.status, response.text);
          // alert('Email sent successfully!');
          this.emailSent = true; // Update the state to hide the form and show the success message

        })
        .catch((error) => {
          console.error('Failed to send email:', error);
          //alert('Failed to send email. Please try again later.');
          this.isError = true;
        }).finally(() => {
          this.loading = false;
        });
    },
    goToHomePage() {
      this.$router.push('/');
    },
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
  },
};
</script>
<style>
.spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>