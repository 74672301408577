<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div>
        <!--<argon-button color="white" variant="outline">New order</argon-button>-->
      </div>
      <div class="d-flex">
        <div class="dropdown d-inline">
          <argon-button
            id="navbarDropdownMenuLink2"
            color="white"
            variant="outline"
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Filters</argon-button
          >
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            style
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Paid</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Refunded</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Canceled</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Remove Filter</a
              >
            </li>
          </ul>
        </div>
        <argon-button
          class="btn-icon ms-2 export"
          size
          color="white"
          variant="outline"
          data-type="csv"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </argon-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <table id="order-list" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Id</th>
                  <th>Image</th>
                  <th>Service</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Location</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Payment</th>
                  <th>Customer</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="booking in bookinglist" :key="booking.id">
                  <td>
                    <div class="d-flex align-items-center">
                      <argon-checkbox class="d-none" />
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        #L-{{ booking.id }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <img
                        :src="imgUrl + booking.service_image"
                        alt="..."
                        class="avatar shadow"
                      />
                    </div>
                  </td>

                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">{{
                      booking.service_title
                    }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">
                      {{ booking.service_date }}</span
                    >
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs">
                      {{ booking.from_time }} - {{ booking.to_time }}
                    </span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs"> {{ booking.location }}</span>
                  </td>
                  <td class="font-weight-bold">
                    <span class="my-2 text-xs"> {{ booking.mobileno }}</span>
                  </td>

                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <span
                        class="badge badge-sm"
                        :class="
                          booking.status == 1
                            ? 'badge-warning'
                            : booking.status == 2
                            ? 'badge-info'
                            : booking.status == 3
                            ? 'badge-success'
                            : booking.status == 4
                            ? 'badge-warning'
                            : booking.status == 5
                            ? 'badge-danger'
                            : booking.status == 6
                            ? 'badge-warning'
                            : booking.status == 7
                            ? 'badge-danger'
                            : booking.status == 8
                            ? 'badge-success'
                            : ''
                        "
                      >
                        <small v-if="booking.status == 1">Pending</small>
                        <small v-if="booking.status == 2">In progress</small>
                        <small v-if="booking.status == 3"
                          >Complete Request Sent by Provider</small
                        >
                        <small v-if="booking.status == 4"
                          >Complete Request Sent by Provider</small
                        >
                        <small v-if="booking.status == 5"
                          >Rejected by Pro</small
                        >
                        <small v-if="booking.status == 6"
                          >service Complete - Payment Pending</small
                        >
                        <small v-if="booking.status == 7"
                          >Cancelled by Patient</small
                        >
                        <small v-if="booking.status == 8">Complete</small>
                      </span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <argon-button
                        :color="
                          booking.paid === '1' ||
                          (booking.cod == '1' && booking.status == 8)
                            ? 'success'
                            : booking.paid === '0'
                            ? 'info'
                            : 'danger'
                        "
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i
                          class="fas"
                          :class="
                            booking.paid === '1' ||
                            (booking.cod == '1' && booking.status == 8)
                              ? 'fa-check'
                              : booking.paid === '0'
                              ? 'fa-undo'
                              : booking.paid === '2'
                              ? 'fa-exclamation-triangle'
                              : 'fa-times'
                          "
                          aria-hidden="true"
                        ></i>
                      </argon-button>
                      <span class="text-xs" v-if="booking.cod == '1'">COD</span>
                      <span class="text-xs" v-if="booking.cod == '0'">CB</span>
                    </div>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center">
                      <img
                        :src="imgUrl + booking.profile_img"
                        class="avatar avatar-xs me-2"
                        alt="user image"
                      />
                      <span
                        ><a href="#"> {{ booking.name }}</a></span
                      >
                    </div>
                  </td>

                  <td class="text-xs font-weight-bold">
                    <span class="my-2 text-xs">
                      {{ (booking.service_amount / 1000).toFixed(3) }} /
                      {{ booking.currency }}
                    </span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <router-link
                      :to="{
                        name: 'Booking Details',
                        params: { id: booking.id },
                      }"
                    >
                      <argon-button
                        color="success"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i class="fas fa-eye" aria-hidden="true"></i>
                      </argon-button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";

export default {
  name: "Booking Provider List",
  components: {
    ArgonButton,
    ArgonCheckbox,
  },
  data() {
    return {
      bookingStatusCode: "",
      bookingStatusColor: "",
      bookingStatusTitle: "",
      bookingDetails: [],
      bookingProviderList: [],
      imgUrl: "https://back.labes.com.tn/",
      uRole: "",
    };
  },
  mounted() {
    if (document.getElementById("order-list")) {
      const dataTableSearch = new DataTable("#order-list", {
        searchable: true,
        fixedHeight: false,
        perPageSelect: false,
      });

      document.querySelectorAll(".export").forEach(function (el) {
        el.addEventListener("click", function (el) {
          var type = el.dataset.type;

          var data = {
            type: type,
            filename: "soft-ui-" + type,
          };

          if (type === "csv") {
            data.columnDelimiter = "|";
          }

          dataTableSearch.export(data);
        });
      });
    }
  },
  async created() {
    await this.$store.dispatch("loadBookingList");
    this.bookingProviderList = this.$store.state.bookingList.booking_list;
    this.uRole = this.$store.state.auth.user.type;
  },
  computed: {
    bookinglist() {
      return this.$store.state.bookingList.booking_list;
    },
  },
  methods: {
    async setBookingStatus(booking) {
      this.bookingStatusCode = booking.status;

      if (this.bookingStatusCode == 1) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Pending";
      } else if (this.bookingStatusCode == 2) {
        this.bookingStatusColor = "badge-info";
        this.bookingStatusTitle = "In progress";
      } else if (this.bookingStatusCode == 3) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 4) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "Complete Request Sent by Provider";
      } else if (this.bookingStatusCode == 5) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Rejected by Pro";
      } else if (this.bookingStatusCode == 6) {
        this.bookingStatusColor = "badge-warning";
        this.bookingStatusTitle = "service Complete - Payment Pending";
      } else if (this.bookingStatusCode == 7) {
        this.bookingStatusColor = "badge-danger";
        this.bookingStatusTitle = "Cancelled by Patient";
      } else if (this.bookingStatusCode == 8) {
        this.bookingStatusColor = "badge-success";
        this.bookingStatusTitle = "Complete";
      }
    },
  },
};
</script>
