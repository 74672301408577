import { createI18n } from 'vue-i18n';

// Définissez vos traductions ici
const messages = {
  en: {
    welcome: 'Welcome',
    button: {
      submit: 'Submit',
      cancel: 'Cancel',
    },
    // ajoutez d'autres messages ici
    cards: {
      bookings : 'bookings',
      services : 'services',
      reviews : 'reviews',
      notifications : 'notifications' ,

    },
  },
  fr: {
    welcome: 'Bienvenue',
    button: {
      submit: 'Soumettre',
      cancel: 'Annuler',
    },
    // ajoutez d'autres messages ici
    cards: {
      bookings : 'Réservations',
      services : 'services',
      reviews : 'avis',
      notifications : 'notifications' ,

    },
  }
};

const i18n = createI18n({
  locale: 'en', // langue par défaut
  fallbackLocale: 'en', // langue de secours
  messages,
});

export default i18n;
