<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
        :class="isRTL ? 'me-3' : ''"
      >
        <a
          href="#"
          class="p-0 nav-link text-body"
          @click.prevent="navbarMinimize"
        >
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
          </div>
        </a>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <!--<div class="input-group" >
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input type="text" class="form-control" :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'" />
          </div>-->
        </div>

        <ul class="navbar-nav justify-content-end">
          <li
            class="px-3 nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              :class="`p-0 nav-link ${
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              } ${showMenu ? 'show' : ''}`"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul
              v-if="isAuth"
              class="px-3 py-1 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li
                class="mb-2"
                v-for="notification in notifications.slice(0, 5)"
                :key="notification.utc_date_time"
              >
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        :src="imgUrl + notification.profile_img"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-xs font-weight-normal">
                        {{ notification.message.split(" ")[0] }}
                      </h6>
                      <span style="white-space: pre-wrap" class="text-xs">{{
                        notification.message
                      }}</span>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{ notification.utc_date_time }}
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li v-if="this.notificationCount == 0" class="mb-2">
                <span style="white-space: pre-wrap" class="text-xs"
                  >Notification ({{ this.notificationCount }})</span
                >
              </li>
            </ul>

            <ul class="d-none">
              <!-- Render something for unauthenticated users -->
              <p>Please log in to view notifications.</p>
            </ul>
          </li>

          <li class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Settings' }"
              class="px-0 nav-link font-weight-bold"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              "
            >
              <button
                class="my-2 mx-2 btn bg-gradient-warning btn-sm me-2"
                type="button"
                name="button"
              >
                <i class="fa fa-user mx-3"></i>
                <span class="d-sm-inline d-none">{{ user.name }}</span>
              </button>
            </router-link>
          </li>

          <li class="nav-item d-flex align-items-center">
            <button
              class="my-2 mx-2 btn text-white"
              type="button"
              name="button"
              @click="logout"
            >
              <i class="fa fa-sign-out"></i>
            </button>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-white"
              @click.prevent="navbarMinimize"
            >
              <div class="sidenav-toggler-inner">
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
              </div>
            </a>
          </li>
          <!--<li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link" @click="toggleConfigurator">
              <i
:class="`cursor-pointer fa fa-cog fixed-plugin-button-nav ${!isNavFixed || darkMode ? 'text-white' : 'opacity-8 text-dark'
              }`"></i>
            </a>
          </li>-->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      notificationCount: "",
      showMenu: false,
      isAuth: false,
      imgUrl: "https://back.labes.com.tn/",
    };
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    ...mapGetters("auth", {
      user: "user",
      authenticated: "authenticated",
    }),
    notifications() {
      return this.$store.state.notifications.notification_list;
    },
  },
  async created() {
    this.isAuth = this.$store.state.auth.authenticated;
    if (this.isAuth) {
      await this.$store.dispatch("loadNotificationList");
    }

    if (
      this.isAuth &&
      this.$store.state.notifications.notification_list.length != 0
    ) {
      this.notificationCount =
        this.$store.state.notifications.notification_list.length;
    } else {
      this.notificationCount = "0";
    }

    //get-notification-list
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
      this.signOut();
      this.$router.push({ name: "Signin Cover" });
      this.$store.state.showSidenav = false;
      this.$store.state.showNavbar = false;
    },
  },
};
</script>
