<template>
  <div class="container-fluid py-4">



    <div class="row">
      <div class="col-lg-4 col-md-5 col-12">



        <div
          class="card blur shadow-blur max-height-vh-70 overflow-auto overflow-x-hidden mb-5 mb-lg-0"
        >
          <div class="card-header p-3">
            <h6>Contacts</h6>
            <input
              v-model="searchContact"
              type="text"
              class="form-control"
              placeholder="Contact"
              aria-label="Nom"
            />
          </div>
          <div class="card-body p-2">
            <a
              v-for="(chat, index) in filteredChatList"
              :key="chat.id"
              href="javascript:;"
              v-on:click="loadChatHistory(chat.token, index)"
              class="d-block p-2"
              :class="
                chat.token == currentUserChat.token
                  ? 'border-radius-lg bg-gradient-primary'
                  : ''
              "
            >
              <div class="d-flex p-2">
                <img
                  alt="Image"
                  :src="imgUrl + chat.profile_img"
                  class="avatar shadow"
                />
                <div class="ms-3">
                  <h6
                    class="mb-0"
                    :class="
                      chat.token == currentUserChat.token ? 'text-white' : ''
                    "
                  >
                    {{ chat.name }}
                  </h6>
                  <p
                    class="text-xs mb-2"
                    :class="
                      chat.token == currentUserChat.token
                        ? 'text-white'
                        : 'text-muted'
                    "
                  >
                    {{ chat.date }}, {{ (chat.time).substring(0, 5) }}
                  </p>
                  <span
                    class="text-sm col-11 p-0 text-truncate d-block d-none"
                    :class="
                      chat.token == currentUserChat.token
                        ? 'text-white'
                        : 'text-muted'
                    "
                    >{{ chat.token }}</span
                  >
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-7 col-12">


        <div class="card blur shadow-blur max-height-vh-70">
          <div class="card-header shadow-lg">
            <div class="row">
              <div class="col-lg-10 col-8">
                <div class="d-flex align-items-center">
                  <img
                    alt="Image"
                    :src="imgUrl + currentUserChat.profile_img"
                    class="avatar"
                  />
                  <div class="ms-3">
                    <h6 class="mb-0 d-block">{{ currentUserChat.name }}</h6>
                    <span class="text-xs text-dark opacity-8"
                      >last seen {{ currentUserChat.date }} at
                      {{ currentUserChat.time.substring(0, 5)  }}</span
                    >
                    <small class="d-none">{{ currentUserChat.token }}</small>
                  </div>
                </div>
                <div class="d-flex align-items-center p-1">
                 
                  <i class="fas fa-shield-alt fa-fw fa-xl me-2"></i> 
                  <small class="text-xs text-muted">Chat Encypted End-To-End</small>

                </div>
              </div>
              <div class="col-lg-1 col-2 my-auto pe-0">
                <button
                  class="btn btn-icon-only shadow-none text-dark mb-0 me-3 me-sm-0"
                  type="button"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  data-bs-original-title="Video call"
                >
                  <i class="ni ni-camera-compact"></i>
                </button>
              </div>
              <div class="col-lg-1 col-2 my-auto ps-0">
                <div class="dropdown">
                  <button
                    class="btn btn-icon-only shadow-none text-dark mb-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="ni ni-settings"></i>
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end me-sm-n2 p-2"
                    aria-labelledby="chatmsg"
                    style=""
                  >
                    <li>
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        Profile
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        Mute conversation
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        Block
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item border-radius-md"
                        href="javascript:;"
                      >
                        Clear chat
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item border-radius-md text-danger"
                        href="javascript:;"
                      >
                        Delete chat
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div ref="chatContainer"  class="card-body overflow-auto overflow-x-hidden chat-container">
            <div
              v-for="(chatH, index) in chatHistory.slice()"
              :key="chatH.id"
              class="row mb-4"
              :class="
                this.currUser == chatH.sender_token
                  ? 'justify-content-end text-left'
                  : 'justify-content-start'
              "
            >

            <div v-if="isNewDate(index)" 
                class="row mt-4">
              <div class="col-md-12 text-center">
                <span class="badge text-dark">
                  {{ formatCustomDate(chatH.utc_date_time) }}</span>
              </div>
            </div>

              <div class="col-auto">
                <div
                  class="card"
                  :class="
                    this.currUser == chatH.sender_token ? 'bg-gray-200' : ''
                  "
                >
                  <div class="card-body py-2 px-3">
                    <p class="text-xs mb-0">
                      {{ chatH.message }}
                    </p>
                    <div
                      class="d-flex align-items-center text-sm opacity-6"
                      :class="
                        this.currUser == chatH.sender_token
                          ? 'justify-content-end'
                          : 'justify-content-start'
                      ">
                      <small class="text-xs">{{ (chatH.utc_date_time).substring(11,16) }}</small>
                      
                      <i v-if="chatH.read_status == 1 " class="ni ni-check-bold text-xs me-1"
                      
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="card-footer d-block">
            <div class="align-items-center">
              <div class="d-flex">
             
               <!-- chat container Socket -->
               <div class="chat-container w-100">
                    <div class="messages">
                        <div v-for="(message, index) in messages" :key="index" class="message">
                          {{ message }}
                        </div>
                    </div>
                      <input
                      class="form-control"
                        v-model="newMessage"
                        @keyup.enter="sendMessageSocket"
                        type="text"
                        placeholder="Type a message"
                      />
                  </div> 
               
                <button
                  @click="sendMessageSocket()"
                  class="btn bg-gradient-primary mb-0 ms-2"
                >
                  <i class="ni ni-send"></i>
                </button>
              </div>
            </div>
          </div>







        </div>
      </div>
    </div>


     
  </div>
</template>

<script>

//import ArgonButton from "@/components/ArgonButton.vue";
//import ArgonAvatar from "@/components/ArgonAvatar.vue";
//import ArgonBadge from "@/components/ArgonBadge.vue";
//import Chat from '@/components/Chat.vue';
import axios from "axios";
//import { io } from "socket.io-client";
//import socket from '../services/socket';

export default {
  name: "ChatList",

  components: {
    //ArgonButton,
    //ArgonAvatar,
    //ArgonBadge,
  
  },
  data() {
    return {
      searchContact:'',
      openChatToken: "",
      chatForm: {
        to: "",
        content: "",
      },
      chatHistory: [],
      selectedChat: [],
      chatList: [],
      chatSelectedList:[],
      filtredList: [],
      currentUserChat: [],
      currUser: localStorage.getItem("api_token"),
      imgUrl: "https://back.labes.com.tn/",
      socket: null,
      messages: [],
      newMessage: '',
      lastDateChat:''
    };
  },
  mounted() {
      //this.socket = io('https://back.labes.com.tn/ws'); 
      this.scrollToBottom();
  },
  async created() {
    await this.$store.dispatch("loadChatList");
    this.openChatToken = this.$store.state.openChatToken;
    this.chatList = this.$store.state.chatList;

    if (this.openChatToken != "") {
      // alert("openChatToken" + this.openChatToken);

      this.loadChatHistoryOpen(this.openChatToken);

      this.$store.state.openChatToken = "";
    } else {
      this.currentUserChat = this.$store.state.chatList[0];
      //alert("openChatToken is empty so select last chat");
      this.selectedChat = await this.$store.dispatch(
        "loadChatHistory",
        this.currentUserChat.token
      );
      this.chatHistory = this.$store.state.chatHistory.chat_history;
    }
  },
  computed: {
    chats() {
      return this.$store.state.chatList;
    },
    lastReadIndex() {
      // Find the index of the last message with read_status === 1
      for (let i = this.chatHistory.length - 1; i >= 0; i--) {
        if (this.chatHistory[i].read_status === 1) {
          return i;
        }
      }
      return -1;
    },
    filteredChatList() {
          if (this.searchContact === '') {
            return this.chatList;
          }
          return this.chatList.filter(user => 
            user.name.toLowerCase().includes(this.searchContact.toLowerCase())
          );
        }
  
  },
  updated(){
    this.scrollToBottom();

  },
  methods: {
    async loadChatHistory(token, index) {
      console.log(token + '_' + index);
      var item = "";
      item = this.chatList.filter((chat) => chat.token == token);
      this.chatSelectedList = [];
      this.chatSelectedList = item;
      this.currentUserChat = this.chatSelectedList[0] // this.$store.state.chatList[index];
      console.log(this.currentUserChat);
      this.selectedChat = await this.$store.dispatch("loadChatHistory", token);
      this.chatHistory = this.$store.state.chatHistory.chat_history;
    },
    async loadChatHistoryOpen(token) {
      var item = "";
      item = this.chatList.filter((chat) => chat.token == token);
      this.chatList = [];
      this.chatList = item;
      //console.log(this.filtredList);
      this.currentUserChat = [];
      this.currentUserChat = this.chatList[0];
      this.selectedChat = await this.$store.dispatch("loadChatHistory", token);
      this.chatHistory = this.$store.state.chatHistory.chat_history;
    },
    async sendChatMessage() {
      this.chatForm.to = this.currentUserChat.token;
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      axios.defaults.headers["language"] = "fr";
      await axios
        .post("chat", this.chatForm)
        .then(({ data }) => {
          //alert("hello");
          console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            console.log(data.response);
          } else {
            console.log(data.status);
            //alert("hella");
          }
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          //alert("login finish ")
        });
    },
 
    async sendMessageSocket() {

     this.chatForm.to = this.currentUserChat.token;
      axios.defaults.headers["token"] = localStorage.getItem("api_token");
      axios.defaults.headers["language"] = "fr";

      this.chatForm.content= ''; 
      this.chatForm.content = this.newMessage;
      await axios
        .post("chat", this.chatForm)
        .then(({ data }) => {
          //alert("hello");
          //console.log(data.response.response_code);
          if (data.response.response_code == 200) {
            console.log(data.response);
          } else {
            console.log(data.status);
            //alert("hella");
          }
        })
        .catch(({ response }) => {
          console.log(response);
        })
        .finally(() => {
          //alert("login finish ")
          this.newMessage = '';
          //this.selectedChat =  this.$store.dispatch("loadChatHistory", this.currentUserChat.token);
          //this.chatHistory = this.$store.state.chatHistory.chat_history;
          this.refreshChat();
        });
      
     
    },   
    async refreshChat(){
      this.selectedChat = await this.$store.dispatch(
        "loadChatHistory",
        this.currentUserChat.token
      );
      this.chatHistory = this.$store.state.chatHistory.chat_history;
    },
    
    isNewDate(index) {
      if (index === 0) {
        return true;
      }
      const currentDate = new Date(this.chatHistory[index].utc_date_time).toDateString();
     // console.log('current Date :'+ currentDate);
      const previousDate = new Date(this.chatHistory[index - 1].utc_date_time).toDateString();
     // console.log('previousDate Date :'+ previousDate);
      return currentDate !== previousDate;
    },
    formatCustomDate(dateTime) {
          const date = new Date(dateTime);
          const dayName = date.toLocaleString('fr-FR', { weekday: 'short' });
          const day = date.getDate();
          const month = date.toLocaleString('fr-FR', { month: 'short' });
          const year = date.getFullYear();
          return `${dayName} ${day} ${month} - ${year}`;
        },
    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }

  },
};
</script>
